<template>
  <main class="bg-white min-h-screen flex flex-col">
    <div class="flex flex-grow max-md:flex-col">
      <aside
        class="flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div class="flex flex-col mt-14 flex-grow">
          <div class="flex flex-col ml-10 text-left">
            <span class="text-white text-3xl font-semibold leading-9">
              Create An <br />
              Expert Program
            </span>
            <span
              class="text-white text-base font-normal leading-6 font-sans mt-3"
            >
              Start setting up an expert program</span
            >
          </div>

          <!-- Third Image, or Footer Image -->
          <StepsList :currentStep="4" />
        </div>
      </aside>
      <section
        class="flex flex-col justify-start w-4/5 max-md:ml-0 max-md:w-full"
      >
        <div class="flex flex-col mt-20 w-full max-md:mt-10 max-md:max-w-full">
          <!-- directroy + code step list -->
          <div class="flex flex-col px-10 max-w-full text-left">
            <span
              class="self-start text-gray-800 font-semibold text-xl leading-9 break-words"
            >
              Track Results by integrating your CRM
            </span>
            <span
              class="self-start text-black font-normal text-base leading-6 mt-2 break-words"
            >
              Integrate your SlashExperts program with CRM. It only takes 5
              minutes and no admin access required. It's easy!
            </span>
          </div>

          <!-- Error div -->
          <div class="flex justify-center mb-4">
            <div
              v-if="errorMessage"
              role="alert"
              class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
              style="max-height: 80px; overflow-y: auto"
            >
              <div
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <img
                  src="../../assets/shared/failure.svg"
                  alt="Error Icon"
                  class="w-4 h-4"
                />
              </div>
              <p>{{ errorMessage }}</p>
            </div>
          </div>

          <div class="self-center mt-8 w-full px-10">
            <div class="flex gap-5 max-md:flex-col">
              <div class="flex flex-col gap-4">
                <div class="flex flex-row gap-3">
                  <div
                    class="flex flex-col text-left gap-3 border border-gray-300 border-solid px-4 py-6 rounded-md"
                  >
                    <img
                      src="../../assets/shared/hubspot-logo.svg"
                      alt="Hubspot Logo"
                      class="w-20 h-auto mb-2"
                    />
                    <div class="flex flex-col gap-1">
                      <span
                        class="text-gray-900 text-xl font-semibold leading-7 tracking-wide"
                        >Connect Hubspot</span
                      >
                      <span
                        class="text-gray-500 text-sm font-normal leading-5 tracking-wide"
                        >Connect Slash Expert to a Hubspot admin<br />
                        and begin enriching your records.</span
                      >
                    </div>
                    <div class="flex flex-col w-full">
                      <BaseButton
                        type="button"
                        class="flex flex-col justify-center items-center p-2 w-full rounded-lg text-sm font-medium leading-none text-indigo-700 bg-indigo-50 text-center"
                      >
                        <span>Coming Soon</span>
                      </BaseButton>
                    </div>
                  </div>

                  <div
                    class="flex flex-col text-left gap-3 border border-gray-300 border-solid px-4 py-6 rounded-md"
                  >
                    <img
                      src="../../assets/shared/salesforce-logo.svg"
                      alt="Salesforce Logo"
                      class="w-12 h-auto"
                    />
                    <div class="flex flex-col gap-1">
                      <span
                        class="text-gray-900 text-xl font-semibold leading-7 tracking-wide"
                        >Connect Salesforce</span
                      >
                      <span
                        class="text-gray-500 text-sm font-normal leading-5 tracking-wide"
                        >Connect Slash Expert to a Salesforce admin <br />
                        and begin enriching your records.</span
                      >
                    </div>
                    <div class="flex flex-col w-full">
                      <BaseButton
                        type="button"
                        @click="connectSalesforce()"
                        class="flex flex-col justify-center items-center p-2 w-full rounded-lg text-sm font-medium leading-none text-center"
                        :class="{
                          'text-primary-purple bg-violet-200':
                            userStore.createExpertProgram?.salesforceSynced,
                          'text-white bg-primary-purple':
                            !userStore.createExpertProgram?.salesforceSynced,
                        }"
                      >
                        <span
                          v-if="
                            !userStore.createExpertProgram?.salesforceSynced
                          "
                          >Connect Now</span
                        >
                        <span v-else>Connected</span>
                      </BaseButton>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-col text-left gap-3 border border-gray-300 border-solid px-4 py-6 rounded-md"
                >
                  <div v-html="COMPANY_LOGO"></div>
                  <div class="flex flex-row justify-between">
                    <div class="flex flex-col">
                      <span
                        class="text-gray-900 text-xl font-semibold leading-7 tracking-wide"
                        >Track Slash Expert Results</span
                      >
                      <span
                        class="text-gray-500 text-sm font-normal leading-5 tracking-wide"
                        >Track Results inside SlashExperts without integrating
                        CRM.</span
                      >
                    </div>
                    <div class="flex flex-col justify-center">
                      <button
                        class="flex items-center w-12 h-6 rounded-xl transition-colors duration-300"
                        :class="{
                          'bg-violet-600': internalTracking,
                          'bg-gray-200': !internalTracking,
                        }"
                        @click="toggleInternalTracking()"
                        aria-label="Toggle button"
                      >
                        <span
                          class="flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform"
                          :class="{
                            'translate-x-6': internalTracking,
                            'translate-x-0.5': !internalTracking,
                          }"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- footer section -->
        <footer class="flex flex-wrap justify-center mt-auto items-center">
          <FooterSection :isDisabled="isDisabled" @next="goNext" />
        </footer>
      </section>
    </div>
  </main>
</template>

<script lang="ts">
import StepsList from "@/components/expert-program/StepsList.vue";
import FooterSection from "@/components/expert-program/FooterSection.vue";
import { defineComponent, onMounted, ref, watch } from "vue";
import {
  CLOSE_ICON_SM,
  PLUS_ICON,
  LOCK_ICON,
  BROWSER_MENU_ICON,
  ARROW_UP,
  COMPANY_LOGO,
} from "@/assets/svg/shared/svg-constants";
import BaseButton from "@/components/shared/BaseButton.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import router from "@/router";
import { useUserStore } from "@/stores/user";

const GET_SALESFORCE_OAUTH_URL = gql`
  query getSalesforceOauthUrl($programId: ID!) {
    salesforceoauth(programId: $programId) {
      oauthUrl
    }
  }
`;

export default defineComponent({
  name: "ExpertProgramSetup",
  components: {
    StepsList,
    FooterSection,
    BaseButton,
  },
  setup() {
    const userStore = useUserStore();
    const isDisabled = ref(false);
    const errorMessage = ref("");
    const salesforceOauthUrl = ref("");
    // const company = {
    //   id: "66fea575199d5db008ee0bc5",
    //   name: "Sendoso",
    //   website: "https://sendoso.com",
    // };
    // const program = { id: "66fee9f32054b539ffe02e9b" };
    const internalTracking = ref(false);
    const route = useRoute();

    console.log(
      "userStore program id for expert program",
      userStore.createExpertProgram?.id
    );

    onMounted(() => {
      checkSalesForceSyncFailure();
    });

    watch(
      () => salesforceOauthUrl.value,
      (newValue) => {
        errorMessage.value = "";
        window.location.href = newValue;
      }
    );

    const checkSalesForceSyncFailure = () => {
      const salesforceSync = route.query.salesforce_sync as string | undefined;
      if (salesforceSync === "false") {
        errorMessage.value =
          (route.query.details as string) || "Salesforce sync failed";
        clearQueryParams();
      }
    };

    const clearQueryParams = () => {
      const { fullPath } = route;
      const newUrl = fullPath.split("?")[0];
      router.replace({ path: newUrl });
    };

    const goNext = () => {
      errorMessage.value = "";
      router.push({ name: "InviteExperts" });
    };

    const toggleInternalTracking = () => {
      internalTracking.value = !internalTracking.value;
    };

    const connectSalesforce = () => {
      // console.log("Connect Salesforce");
      errorMessage.value = "";
      const { result: queryResult, error: queryError } = useQuery(
        GET_SALESFORCE_OAUTH_URL,
        {
          programId: userStore.createExpertProgram?.id,
        }
      );

      watch(
        () => queryResult.value,
        (newValue) => {
          console.log("Salesforce auth value ", newValue);
          if (
            newValue?.salesforceoauth?.oauthUrl === null ||
            newValue?.salesforceoauth?.oauthUrl === undefined
          ) {
            errorMessage.value =
              "Failed to fetch Salesforce authorization URL. Please refresh the page and try again.";
          } else {
            salesforceOauthUrl.value = newValue?.salesforceoauth?.oauthUrl;
          }
        }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            errorMessage.value =
              "Failed to fetch Salesforce authorization URL. Please refresh the page and try again.";
            console.error("Query error: ", newError);
          }
        }
      );
    };

    return {
      isDisabled,
      goNext,
      CLOSE_ICON_SM,
      PLUS_ICON,
      LOCK_ICON,
      BROWSER_MENU_ICON,
      ARROW_UP,
      COMPANY_LOGO,
      // company,
      errorMessage,
      internalTracking,
      toggleInternalTracking,
      connectSalesforce,
      userStore,
    };
  },
});
</script>
