<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <header class="flex flex-col w-full border-b">
      <Navbar :activeTab="0" />
    </header>
    <main class="flex flex-col sm:flex-row w-full pt-12 pr-6">
      <aside
        class="flex flex-col w-1/5 text-left pt-2 px-6 gap-6 sm:h-screen rounded-md border border-gray-200 max-md:ml-0 max-md:w-full"
      >
        <div class="flex flex-col">
          <span class="text-black text-lg font-medium leading-6">Settings</span>
        </div>
        <div class="flex flex-col gap-3">
          <div
            class="flex flex-row items-center px-2 py-1 rounded-md"
            :class="{
              'bg-violet-100 text-indigo-700': activeTab === 0,
            }"
          >
            <div
              v-html="PERSON_ICON"
              class="mr-2 text-gray-500"
              :class="{
                'text-primary-purple': activeTab === 0,
                'text-gray-500': activeTab !== 0,
              }"
            ></div>
            <BaseButton
              type="button"
              class="text-gray-500 text-base font-medium leading-6 text-left w-full"
              @click="activeTab = 0"
              ><span :class="{ 'text-indigo-700': activeTab === 0 }"
                >Account</span
              ></BaseButton
            >
          </div>
          <div
            class="flex flex-row items-center px-2 py-1 rounded-md"
            :class="{
              'bg-violet-100 text-indigo-700': activeTab === 1,
            }"
          >
            <div
              v-html="MONEY_ICON"
              class="mr-2 text-gray-500"
              :class="{
                'text-primary-purple': activeTab === 1,
                'text-gray-500': activeTab !== 1,
              }"
            ></div>
            <BaseButton
              type="button"
              class="text-gray-500 text-base font-medium leading-6 text-left w-full"
              @click="activeTab = 1"
              ><span :class="{ 'text-indigo-700': activeTab === 1 }"
                >Billing</span
              ></BaseButton
            >
          </div>
          <div
            class="flex flex-row items-center px-2 py-1 rounded-md"
            :class="{
              'bg-violet-100 text-indigo-700': activeTab === 2,
            }"
          >
            <div
              v-html="PEOPLE_ICON_2"
              class="mr-2 text-gray-500"
              :class="{
                'text-primary-purple': activeTab === 2,
                'text-gray-500': activeTab !== 2,
              }"
            ></div>
            <BaseButton
              type="button"
              class="text-gray-500 text-base font-medium leading-6 text-left w-full"
              @click="activeTab = 2"
              ><span :class="{ 'text-indigo-700': activeTab === 2 }"
                >Team Management</span
              ></BaseButton
            >
          </div>
        </div>
      </aside>
      <section class="flex flex-col w-full sm:w-4/5 justify-start pr-6 pt-6">
        <AccountContainer v-if="activeTab === 0" />
        <BillingContainer v-if="activeTab === 1" />
        <TeamManagementContainer v-if="activeTab === 2" />
      </section>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Navbar from "@/components/dashboard/Navbar.vue";
import BillingContainer from "@/components/dashboard/billing/BillingContainer.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import AccountContainer from "@/components/dashboard/account/AccountContainer.vue";
import TeamManagementContainer from "@/components/dashboard/team-mangement/TeamManagementContainer.vue";
import {
  PERSON_ICON,
  MONEY_ICON,
  PEOPLE_ICON_2,
} from "@/assets/svg/dashboard/svg-constants";

export default defineComponent({
  name: "BillingPage",
  components: {
    // NavigationBar,
    // BalanceCard,
    // BillingHistory,
    BaseButton,
    BillingContainer,
    Navbar,
    TeamManagementContainer,
    AccountContainer,
  },
  setup() {
    const abc = ref(1);
    const activeTab = ref(0);

    return {
      abc,
      activeTab,
      PERSON_ICON,
      MONEY_ICON,
      PEOPLE_ICON_2,
    };
  },
});
</script>
