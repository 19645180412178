<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div v-else class="flex flex-grow max-md:flex-col">
      <aside
        class="flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div class="flex flex-col mt-14 flex-grow">
          <div class="flex flex-col ml-10 text-left">
            <span class="text-white text-3xl font-semibold leading-9">
              Create An <br />
              Expert Program
            </span>
            <span
              class="text-white text-base font-normal leading-6 font-sans mt-3"
            >
              Start setting up an expert program</span
            >
          </div>

          <!-- Third Image, or Footer Image -->
          <StepsList :currentStep="4" />
        </div>
      </aside>
      <main class="flex flex-col w-full md:w-4/5 justify-start pt-12">
        <div
          class="flex flex-col items-start w-full gap-3 px-6 sm:px-24 text-left"
        >
          <div class="flex flex-col mb-6">
            <span
              class="text-3xl leading-9 font-semibold text-neutral-800 font-inter"
              >Track Results in Salesforce</span
            >
          </div>

          <!-- Error div -->
          <div class="flex justify-center mb-4">
            <div
              v-if="errorMessage"
              role="alert"
              class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
              style="max-height: 80px; overflow-y: auto"
            >
              <!-- Icon Container -->
              <div
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <!-- Custom Image Icon -->
                <img
                  src="../../assets/shared/failure.svg"
                  alt="Error Icon"
                  class="w-4 h-4"
                />
              </div>
              <p>{{ errorMessage }}</p>
            </div>
          </div>

          <div class="flex flex-col">
            <span class="text-sm leading-5 font-medium text-neutral-800"
              >Sync'd Salesforce Account</span
            >
          </div>

          <!-- user info -->
          <div
            class="flex flex-col border border-solid border-primary-purple bg-white rounded-lg p-0.5"
          >
            <div
              class="flex flex-col border border-solid border-gray-300 rounded-lg p-4"
            >
              <div class="flex flex-wrap gap-3">
                <div class="flex flex-col">
                  <div
                    class="flex items-center justify-center w-8 h-8 bg-blue-500 text-white rounded-full"
                  >
                    {{ initials }}
                  </div>
                </div>
                <div class="flex flex-col text-left">
                  <span class="text-sm leading-5 font-medium text-gray-900">{{
                    providerAccountName
                  }}</span>
                  <span class="text-sm leading-5 font-normal text-gray-500">{{
                    providerAccountEmail
                  }}</span>
                </div>
                <div
                  v-html="HOLLOW_CHECK_CIRCLE"
                  class="text-primary-purple"
                ></div>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col gap-4 max-w-[80%] md:max-w-[70%] lg:max-w-[60%]"
          >
            <!-- salesforce fields mapping -->
            <div>
              <LeadFieldMapping
                :leadMappingFields="leadMappingFields"
                :leadFirstName="salesforceData.leadFirstName.value"
                :leadLastName="salesforceData.leadLastName.value"
                :leadCompany="salesforceData.leadCompany.value"
                :leadTitle="salesforceData.leadTitle.value"
                @update:leadFirstName="
                  salesforceData.leadFirstName.value = $event
                "
                @update:leadLastName="
                  salesforceData.leadLastName.value = $event
                "
                @update:leadCompany="salesforceData.leadCompany.value = $event"
                @update:leadTitle="salesforceData.leadTitle.value = $event"
              />
            </div>

            <!-- lead or contact fields mapping -->
            <div>
              <ContactFieldMapping
                :contactMappingFields="contactMappingFields"
                :contactFirstName="salesforceData.contactFirstName.value"
                :contactLastName="salesforceData.contactLastName.value"
                :contactCompany="salesforceData.contactCompany.value"
                :contactTitle="salesforceData.contactTitle.value"
                @update:contactFirstName="
                  salesforceData.contactFirstName.value = $event
                "
                @update:contactLastName="
                  salesforceData.contactLastName.value = $event
                "
                @update:contactCompany="
                  salesforceData.contactCompany.value = $event
                "
                @update:contactTitle="
                  salesforceData.contactTitle.value = $event
                "
              />
            </div>

            <!-- create new activity toggle -->
            <div>
              <CreateNewActivityToggle
                :createNewActivity="createActivity"
                @update:createNewActivity="createActivity = $event"
              />
            </div>

            <!-- create new lead or contact toggle -->
            <div>
              <CreateNewLeadOrContactToggle
                :createNewLeadOrContact="createLeadOrContact"
                @update:createNewLeadOrContact="createLeadOrContact = $event"
              />
            </div>
          </div>
        </div>
        <!-- footer section -->
        <footer class="flex flex-wrap justify-center mt-auto items-center">
          <FooterSection :isDisabled="isDisabled" @next="goNext" />
        </footer>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { HOLLOW_CHECK_CIRCLE } from "@/assets/svg/shared/svg-constants";
import StepsList from "@/components/expert-program/StepsList.vue";
import FooterSection from "@/components/expert-program/FooterSection.vue";
import LeadFieldMapping from "@/components/expert-program/integration/LeadFieldMapping.vue";
import ContactFieldMapping from "@/components/expert-program/integration/ContactFieldMapping.vue";
import CreateNewActivityToggle from "@/components/expert-program/integration/CreateNewActivityToggle.vue";
import CreateNewLeadOrContactToggle from "@/components/expert-program/integration/CreateNewLeadOrContactToggle.vue";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import router from "@/router";
import { useUserStore } from "@/stores/user";

interface MappingField {
  name: string;
  label: string;
}

interface MappingInput {
  salesforceField: string;
  salesforceObject: string;
  mappedField: string;
}

const GET_PROVIDER_ACCOUNT = gql`
  query getProviderAccount($programId: ID!) {
    getProviderAccount(programId: $programId) {
      name
      email
    }
  }
`;

const GET_SALESFORCE_MAPPING_FIELDS = gql`
  query getSalesforceMappingFields($objectType: String!, $programId: ID!) {
    salesforceMappingFields(objectType: $objectType, programId: $programId) {
      name
      label
    }
  }
`;

const CREATE_SALESFORCE_MAPPING_AND_SETTING = gql`
  mutation createSalesforceMappingAndSetting(
    $input: CreateSalesforceMappingAndSettingInput!
  ) {
    createSalesforceMappingAndSetting(input: $input) {
      salesforceSetting {
        createActivity
        createLeadOrContact
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "SalesforceIntegrationView",
  components: {
    StepsList,
    FooterSection,
    LeadFieldMapping,
    ContactFieldMapping,
    CreateNewActivityToggle,
    CreateNewLeadOrContactToggle,
  },
  setup() {
    // const program = { id: "66fea4fe3ec9d2b8cd20063f" };
    const selectedValue = ref<string | number>("");
    const createActivity = ref<boolean>(false);
    const createLeadOrContact = ref<boolean>(false);
    const isDisabled = ref<boolean>(false);
    const isLoadingLeadMappingFields = ref(true);
    const isLoadingContactMappingFields = ref(true);
    const isLoadingProviderAccount = ref(true);
    const providerAccountEmail = ref<string>("");
    const providerAccountName = ref<string>("");
    const errorMessage = ref<string>("");
    const leadMappingFields = ref<MappingField[]>([]);
    const contactMappingFields = ref<MappingField[]>([]);
    const userStore = useUserStore();
    const salesforceData = {
      contactFirstName: ref(""),
      contactLastName: ref(""),
      contactCompany: ref(""),
      contactTitle: ref(""),
      leadFirstName: ref(""),
      leadLastName: ref(""),
      leadCompany: ref(""),
      leadTitle: ref(""),
    };

    onMounted(() => {
      if (userStore.salesforceSyncedRedirection === "program-details") {
        router.push({ name: "ExpertPrograms" });
      } else {
        getSalesforceMappingFields("Lead");
        getSalesforceMappingFields("Contact");
        getProviderAccountData();
      }
    });

    const {
      mutate: createSalesforceMappingAndSetting,
      onDone: createSalesforceMappingAndSettingDone,
      onError: createSalesforceMappingAndSettingError,
    } = useMutation(CREATE_SALESFORCE_MAPPING_AND_SETTING);

    const getProviderAccountData = () => {
      const {
        result: providerAccountQueryResult,
        error: providerAccountQueryError,
      } = useQuery(GET_PROVIDER_ACCOUNT, {
        programId: userStore.createExpertProgram?.id,
      });

      watch(
        () => providerAccountQueryResult.value,
        (newValue) => {
          if (newValue) {
            isLoadingProviderAccount.value = false;
            if (newValue?.getProviderAccount?.email) {
              providerAccountEmail.value = newValue.getProviderAccount.email;
              providerAccountName.value = newValue.getProviderAccount.name;
            }
          }
        },
        { immediate: true }
      );

      watch(
        () => providerAccountQueryError.value,
        (newError) => {
          if (newError) {
            console.log("providerAccountQueryError ", newError);
            errorMessage.value = newError.message;
          }
        },
        { immediate: true }
      );
    };

    const getSalesforceMappingFields = (objectType: string) => {
      const {
        result: mappingFieldsQueryResult,
        error: mappingFieldsQueryError,
      } = useQuery(GET_SALESFORCE_MAPPING_FIELDS, {
        objectType: objectType,
        programId: userStore.createExpertProgram?.id,
      });

      watch(
        () => mappingFieldsQueryResult.value,
        (newValue) => {
          if (newValue) {
            if (objectType === "Lead") {
              isLoadingLeadMappingFields.value = false;
            } else {
              isLoadingContactMappingFields.value = false;
            }
            if (newValue?.salesforceMappingFields.length > 0) {
              handleMappingFields(newValue.salesforceMappingFields, objectType);
            }
          }
        },
        { immediate: true }
      );

      watch(
        () => mappingFieldsQueryError.value,
        (newError) => {
          if (newError) {
            console.log("lead mapping fields error", newError);
            errorMessage.value = newError.message;
          }
        },
        { immediate: true }
      );
    };

    const handleCreateSalesforceMappingAndSetting = () => {
      isDisabled.value = true;
      const params = payload();
      createSalesforceMappingAndSetting({
        input: {
          params: params,
          createActivity: createActivity.value,
          createLeadOrContact: createLeadOrContact.value,
          programId: userStore.createExpertProgram?.id,
        },
      });

      createSalesforceMappingAndSettingDone((response) => {
        if (response) {
          isDisabled.value = false;
          const data = response.data.createSalesforceMappingAndSetting;
          if (data.errors.length > 0) {
            errorMessage.value = data.errors.join(", ");
            scrollToTop();
          } else {
            userStore.updateSalesforceSynced(true);
            router.push({ name: "AddIntegration" });
          }
        }
      });

      createSalesforceMappingAndSettingError((error: ApolloError) => {
        errorMessage.value = error.message;
        isDisabled.value = false;
        scrollToTop();
      });
    };

    const validateSalesforceData = (): boolean => {
      return Object.values(salesforceData).some(
        (field) => field.value.trim() === ""
      );
    };

    const payload = (): MappingInput[] => {
      const types = ["Lead", "Contact"];
      const mappingInput: MappingInput[] = [];

      for (const field of Object.values(types)) {
        mappingInput.push({
          salesforceField: "first_name",
          salesforceObject: field,
          mappedField:
            field === "Lead"
              ? salesforceData.leadFirstName.value
              : salesforceData.contactFirstName.value,
        });
        mappingInput.push({
          salesforceField: "last_name",
          salesforceObject: field,
          mappedField:
            field === "Lead"
              ? salesforceData.leadLastName.value
              : salesforceData.contactLastName.value,
        });
        mappingInput.push({
          salesforceField: "company",
          salesforceObject: field,
          mappedField:
            field === "Lead"
              ? salesforceData.leadCompany.value
              : salesforceData.contactCompany.value,
        });
        mappingInput.push({
          salesforceField: "title",
          salesforceObject: field,
          mappedField:
            field === "Lead"
              ? salesforceData.leadTitle.value
              : salesforceData.contactTitle.value,
        });
      }

      return mappingInput;
    };

    const handleMappingFields = (
      mappingFields: MappingField[],
      objectType: string
    ) => {
      if (objectType === "Lead") {
        leadMappingFields.value = mappingFields;
      } else {
        contactMappingFields.value = mappingFields;
      }
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const goNext = () => {
      if (!validateSalesforceData()) {
        handleCreateSalesforceMappingAndSetting();
      } else {
        console.log("check payload ", salesforceData);
        errorMessage.value = "Please select all fields.";
        scrollToTop();
      }
    };

    const initials = computed(() => {
      if (providerAccountName.value) {
        const words = providerAccountName.value.trim().split(" ");
        return words.map((word) => word[0].toUpperCase()).join("");
      }
      return "";
    });

    const isLoading = computed(() => {
      console.log(
        "isLoadingLeadMappingFields ",
        isLoadingLeadMappingFields.value
      );
      console.log(
        "isLoadingContactMappingFields ",
        isLoadingContactMappingFields.value
      );
      console.log("isLoadingProviderAccount ", isLoadingProviderAccount.value);
      return (
        isLoadingLeadMappingFields.value ||
        isLoadingContactMappingFields.value ||
        isLoadingProviderAccount.value
      );
    });

    return {
      HOLLOW_CHECK_CIRCLE,
      selectedValue,
      leadMappingFields,
      contactMappingFields,
      createActivity,
      createLeadOrContact,
      goNext,
      isDisabled,
      salesforceData,
      isLoadingLeadMappingFields,
      isLoadingContactMappingFields,
      isLoadingProviderAccount,
      providerAccountEmail,
      providerAccountName,
      initials,
      errorMessage,
      isLoading,
    };
  },
});
</script>
