import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'
import _imports_1 from '../../assets/expert-program/directory/traffic-lights.svg'
import _imports_2 from '../../assets/expert-program/directory/browser-actions.svg'


const _hoisted_1 = { class: "bg-white min-h-screen flex flex-col" }
const _hoisted_2 = { class: "flex flex-grow max-md:flex-col" }
const _hoisted_3 = { class: "flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple" }
const _hoisted_4 = { class: "flex flex-col mt-14 flex-grow" }
const _hoisted_5 = { class: "flex flex-col justify-start w-4/5 max-md:ml-0 max-md:w-full" }
const _hoisted_6 = { class: "flex flex-col mt-20 w-full max-md:mt-10 max-md:max-w-full" }
const _hoisted_7 = { class: "flex flex-col ml-16 max-w-full" }
const _hoisted_8 = { class: "flex justify-center mb-4" }
const _hoisted_9 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_10 = {
  key: 0,
  class: "self-center mt-8 w-full px-10"
}
const _hoisted_11 = { class: "flex gap-5 max-md:flex-col" }
const _hoisted_12 = { class: "flex flex-col w-[63%] max-md:ml-0 max-md:w-full" }
const _hoisted_13 = {
  id: "unevenGridContainer",
  class: "flex flex-col w-full mx-auto w-full bg-white max-md:mt-8 max-md:max-w-full"
}
const _hoisted_14 = { class: "flex flex-wrap items-center pt-2 pr-2 pl-3.5 text-xs whitespace-nowrap text-zinc-700 max-md:pr-5" }
const _hoisted_15 = { class: "flex flex-col bg-gray-200 w-full rounded-t-lg px-2" }
const _hoisted_16 = { class: "flex flex-row justify-start gap-2 ml-2 pt-2 pt-3" }
const _hoisted_17 = { class: "flex flex-row bg-white rounded-t-2xl w-full justify-between max-w-xs p-1" }
const _hoisted_18 = { class: "flex flex-row justify-start items-center" }
const _hoisted_19 = { class: "ml-2" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "flex flex-row justify-end items-center mr-3" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "flex flex-row items-center ml-2" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "flex flex-col w-full px-2" }
const _hoisted_26 = { class: "flex flex-row justify-start items-center gap-2 ml-2 py-2" }
const _hoisted_27 = { class: "flex flex-row bg-gray-100 w-full p-1 rounded-full items-center whitespace-nowrap overflow-ellipsis overflow-hidden" }
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = { class: "ml-2 overflow-hidden" }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = {
  key: 0,
  class: "flex gap-2 bg-gray-100 p-6 justify-center w-full max-md:max-w-full"
}
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { class: "flex flex-col ml-5 w-[37%] max-md:ml-0 max-md:w-full" }
const _hoisted_34 = { class: "flex flex-col max-md:mt-8" }
const _hoisted_35 = { class: "flex-flex-col" }
const _hoisted_36 = { class: "flex flex-col" }
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { class: "flex flex-wrap justify-center mt-auto items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsList = _resolveComponent("StepsList")!
  const _component_CurrentStep = _resolveComponent("CurrentStep")!
  const _component_ProfileCard = _resolveComponent("ProfileCard")!
  const _component_TemplateSelector = _resolveComponent("TemplateSelector")!
  const _component_CardStyling = _resolveComponent("CardStyling")!
  const _component_GetCode = _resolveComponent("GetCode")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("aside", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-col ml-10 text-left" }, [
            _createElementVNode("span", { class: "text-white text-3xl font-semibold leading-9" }, [
              _createTextVNode(" Create An "),
              _createElementVNode("br"),
              _createTextVNode(" Expert Program ")
            ]),
            _createElementVNode("span", { class: "text-white text-base font-normal leading-6 font-sans mt-3" }, " Start setting up an expert program")
          ], -1)),
          _createVNode(_component_StepsList, { currentStep: 2 })
        ])
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "self-start text-3xl font-semibold leading-tight text-zinc-800" }, " Set-up Directory ", -1)),
            _createVNode(_component_CurrentStep, {
              currentStep: _ctx.currentStep,
              firstStepText: "Setup Directory"
            }, null, 8, ["currentStep"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "Error Icon",
                      class: "w-4 h-4"
                    })
                  ], -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (!_ctx.userStore.createExpertProgram?.directoryCreated)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("section", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _cache[3] || (_cache[3] = _createElementVNode("img", {
                              loading: "lazy",
                              src: _imports_1,
                              class: "object-fit shrink-0 self-stretch my-auto",
                              alt: ""
                            }, null, -1)),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", _hoisted_19, [
                                  _createElementVNode("img", {
                                    loading: "lazy",
                                    src: 
                                  _ctx.logoFromWebsite(
                                    _ctx.userStore.companyWebsite || ''
                                  )
                                ,
                                    class: "w-8 h-8 mr-2 rounded-full"
                                  }, null, 8, _hoisted_20)
                                ]),
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.userStore.companyName), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("div", { innerHTML: _ctx.CLOSE_ICON_SM }, null, 8, _hoisted_22)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_23, [
                              _createElementVNode("div", {
                                innerHTML: _ctx.PLUS_ICON,
                                class: "text-gray-600"
                              }, null, 8, _hoisted_24)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _cache[4] || (_cache[4] = _createElementVNode("img", {
                              loading: "lazy",
                              src: _imports_2,
                              class: "object-fit shrink-0 self-stretch my-auto",
                              alt: ""
                            }, null, -1)),
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("div", {
                                innerHTML: _ctx.LOCK_ICON,
                                class: "text-gray-600 ml-2"
                              }, null, 8, _hoisted_28),
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.userStore.companyWebsite) + "/experts", 1)
                              ])
                            ]),
                            _createElementVNode("div", null, [
                              _createElementVNode("div", {
                                innerHTML: _ctx.BROWSER_MENU_ICON,
                                class: "text-gray-600 ml-2"
                              }, null, 8, _hoisted_30)
                            ])
                          ])
                        ]),
                        (_ctx.isGridUneven)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (n) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: n,
                                  class: "space-y-3"
                                }, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles.filter(
                            (_, index) => index % _ctx.columns === n - 1
                          ), (profile) => {
                                    return (_openBlock(), _createBlock(_component_ProfileCard, {
                                      key: profile.id,
                                      profile: profile,
                                      isCentered: _ctx.profileCenterAligned,
                                      isGridUneven: _ctx.isGridUneven,
                                      profileImageClass: _ctx.profileImageClass,
                                      profileImageAndNameLayoutClass: 
                            _ctx.profileImageAndNameLayoutClass
                          ,
                                      fontFamily: _ctx.fontFamily,
                                      boldness: _ctx.boldness,
                                      textColor: _ctx.textColor,
                                      backgroundColor: _ctx.backgroundColor,
                                      fontSize: _ctx.fontSize,
                                      class: "flex-shrink-0"
                                    }, null, 8, ["profile", "isCentered", "isGridUneven", "profileImageClass", "profileImageAndNameLayoutClass", "fontFamily", "boldness", "textColor", "backgroundColor", "fontSize"]))
                                  }), 128))
                                ]))
                              }), 128))
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_32, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["flex flex-wrap bg-gray-100 p-6 items-start w-full max-md:max-w-full", _ctx.profileCardsGap])
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles, (profile) => {
                                  return (_openBlock(), _createBlock(_component_ProfileCard, {
                                    key: profile.id,
                                    profile: profile,
                                    isCentered: _ctx.profileCenterAligned,
                                    profileImageClass: _ctx.profileImageClass,
                                    isGridUneven: _ctx.isGridUneven,
                                    profileImageAndNameLayoutClass: 
                            _ctx.profileImageAndNameLayoutClass
                          ,
                                    fontFamily: _ctx.fontFamily,
                                    boldness: _ctx.boldness,
                                    textColor: _ctx.textColor,
                                    backgroundColor: _ctx.backgroundColor,
                                    fontSize: _ctx.fontSize,
                                    class: "flex-shrink-0"
                                  }, null, 8, ["profile", "isCentered", "profileImageClass", "isGridUneven", "profileImageAndNameLayoutClass", "fontFamily", "boldness", "textColor", "backgroundColor", "fontSize"]))
                                }), 128))
                              ], 2)
                            ]))
                      ])
                    ])
                  ]),
                  _createElementVNode("section", _hoisted_33, [
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("div", _hoisted_35, [
                        _createVNode(_component_TemplateSelector, {
                          initialSelected: _ctx.selectedTemplate,
                          onTemplateSelected: _ctx.selectTemplate
                        }, null, 8, ["initialSelected", "onTemplateSelected"])
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        _createVNode(_component_CardStyling, {
                          fontFamily: _ctx.fontFamily,
                          fontSize: _ctx.fontSize,
                          buttonTextColor: _ctx.textColor,
                          boldness: _ctx.boldness,
                          buttonBackgroundColor: _ctx.backgroundColor,
                          onFontFamilyChanged: _ctx.handleFontChange,
                          onBoldnessChanged: _ctx.handleBoldnessChange,
                          onButtonTextColorChanged: _ctx.handleTextColorChange,
                          onButtonBackgroundColorChanged: 
                        _ctx.handleBackgroundColorChange
                      ,
                          onFontSizeChanged: _ctx.handleFontSizeChange
                        }, null, 8, ["fontFamily", "fontSize", "buttonTextColor", "boldness", "buttonBackgroundColor", "onFontFamilyChanged", "onBoldnessChanged", "onButtonTextColorChanged", "onButtonBackgroundColorChanged", "onFontSizeChanged"])
                      ])
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_37, [
                _createVNode(_component_GetCode)
              ]))
        ]),
        _createElementVNode("footer", _hoisted_38, [
          _createVNode(_component_FooterSection, {
            isDisabled: _ctx.isDisabled,
            onNext: _ctx.goNext
          }, null, 8, ["isDisabled", "onNext"])
        ])
      ])
    ])
  ]))
}