import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full p-6 bg-gray-50 shadow-md rounded-md" }
const _hoisted_2 = {
  key: 0,
  class: "mt-3 text-red-500 mb-2"
}
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "flex flex-row gap-3" }
const _hoisted_5 = { class: "flex flex-col mb-4 w-1/2" }
const _hoisted_6 = { class: "flex flex-col mb-4 w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.paymentError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.paymentError), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BaseLabel, {
        forAttr: "cardNumber",
        text: "Card Number",
        class: "block text-sm font-medium text-gray-700"
      }),
      _cache[0] || (_cache[0] = _createElementVNode("div", {
        id: "card-number",
        class: "mt-1 p-2 border border-gray-300 rounded-md"
      }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BaseLabel, {
          forAttr: "expirationDate",
          text: "Expiration Date",
          class: "block text-sm font-medium text-gray-700"
        }),
        _cache[1] || (_cache[1] = _createElementVNode("div", {
          id: "card-expiry",
          class: "mt-1 p-2 border border-gray-300 rounded-md"
        }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BaseLabel, {
          forAttr: "cvc",
          text: "CVC",
          class: "block text-sm font-medium text-gray-700"
        }),
        _cache[2] || (_cache[2] = _createElementVNode("div", {
          id: "card-cvc",
          class: "mt-1 p-2 border border-gray-300 rounded-md"
        }, null, -1))
      ])
    ])
  ]))
}