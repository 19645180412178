<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="bg-white p-6 rounded-md shadow-lg w-full sm:w-96" @click.stop>
      <header class="flex flex-col w-full text-center">
        <h1 class="text-3xl font-semibold leading-tight text-zinc-800">
          Add New Software
        </h1>
        <p class="mt-3 text-base text-gray-500">
          Add new software that you think you are an expert in
        </p>
      </header>
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      >
        <!-- Icon Container -->
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <!-- Custom Image Icon -->
          <img
            src="../../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
      <form @submit.prevent="submitForm" class="flex flex-col mt-6 w-full">
        <!-- Form fields -->
        <div
          class="flex flex-col w-full text-sm leading-none whitespace-nowrap"
        >
          <label for="softwareName" class="font-medium text-zinc-800 text-left"
            >Name</label
          >
          <input
            type="text"
            id="softwareName"
            v-model="name"
            class="overflow-hidden self-stretch px-3.5 py-2.5 mt-1 w-full bg-white rounded-md border border-gray-300 border-solid shadow-sm"
            placeholder="Enter software name"
            required
          />
        </div>
        <div class="flex flex-col mt-6 w-full text-sm leading-none text-left">
          <label for="softwareUrl" class="font-medium text-zinc-800"
            >Add URL</label
          >
          <div
            class="flex overflow-hidden items-center mt-1 w-full text-gray-500 whitespace-nowrap rounded-md border border-gray-300 border-solid"
          >
            <span class="self-stretch py-2.5 pr-3 pl-3.5 bg-gray-100 rounded-md"
              >https://</span
            >
            <input
              type="text"
              id="url"
              v-model="url"
              class="overflow-hidden flex-1 shrink self-stretch py-2.5 pr-3.5 pl-3 my-auto bg-white rounded-none"
              placeholder="www.example.com"
              required
            />
          </div>
        </div>
        <!-- Image upload section -->
        <div class="flex flex-col mt-6 w-full">
          <label
            for="logoUpload"
            class="text-sm font-medium leading-none text-zinc-800 text-left"
            >Logo (Optional)</label
          >
          <div
            v-if="!imageSrc"
            class="flex flex-col items-center px-7 pt-6 pb-7 mt-1 w-full rounded-md border-2 border-gray-300 border-dashed"
            @dragover.prevent
            @dragenter.prevent
            @dragleave.prevent
            @drop.prevent="handleDrop"
          >
            <div v-html="ADD_IMAGE_ICON"></div>
            <div
              class="flex flex-col items-center text-center mt-1 text-sm font-medium leading-none"
            >
              <label
                for="logoUpload"
                class="text-violet-600 bg-white rounded-md cursor-pointer px-4 py-1 text-center"
              >
                Upload a Logo
                <span class="ml-1 text-gray-600 text-sm">or drag and drop</span>
              </label>
              <p class="mt-1 text-xs leading-none text-gray-500">
                PNG or JPG up to 5 MB
              </p>
            </div>
          </div>
          <div
            v-if="imageSrc"
            class="flex items-center w-full mt-2 border border-gray-300 rounded-md"
          >
            <img
              :src="imageSrc"
              class="object-contain w-12 aspect-square mr-4"
              alt="Selected image preview"
            />
            <div class="flex sm:flex-row ml-auto gap-2">
              <BaseButton
                type="button"
                class="px-4 py-2 text-xs sm:text-sm font-medium leading-none text-violet-600 bg-transparent"
                @click="handleChangeClick"
              >
                Update
              </BaseButton>
              <BaseButton
                type="button"
                class="text-black-600 hover:text-red-800 focus:outline-none mr-2"
                @click="handleCloseClick"
              >
                <div v-html="CLOSE_ICON_SM"></div>
              </BaseButton>
            </div>
          </div>
          <!-- Hidden file input -->
          <input
            ref="fileInput"
            type="file"
            id="logoUpload"
            class="sr-only"
            accept="image/png,image/jpeg,image/jpg"
            @change="handleImageUpload"
          />
        </div>
        <div class="flex flex-col mt-6 w-full text-base whitespace-nowrap">
          <div class="flex flex-col w-full">
            <button
              type="submit"
              class="overflow-hidden self-stretch px-4 py-2.5 w-full font-semibold text-white bg-violet-600 rounded-md shadow-sm"
              :disabled="isDisabled"
            >
              {{ addButtonText }}
            </button>
            <button
              type="button"
              class="gap-1.5 self-stretch mt-6 w-full font-medium text-gray-500"
              @click="closeModal"
              :disabled="isDisabled"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { useMutation } from "@vue/apollo-composable";
import { ApolloError, gql } from "@apollo/client/core";
import {
  ADD_IMAGE_ICON,
  CLOSE_ICON_SM,
} from "@/assets/svg/shared/svg-constants";

const CREATE_PROGRAM_MUTATION = gql`
  mutation CreateProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      programs {
        id
        name
        website
        logo
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "AddProgramModal",
  components: {
    BaseButton,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const errorMessage = ref("");
    const imageSrc = ref<string>("");
    const fileInput = ref<HTMLInputElement | null>(null);
    const name = ref("");
    const url = ref("");
    const isDisabled = ref(false);

    const {
      mutate: CreateProgram,
      onDone,
      onError,
    } = useMutation(CREATE_PROGRAM_MUTATION);

    const submitForm = async () => {
      if (!/\.[a-zA-Z]{2,}$/.test(url.value)) {
        errorMessage.value =
          "Website url should end with a TLD, such as .io, .com, .co, etc";
        return;
      }
      isDisabled.value = true;
      errorMessage.value = "";
      CreateProgram({
        input: {
          programParams: [
            {
              name: name.value,
              website: `https://${url.value}`,
              logo: getCleanBase64(imageSrc.value),
              destroy: false,
            },
          ],
          bulk: false,
        },
      });
    };

    onDone((response) => {
      isDisabled.value = false;
      if (response.data.createProgram.errors.length > 0) {
        errorMessage.value = response.data.createProgram.errors.join(".");
      } else {
        let program = extractProgram(response.data.createProgram.programs[0]);
        name.value = "";
        url.value = "";
        imageSrc.value = "";
        emit("programAdded", program);
        closeModal();
      }
    });

    onError((mutationError: ApolloError) => {
      isDisabled.value = false;
      errorMessage.value = "Something went wrong, please try again";
      console.log("Mutation error, ", mutationError);
    });

    function extractProgram(response: any) {
      return {
        id: response.id,
        name: response.name,
        experts: 0,
        logo: response.logo,
        website: response.website,
      };
    }

    const handleChangeClick = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleCloseClick = () => {
      imageSrc.value = "";
    };

    const handleImageUpload = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        const { type, size } = file;

        const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png", "image/jpg"];
        const MAX_SIZE_MB = 5;

        if (!ALLOWED_MIME_TYPES.includes(type)) {
          errorMessage.value = "Only JPG, JPEG, and PNG files are allowed.";
          return;
        }

        if (size > MAX_SIZE_MB * 1024 * 1024) {
          errorMessage.value = "File size exceeds 5MB.";
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          imageSrc.value = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    };

    const getCleanBase64 = (base64String: string) => {
      return base64String.replace(/^data:image\/(jpeg|jpg|png);base64,/, "");
    };

    const closeModal = () => {
      name.value = "";
      url.value = "";
      imageSrc.value = "";
      errorMessage.value = "";
      emit("update:isVisible", false);
    };

    const handleDrop = (event: DragEvent) => {
      const files = event.dataTransfer?.files;
      if (files && files.length > 0) {
        const file = files[0];
        processFile(file);
      }
    };

    const processFile = (file: File) => {
      const { type, size } = file;
      const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png", "image/jpg"];
      const MAX_SIZE_MB = 5;

      if (!ALLOWED_MIME_TYPES.includes(type)) {
        errorMessage.value = "Only JPG, JPEG, and PNG files are allowed.";
        return;
      }

      if (size > MAX_SIZE_MB * 1024 * 1024) {
        errorMessage.value = "File size exceeds 5MB.";
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        imageSrc.value = reader.result as string;
      };
      reader.readAsDataURL(file);
    };

    const addButtonText = computed(() => {
      if (isDisabled.value) {
        return "Adding program.......";
      } else {
        return "Add";
      }
    });

    return {
      name,
      url,
      closeModal,
      submitForm,
      handleImageUpload,
      imageSrc,
      handleChangeClick,
      handleCloseClick,
      fileInput,
      CreateProgram,
      errorMessage,
      isDisabled,
      handleDrop,
      addButtonText,
      ADD_IMAGE_ICON,
      CLOSE_ICON_SM,
    };
  },
});
</script>
