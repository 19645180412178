import avatar from "@/assets/shared/avatar-2.svg";

export const directoryProfiles = [
  {
    id: "1",
    uuid: "1",
    firstName: "Courtney",
    lastName: "Henry",
    country: "United States",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary: "I'm the co-founder.",
      },
    ],
  },
  {
    id: "2",
    uuid: "2",
    firstName: "Alex",
    lastName: "Johnson",
    country: "United Kingdom",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary: "As the CTO of TechInnovate, I know stuff.",
      },
    ],
  },
  {
    id: "3",
    uuid: "3",
    firstName: "Sarah",
    lastName: "Lee",
    country: "Canada",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary: "With over 15 years in digital marketing.",
      },
    ],
  },
  {
    id: "4",
    uuid: "4",
    firstName: "Michael",
    lastName: "Chang",
    country: "Singapore",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary: "Experienced operations leader with a track.",
      },
    ],
  },
  {
    id: "5",
    uuid: "5",
    firstName: "Emma",
    lastName: "Rodriguez",
    country: "Spain",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary: "Passionate about creating user-centric designs.",
      },
    ],
  },
  {
    id: "6",
    uuid: "6",
    firstName: "Courtney",
    lastName: "Henry",
    country: "United States",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary:
          "I'm the co-founder and CEO of Predictable Revenue. We use Clay to double the productivity of our 30+ SDRs We use Clay to double the productivity of our 30+ SDRs.. We use Clay to double the productivity of our 30+ SDRs.. We use Clay to double the productivity of our 30+ SDRs.....",
      },
    ],
  },
  {
    id: "7",
    uuid: "7",
    firstName: "Alex",
    lastName: "Johnson",
    country: "United Kingdom",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary:
          "As the CTO of TechInnovate, I leverage my expertise to drive cutting-edge solutions in AI and machine learning...",
      },
    ],
  },
  {
    id: "8",
    uuid: "8",
    firstName: "Sarah",
    lastName: "Lee",
    country: "Canada",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary:
          "With over 15 years in digital marketing, I've helped startups achieve 10x growth...",
      },
    ],
  },
  {
    id: "9",
    uuid: "9",
    firstName: "Michael",
    lastName: "Chang",
    country: "Singapore",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary: "Experienced operations.",
      },
    ],
  },
];
