<template>
  <div
    class="flex flex-col text-left gap-3 border border-gray-300 border-solid px-4 py-6 rounded-md"
  >
    <img
      src="../../../../assets/shared/salesforce-logo.svg"
      alt="Salesforce Logo"
      class="w-12 h-auto"
    />
    <div class="flex flex-col gap-1">
      <span class="text-gray-900 text-xl font-semibold leading-7 tracking-wide"
        >Connect Salesforce</span
      >
      <span class="text-gray-500 text-sm font-normal leading-5 tracking-wide"
        >Connect Slash Expert to a Salesforce admin <br />
        and begin enriching your records.</span
      >
    </div>
    <div class="flex flex-col w-full">
      <BaseButton
        type="button"
        @click="connectSalesforce()"
        :disabled="loading"
        class="flex flex-col justify-center items-center p-2 w-full rounded-lg text-sm font-medium leading-none text-center"
        :class="{
          'text-primary-purple bg-violet-200': salesforceSynced,
          'text-white bg-primary-purple': !salesforceSynced,
        }"
      >
        <span v-if="!salesforceSynced">Connect Now</span>
        <span v-else>View Details</span>
        <span v-if="loading">Loading...</span>
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "SalesforceCard",
  components: {
    BaseButton,
  },
  props: {
    salesforceSynced: {
      type: Boolean,
      required: true,
    },
    connectSalesforce: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
