import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "checkbox",
      id: _ctx.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
      class: _normalizeClass([_ctx.baseClasses, _ctx.checked ? _ctx.checkedClasses : _ctx.uncheckedClasses]),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args)))
    }, null, 42, _hoisted_2), [
      [_vModelCheckbox, _ctx.checked]
    ]),
    _createElementVNode("label", {
      for: _ctx.id,
      class: _normalizeClass(_ctx.labelClasses)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 10, _hoisted_3)
  ]))
}