import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../../assets/shared/hubspot-logo.svg'


const _hoisted_1 = { class: "flex flex-col text-left gap-3 border border-gray-300 border-solid px-4 py-6 rounded-md" }
const _hoisted_2 = { class: "flex flex-col w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      src: _imports_0,
      alt: "Hubspot Logo",
      class: "w-20 h-auto mb-2"
    }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col gap-1" }, [
      _createElementVNode("span", { class: "text-gray-900 text-xl font-semibold leading-7 tracking-wide" }, "Connect Hubspot"),
      _createElementVNode("span", { class: "text-gray-500 text-sm font-normal leading-5 tracking-wide" }, [
        _createTextVNode("Connect Slash Expert to a Hubspot admin"),
        _createElementVNode("br"),
        _createTextVNode(" and begin enriching your records.")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseButton, {
        type: "button",
        class: "flex flex-col justify-center items-center p-2 w-full rounded-lg text-sm font-medium leading-none text-indigo-700 bg-indigo-50 text-center"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, "Coming Soon", -1)
        ])),
        _: 1
      })
    ])
  ]))
}