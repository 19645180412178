export const fontFamilies = [
  "Roboto Slab",
  "Merriweather",
  "Playfair Display",
  "Lora",
  "PT Serif",
  "Noto Serif",
  "Libre Baskerville",
  "Vollkorn",
  "Slabo",
  "Roboto",
  "Open Sans",
  "Lato",
  "Montserrat",
  "Poppins",
  "Fira Sans",
  "Roboto Condensed",
  "Proza Libre",
  "Work Sans",
  "Oswald",
  "Bebas Neue",
  "Lobster",
  "Comfortaa",
  "Abril Fatface",
  "Alfa Slab One",
  "Cormorant",
  "Raleway",
  "Italiana",
  "Antic Didone",
  "Cinzel",
  "Dancing Script",
  "Pacifico",
  "Shadows Into Light",
  "Indie Flower",
  "Caveat",
  "Homemade Apple",
  "League Script",
  "Cedarville Cursive",
  "Berkshire Swash",
  "La Belle Aurore",
  "Roboto Mono",
  "Inconsolata",
  "Source Code Pro",
  "IBM Plex Mono",
  "Space Mono",
  "Noto Sans Mono",
  "Anonymous Pro",
  "Azeret Mono",
  "Courier Prime",
  "Inter",
];

export const googleFontFamiliesLink =
  "https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Merriweather&family=Playfair+Display&family=Lora&family=PT+Serif&family=Noto+Serif&family=Libre+Baskerville&family=Vollkorn&family=Slabo&family=Roboto&family=Open+Sans&family=Lato&family=Montserrat&family=Poppins&family=Fira+Sans&family=Roboto+Condensed&family=Proza+Libre&family=Work+Sans&family=Oswald&family=Bebas+Neue&family=Lobster&family=Comfortaa&family=Abril+Fatface&family=Alfa+Slab+One&family=Cormorant&family=Raleway&family=Italiana&family=Antic+Didone&family=Cinzel&family=Dancing+Script&family=Pacifico&family=Shadows+Into+Light&family=Indie+Flower&family=Caveat&family=Homemade+Apple&family=League+Script&family=Cedarville+Cursive&family=Berkshire+Swash&family=La+Belle+Aurore&family=Roboto+Mono&family=Inconsolata&family=Source+Code+Pro&family=IBM+Plex+Mono&family=Space+Mono&family=Noto+Sans+Mono&family=Anonymous+Pro&family=Azeret+Mono&family=Courier+Prime&family=Inter&display=swap";
