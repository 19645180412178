<template>
  <div class="flex flex-col flex-1">
    <div class="relative">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search for software..."
        @focus="isFocused = true"
        @blur="handleBlur"
        class="px-3.5 py-2.5 w-full text-sm leading-none bg-white rounded-md border border-gray-300 shadow-sm"
      />

      <ul
        v-if="isFocused && searchQuery.length > 0 && !isLoading"
        class="absolute z-50 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto w-full"
      >
        <li
          v-for="(card, index) in limitedResults"
          :key="index"
          @click="selectProgram(card)"
          class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-left"
        >
          <img
            :src="programLogo(card)"
            alt="Logo"
            class="w-8 h-8 mr-2 rounded-full"
          />
          {{ card.name }}
        </li>

        <li
          v-if="limitedResults.length === 0 && !isLoading"
          class="px-4 py-2 text-gray-500"
        >
          <div class="flex-1 mb-1">
            <p class="text-left">Software you are looking for is not found</p>
          </div>
          <div class="flex justify-center">
            <BaseButton
              type="button"
              @click="showCreateSoftwareModal = true"
              class="border border-gray-300 px-4 py-2 text-base text-gray-700 rounded-md"
            >
              + Add New Software
            </BaseButton>
          </div>
        </li>
      </ul>

      <div v-if="selectedProgram" class="flex flex-col inline-flex">
        <span class="text-xs">Selected software</span>
        <div
          class="flex items-center bg-gray-200 rounded-md px-2 py-1 mb-2 inline-flex"
        >
          <img
            :src="programLogo(selectedProgram)"
            alt="Logo"
            class="w-6 h-6 mr-1 rounded-full"
          />
          <span class="text-sm font-semibold">{{ selectedProgram.name }}</span>
          <BaseButton
            type="button"
            @click="handleRemoveCard"
            class="ml-2 text-xs text-gray-500"
          >
            x
          </BaseButton>
        </div>
      </div>

      <AddProgramModal
        :isVisible="showCreateSoftwareModal"
        @update:isVisible="showCreateSoftwareModal = $event"
        @programAdded="handleProgramCreated"
      />
    </div>

    <div class="flex flex-col flex-1">
      <div
        v-if="
          programAdded &&
          (!limitedResults ||
            !searchQuery ||
            !isFocused ||
            (limitedResults.length === 0 && !programAdded))
        "
        class="flex flex-wrap gap-4 px-2 pt-2 pb-8 w-full text-xs leading-none text-gray-500 bg-gray-50 rounded-md min-h-12 overflow-auto"
      >
        <div>
          <SoftwareCard
            :id="programAdded?.id"
            :name="programAdded?.name"
            :experts="programAdded?.experts"
            :logo="programLogo(programAdded)"
            isAdded
            @remove-card="handleRemoveCard"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import AddProgramModal from "./AddProgramModal.vue";
import SoftwareCard from "../SoftwareCard.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { Program } from "@/types/expert-program-types";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import debounce from "lodash.debounce";
import { useUserStore } from "@/stores/user";

const GET_PROGRAM = gql`
  query GetProgram($userBased: Boolean!, $availablePrograms: Boolean!) {
    getProgram(userBased: $userBased, availablePrograms: $availablePrograms) {
      programs {
        id
        name
        website
        logo
        experts
        payout
        description
        companyId
        uuid
      }
    }
  }
`;

export default defineComponent({
  name: "SoftwareSearch",
  components: {
    AddProgramModal,
    SoftwareCard,
    BaseButton,
  },
  setup(_, { emit }) {
    const searchQuery = ref("");
    const isFocused = ref(false);
    const isLoading = ref(false);
    const limit = 10;
    const selectedProgram = ref<Program | null>(null);
    const filteredNames = ref<Program[]>([]);
    const programAdded = ref<Program | null>(null);
    const programsList = ref<Program[]>([]);
    const showCreateSoftwareModal = ref(false);
    const userStore = useUserStore();
    console.log("userStore from software search", userStore);

    const fetchProgramData = () => {
      console.log("Fetching program data ");
      const {
        result: queryResult,
        error: queryError,
        refetch: refetchPrograms,
      } = useQuery(GET_PROGRAM, {
        userBased: false,
        availablePrograms: true,
      });

      refetchPrograms();

      watch(
        () => queryResult.value,
        (newValue) => {
          if (newValue) {
            programsList.value = newValue.getProgram.programs;
            console.log("Program are ", programsList.value);
          }
        },
        { immediate: true }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            console.log("Error heppaned ", newError);
          }
        }
      );
    };

    fetchProgramData();

    watch(searchQuery, () => {
      isLoading.value = true;
      performSearch();
    });

    const performSearch = debounce(() => {
      filteredNames.value = programsList.value
        .filter((card) =>
          card.name.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
        .slice(0, limit);
      isLoading.value = false;
    }, 100);

    const handleBlur = () => {
      setTimeout(() => {
        isFocused.value = false;
      }, 300);
    };

    const selectProgram = (card: Program) => {
      selectedProgram.value = card;
      searchQuery.value = "";
      emit("selectProgram", card);
    };

    const handleProgramCreated = (newProgram: Program) => {
      searchQuery.value = "";
      selectProgram(newProgram);
      programsList.value = [...programsList.value, newProgram];
    };

    const handleRemoveCard = () => {
      selectedProgram.value = null;
      emit("removeCard");
    };

    const programLogo = (program: Program) => {
      return program.logo ? program.logo : logoFromWebsite(program.website);
    };

    const logoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";

      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");

        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    return {
      programLogo,
      isLoading,
      isFocused,
      selectedProgram,
      limitedResults: filteredNames,
      programAdded,
      showCreateSoftwareModal,
      searchQuery,
      handleBlur,
      selectProgram,
      handleProgramCreated,
      handleRemoveCard,
    };
  },
});
</script>
