import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-3 text-sm text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckBox = _resolveComponent("CheckBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["p-4 border border-gray-300 rounded-t-md", {
        'bg-primary-purple bg-opacity-10': _ctx.localConvertWebsiteVisitors,
      }])
    }, [
      _createVNode(_component_CheckBox, {
        modelValue: _ctx.localConvertWebsiteVisitors,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localConvertWebsiteVisitors) = $event)),
        id: "convertVisitors",
        "base-classes": "relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none",
        "checked-classes": "bg-violet-600 border-violet-600",
        "unchecked-classes": "bg-white border-violet-600",
        "label-classes": "text-gray-500 ml-2",
        onChange: _cache[1] || (_cache[1] = ($event: any) => (
          _ctx.emitChange('convertWebsiteVisitors', _ctx.localConvertWebsiteVisitors)
        ))
      }, {
        default: _withCtx(() => _cache[14] || (_cache[14] = [
          _createTextVNode(" Convert more website visitors ")
        ])),
        _: 1
      }, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["p-4 border-b border-l border-r border-gray-300", { 'bg-primary-purple bg-opacity-10': _ctx.localGenerateIntentSignals }])
    }, [
      _createVNode(_component_CheckBox, {
        modelValue: _ctx.localGenerateIntentSignals,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localGenerateIntentSignals) = $event)),
        id: "generateIntentSignals",
        "base-classes": "relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none",
        "checked-classes": "bg-violet-600 border-violet-600",
        "unchecked-classes": "bg-white border-violet-600",
        "label-classes": "text-gray-500 ml-2",
        onChange: _cache[3] || (_cache[3] = ($event: any) => (
          _ctx.emitChange('generateIntentSignals', _ctx.localGenerateIntentSignals)
        ))
      }, {
        default: _withCtx(() => _cache[15] || (_cache[15] = [
          _createTextVNode(" Generate new intent signals ")
        ])),
        _: 1
      }, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["p-4 border-b border-l border-r border-gray-300", {
        'bg-primary-purple bg-opacity-10': _ctx.localEnableSales,
      }])
    }, [
      _createVNode(_component_CheckBox, {
        modelValue: _ctx.localEnableSales,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localEnableSales) = $event)),
        id: "enableSales",
        "base-classes": "relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none",
        "checked-classes": "bg-violet-600 border-violet-600",
        "unchecked-classes": "bg-white border-violet-600",
        "label-classes": "text-gray-500 ml-2",
        onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.emitChange('enableSales', _ctx.localEnableSales)))
      }, {
        default: _withCtx(() => _cache[16] || (_cache[16] = [
          _createTextVNode(" Enable sales to accelerate pipeline ")
        ])),
        _: 1
      }, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["p-4 border-b border-l border-r border-gray-300", {
        'bg-primary-purple bg-opacity-10': _ctx.localDriveConversion,
      }])
    }, [
      _createVNode(_component_CheckBox, {
        modelValue: _ctx.localDriveConversion,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localDriveConversion) = $event)),
        id: "driveConversion",
        "base-classes": "relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none",
        "checked-classes": "bg-violet-600 border-violet-600",
        "unchecked-classes": "bg-white border-violet-600",
        "label-classes": "text-gray-500 ml-2",
        onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.emitChange('driveConversion', _ctx.localDriveConversion)))
      }, {
        default: _withCtx(() => _cache[17] || (_cache[17] = [
          _createTextVNode(" Use as CTA in ads to drive conversion ")
        ])),
        _: 1
      }, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["p-4 border-b border-l border-r border-gray-300", {
        'bg-primary-purple bg-opacity-10': _ctx.localLateStageCustomerReferences,
      }])
    }, [
      _createVNode(_component_CheckBox, {
        modelValue: _ctx.localLateStageCustomerReferences,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localLateStageCustomerReferences) = $event)),
        id: "lateStageCustomerReferences",
        "base-classes": "relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none",
        "checked-classes": "bg-violet-600 border-violet-600",
        "unchecked-classes": "bg-white border-violet-600",
        "label-classes": "text-gray-500 ml-2",
        onChange: _cache[9] || (_cache[9] = ($event: any) => (
          _ctx.emitChange(
            'lateStageCustomerReferences',
            _ctx.localLateStageCustomerReferences
          )
        ))
      }, {
        default: _withCtx(() => _cache[18] || (_cache[18] = [
          _createTextVNode(" Use for late stage customer backchannel/references ")
        ])),
        _: 1
      }, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["p-4 border-b border-l border-r border-gray-300", {
        'bg-primary-purple bg-opacity-10': _ctx.localSdrSequenceStep,
      }])
    }, [
      _createVNode(_component_CheckBox, {
        modelValue: _ctx.localSdrSequenceStep,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localSdrSequenceStep) = $event)),
        id: "sdrSequenceStep",
        "base-classes": "relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none",
        "checked-classes": "bg-violet-600 border-violet-600",
        "unchecked-classes": "bg-white border-violet-600",
        "label-classes": "text-gray-500 ml-2",
        onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.emitChange('sdrSequenceStep', _ctx.localSdrSequenceStep)))
      }, {
        default: _withCtx(() => _cache[19] || (_cache[19] = [
          _createTextVNode(" New SDR sequence step ")
        ])),
        _: 1
      }, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["p-4 border-b border-l border-r border-gray-300 rounded-b-md", { 'bg-primary-purple bg-opacity-10': _ctx.localEngageNewCustomer }])
    }, [
      _createVNode(_component_CheckBox, {
        modelValue: _ctx.localEngageNewCustomer,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localEngageNewCustomer) = $event)),
        id: "engageNewCustomer",
        "base-classes": "relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none",
        "checked-classes": "bg-violet-600 border-violet-600",
        "unchecked-classes": "bg-white border-violet-600",
        "label-classes": "text-gray-500 ml-2",
        onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.emitChange('engageNewCustomer', _ctx.localEngageNewCustomer)))
      }, {
        default: _withCtx(() => _cache[20] || (_cache[20] = [
          _createTextVNode(" Engage new user/customer champions ")
        ])),
        _: 1
      }, 8, ["modelValue"])
    ], 2)
  ]))
}