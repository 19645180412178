import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex z-10 gap-8 justify-start items-center border-b border-gray-200 self-start mt-8 mx-10 max-md:ml-2.5" }
const _hoisted_2 = { class: "flex flex-col justify-center self-stretch my-auto text-sm font-medium leading-none text-violet-600 whitespace-nowrap" }
const _hoisted_3 = { class: "flex gap-2 justify-center items-center self-center px-1 pb-4" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex flex-col mt-8 max-w-full mx-10" }
const _hoisted_6 = { class: "flex gap-5 max-md:flex-col" }
const _hoisted_7 = { class: "flex flex-col w-[85%] max-md:ml-0 max-md:w-full" }
const _hoisted_8 = { class: "flex flex-col grow mt-3 text-sm font-medium leading-5 text-black max-md:mt-7 max-md:max-w-full" }
const _hoisted_9 = {
  ref: "codeSnippet",
  class: "self-start text-left overflow-x-auto p-4 rounded-md max-w-full break-all whitespace-pre-wrap bg-gray-100 border border-gray-300"
}
const _hoisted_10 = { class: "language-html break-all" }
const _hoisted_11 = { class: "flex flex-col ml-5 w-[15%] max-md:ml-0 max-md:w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            innerHTML: _ctx.HTML_ICON,
            class: "text-primary-purple"
          }, null, 8, _hoisted_4),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "self-stretch my-auto" }, "HTML", -1))
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex w-full bg-violet-600 min-h-[2px]" }, null, -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("pre", _hoisted_9, [
              _cache[3] || (_cache[3] = _createTextVNode("")),
              _createElementVNode("code", _hoisted_10, "\n# container can be placed anywhere in the webpage,\n# due to widgets sticky nature\n\n" + _toDisplayString(_ctx.companyWidget) + "\n\n<script src=\"https://dev-slashexperts.s3.amazonaws.com/company-widget/bundle.js\"></script>\n<link rel=\"stylesheet\" href=\"https://dev-slashexperts.s3.amazonaws.com/company-widget/bundle.css\">\n\n", 1),
              _cache[4] || (_cache[4] = _createTextVNode("\n    "))
            ], 512)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyCodeToClipboard && _ctx.copyCodeToClipboard(...args))),
            class: "overflow-hidden gap-3 self-stretch py-2.5 pr-4 pl-4 w-full text-base font-medium text-indigo-700 bg-indigo-50 rounded-md shadow-sm max-md:mt-4"
          }, _toDisplayString(_ctx.buttonText), 1)
        ])
      ])
    ])
  ], 64))
}