import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex-1 gap-4 p-4 border border-solid border-gray-300 bg-white rounded-lg" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-wrap gap-2 justify-between items-center" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "flex flex-wrap gap-2 justify-between items-center" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "flex flex-wrap gap-2 justify-between items-center" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "flex flex-wrap gap-2 justify-between items-center" }
const _hoisted_10 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex flex-col pb-3" }, [
      _createElementVNode("span", { class: "text-gray-800 font-sans text-lg font-semibold leading-6 tracking-tight" }, "Contact Field Mapping")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-gray-600 font-sans text-sm font-medium leading-5" }, "First name", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFirstName) = $event)),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateFirstName && _ctx.updateFirstName(...args))),
          class: "border rounded p-2 w-32 md:w-64"
        }, [
          _cache[8] || (_cache[8] = _createElementVNode("option", {
            value: "",
            disabled: ""
          }, "Select", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactMappingFields, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.label,
              value: option.name
            }, _toDisplayString(option.label), 9, _hoisted_4))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.localFirstName]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "text-gray-600 font-sans text-sm font-medium leading-5" }, "Last name", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localLastName) = $event)),
          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateLastName && _ctx.updateLastName(...args))),
          class: "border rounded p-2 w-32 md:w-64"
        }, [
          _cache[10] || (_cache[10] = _createElementVNode("option", {
            value: "",
            disabled: ""
          }, "Select", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactMappingFields, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.label,
              value: option.name
            }, _toDisplayString(option.label), 9, _hoisted_6))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.localLastName]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-gray-600 font-sans text-sm font-medium leading-5" }, "Company", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localCompany) = $event)),
          onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.updateCompany && _ctx.updateCompany(...args))),
          class: "border rounded p-2 w-32 md:w-64"
        }, [
          _cache[12] || (_cache[12] = _createElementVNode("option", {
            value: "",
            disabled: ""
          }, "Select", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactMappingFields, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.label,
              value: option.name
            }, _toDisplayString(option.label), 9, _hoisted_8))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.localCompany]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[15] || (_cache[15] = _createElementVNode("span", { class: "text-gray-600 font-sans text-sm font-medium leading-5" }, "Title", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localTitle) = $event)),
          onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.updateTitle && _ctx.updateTitle(...args))),
          class: "border rounded p-2 w-32 md:w-64"
        }, [
          _cache[14] || (_cache[14] = _createElementVNode("option", {
            value: "",
            disabled: ""
          }, "Select", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactMappingFields, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.label,
              value: option.name
            }, _toDisplayString(option.label), 9, _hoisted_10))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.localTitle]
        ])
      ])
    ])
  ]))
}