<template>
  <div class="flex flex-wrap min-h-screen">
    <div
      class="flex flex-col w-[80%] items-start pt-4 border-t border-gray-200 gap-4"
    >
      <div class="flex flex-col text-left gap-2">
        <span class="text-black text-sm font-medium leading-5">
          Integration
        </span>
        <span class="text-gray-500 text-sm font-normal leading-6">
          Integrate your SlashExperts program with CRM. It only takes 5 minutes
          and no admin access required. It's easy!
        </span>
      </div>

      <!-- integration cards -->
      <div class="flex flex-wrap gap-4">
        <HubspotCard />
        <SalesforceCard
          :salesforceSynced="salesforceSynced"
          :loading="salesforceLoading"
          :connectSalesforce="connectSalesforce"
        />
      </div>
    </div>
    <div class="flex flex-col w-[20%] border-l border-t border-gray-200">
      <div class="flex flex-col">
        <DetailsSection />
      </div>
    </div>
  </div>
  <SyncSalesforceModal
    :isVisible="isSyncSalesforceModalVisible"
    :providerAccountName="salesforceName"
    :providerAccountEmail="salesforceEmail"
    :programId="userStore.selectedProgram?.id || ''"
    @close="closeModal"
    @updatedSettings="salesforceMappingUpdated"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import HubspotCard from "./HubspotCard.vue";
import SalesforceCard from "./SalesforceCard.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";
import SyncSalesforceModal from "./SyncSalesforceModal.vue";
import DetailsSection from "./DetailsSection.vue";

const GET_PROVIDER_ACCOUNT = gql`
  query getProviderAccount($programId: ID!) {
    getProviderAccount(programId: $programId) {
      name
      email
    }
  }
`;

const GET_SALESFORCE_OAUTH_URL = gql`
  query getSalesforceOauthUrl($programId: ID!) {
    salesforceoauth(programId: $programId) {
      oauthUrl
    }
  }
`;

export default defineComponent({
  name: "ProgramDetails",
  components: {
    HubspotCard,
    SalesforceCard,
    SyncSalesforceModal,
    DetailsSection,
  },
  setup() {
    const isSyncSalesforceModalVisible = ref(false);
    const salesforceSynced = ref(false);
    const salesforceEmail = ref("");
    const salesforceName = ref("");
    const errorMessage = ref("");
    const salesforceLoading = ref(false);
    const userStore = useUserStore();

    onMounted(() => {
      getProviderAccountData();
    });

    const getProviderAccountData = () => {
      const {
        result: providerAccountQueryResult,
        error: providerAccountQueryError,
        refetch: refetchProviderAccount,
      } = useQuery(GET_PROVIDER_ACCOUNT, {
        programId: userStore.selectedProgram?.id,
      });

      refetchProviderAccount();

      watch(
        () => providerAccountQueryResult.value,
        (newValue) => {
          if (newValue) {
            if (newValue?.getProviderAccount?.email) {
              salesforceSynced.value = true;
              openSyncSalesForceModal();
              salesforceEmail.value = newValue.getProviderAccount.email;
              salesforceName.value = newValue.getProviderAccount.name;
            } else if (
              userStore.salesforceSyncedRedirection === "program-details"
            ) {
              userStore.setSalesforceSyncedRedirection("");
            }
          }
        },
        { immediate: true }
      );

      watch(
        () => providerAccountQueryError.value,
        (newError) => {
          if (newError) {
            console.log("providerAccountQueryError ", newError);
            handleErrorMessage(newError.message);
          }
        },
        { immediate: true }
      );
    };

    const openSyncSalesForceModal = () => {
      if (salesforceSynced.value) {
        if (userStore.salesforceSyncedRedirection === "program-details") {
          userStore.setSalesforceSyncedRedirection("");
          isSyncSalesforceModalVisible.value = true;
        }
      }
    };

    const handleErrorMessage = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 2500);
    };

    const closeModal = () => {
      isSyncSalesforceModalVisible.value = false;
    };

    const salesforceMappingUpdated = () => {
      getProviderAccountData();
      isSyncSalesforceModalVisible.value = false;
    };

    const connectSalesforce = () => {
      errorMessage.value = "";

      if (salesforceSynced.value) {
        isSyncSalesforceModalVisible.value = true;
        return;
      }

      const { result: queryResult, error: queryError } = useQuery(
        GET_SALESFORCE_OAUTH_URL,
        {
          programId: userStore.selectedProgram?.id,
        }
      );

      watch(
        () => queryResult.value,
        (newValue) => {
          if (!newValue?.salesforceoauth?.oauthUrl) {
            errorMessage.value =
              "Failed to fetch Salesforce authorization URL. Please refresh the page and try again.";
          } else {
            userStore.setSalesforceSyncedRedirection("program-details");
            window.location.href = newValue?.salesforceoauth?.oauthUrl;
          }
        }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            errorMessage.value =
              "Failed to fetch Salesforce authorization URL. Please refresh the page and try again.";
          }
        }
      );
    };

    return {
      salesforceSynced,
      connectSalesforce,
      salesforceLoading,
      isSyncSalesforceModalVisible,
      salesforceName,
      salesforceEmail,
      userStore,
      salesforceMappingUpdated,
      closeModal,
    };
  },
});
</script>
