<template>
  <div class="flex items-center self-center mt-11 max-md:mt-10">
    <div class="flex gap-0.5 items-center self-stretch my-auto">
      <div
        :class="{
          'flex gap-3 items-center self-stretch my-auto rounded-xl': true,
          'text-indigo-600': currentStep === 1,
          'text-gray-500': currentStep === 2,
        }"
      >
        <div
          class="flex flex-col justify-center self-stretch p-0.5 my-auto w-5"
        >
          <div v-if="currentStep === 1">
            <div
              class="flex flex-col justify-center items-center px-0.5 w-4 h-4 rounded-full bg-indigo-200"
            >
              <div
                class="flex shrink-0 w-2 h-2 rounded-full bg-indigo-600"
              ></div>
            </div>
          </div>
          <div v-else>
            <div v-html="CHECK_CIRCLE" class="text-violet-600"></div>
          </div>
        </div>
        <div
          :class="{
            'self-stretch my-auto text-sm font-medium leading-none': true,
            'text-indigo-600': currentStep === 1,
            'text-gray-500': currentStep === 2,
          }"
        >
          {{ firstStepText }}
        </div>
      </div>
    </div>

    <!-- Divider Line -->
    <div class="hidden sm:block w-16 h-px bg-gray-300 mx-2"></div>

    <!-- Get Code Step -->
    <div class="flex gap-3 items-center self-stretch my-auto rounded-xl">
      <div
        class="flex flex-col justify-center self-stretch px-1 py-1.5 my-auto w-5"
      >
        <div v-if="currentStep === 1">
          <div class="flex shrink-0 w-2 h-2 rounded-full bg-gray-300"></div>
        </div>
        <div v-else>
          <div
            class="flex flex-col justify-center items-center px-0.5 w-4 h-4 rounded-full bg-indigo-200"
          >
            <div class="flex shrink-0 w-2 h-2 rounded-full bg-indigo-600"></div>
          </div>
        </div>
      </div>
      <div
        :class="{
          'self-stretch my-auto text-sm font-medium leading-none': true,
          'text-indigo-600': currentStep === 2,
          'text-gray-500': currentStep < 2,
        }"
      >
        Get Code
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CHECK_CIRCLE } from "@/assets/svg/shared/svg-constants";

export default defineComponent({
  name: "CurrentStep",
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    firstStepText: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      CHECK_CIRCLE,
    };
  },
});
</script>
