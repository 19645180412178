import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../../../assets/shared/failure.svg'


const _hoisted_1 = { class: "bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative" }
const _hoisted_2 = { class: "flex justify-center mb-4" }
const _hoisted_3 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_4 = { class: "flex flex-col gap-3" }
const _hoisted_5 = { class: "flex flex-col gap-2" }
const _hoisted_6 = { class: "flex flex-col justify-between gap-2" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "fixed inset-0 bg-black bg-opacity-50 px-6 flex items-center justify-center z-50",
        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["self"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            class: "absolute top-2 right-2 text-gray-500 hover:text-gray-700",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, " ✕ "),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "Error Icon",
                      class: "w-4 h-4"
                    })
                  ], -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex flex-col" }, [
                _createElementVNode("span", { class: "text-gray-800 text-base font-semibold leading-6 text-left" }, "Add Account Balance")
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_CurrencyInput, {
                  modelValue: _ctx.accountBalance,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.accountBalance) = $event)),
                  leadingText: "$",
                  trailingText: "USD",
                  placeholder: "100"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_BaseButton, {
                  type: "button",
                  class: "flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center",
                  disabled: _ctx.createAccountBalanceLoading,
                  onClick: _ctx.update
                }, {
                  default: _withCtx(() => [
                    (!_ctx.createAccountBalanceLoading)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Add Balance"))
                      : (_openBlock(), _createElementBlock("span", _hoisted_9, "Adding..."))
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}