import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/shared/failure.svg'
import _imports_1 from '../assets/signup/headline.svg'
import _imports_2 from '../assets/signup/bullet-points.svg'
import _imports_3 from '../assets/signup/footer.svg'


const _hoisted_1 = { class: "bg-white min-h-screen flex flex-col" }
const _hoisted_2 = { class: "flex flex-grow max-md:flex-col" }
const _hoisted_3 = { class: "flex flex-col w-1/2 justify-center px-2 py-6 max-md:w-full" }
const _hoisted_4 = { class: "flex flex-col w-1/2 mx-auto justify-center max-md:mt-4 max-md:w-full" }
const _hoisted_5 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
}
const _hoisted_6 = { class: "flex gap-5 justify-between mt-8" }
const _hoisted_7 = { class: "flex flex-col flex-grow basis-1/2" }
const _hoisted_8 = { class: "flex flex-col flex-grow basis-1/2" }
const _hoisted_9 = { class: "mt-6 text-left" }
const _hoisted_10 = {
  key: 0,
  class: "mt-6 text-left"
}
const _hoisted_11 = {
  key: 1,
  class: "flex flex-col mt-6 w-full text-sm leading-none text-left mb-6"
}
const _hoisted_12 = { class: "flex overflow-hidden items-center mt-1 w-full text-gray-500 whitespace-nowrap rounded-md border border-gray-300 border-solid" }
const _hoisted_13 = { class: "flex flex-col flex-grow basis-1/2" }
const _hoisted_14 = { class: "mt-6 text-left" }
const _hoisted_15 = { class: "relative" }
const _hoisted_16 = ["title"]
const _hoisted_17 = { class: "mt-6 text-left" }
const _hoisted_18 = { class: "relative" }
const _hoisted_19 = ["title"]
const _hoisted_20 = { class: "flex items-center mt-6 text-base font-medium leading-6" }
const _hoisted_21 = { class: "flex gap-1 justify-center px-16 mt-6 text-base font-medium leading-6 max-md:px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_PasswordStrength = _resolveComponent("PasswordStrength")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[19] || (_cache[19] = _createElementVNode("h1", { class: "text-3xl font-semibold leading-9 text-center text-zinc-800" }, " Sign Up ", -1)),
          _cache[20] || (_cache[20] = _createElementVNode("p", { class: "mt-3 text-base leading-6 text-center text-neutral-700" }, " Create your account to get started ", -1)),
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "Error Icon",
                    class: "w-4 h-4"
                  })
                ], -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("form", {
            onSubmit: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"])),
            class: "flex flex-col gap-2"
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_BaseLabel, {
                  forAttr: "firstName",
                  text: "First Name",
                  class: "text-sm font-medium leading-5 text-zinc-800 text-left"
                }),
                _createVNode(_component_BaseInput, {
                  type: "text",
                  id: "firstName",
                  modelValue: _ctx.firstName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
                  className: "flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full",
                  placeholder: "John",
                  required: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_BaseLabel, {
                  forAttr: "lastName",
                  text: "Last Name",
                  class: "text-sm font-medium leading-5 text-zinc-800 text-left"
                }),
                _createVNode(_component_BaseInput, {
                  type: "text",
                  id: "lastName",
                  modelValue: _ctx.lastName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
                  className: "flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full",
                  placeholder: "Doe",
                  required: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_BaseLabel, {
                forAttr: "email",
                text: "Work Email",
                class: "text-sm font-medium leading-5 text-zinc-800"
              }),
              _createVNode(_component_BaseInput, {
                id: "email",
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                placeholder: "slashexpert@gmail.com",
                className: "px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full",
                required: ""
              }, null, 8, ["modelValue"])
            ]),
            (!_ctx.invitationToken)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "companyName",
                    text: "Company Name",
                    class: "text-sm font-medium leading-5 text-zinc-800"
                  }),
                  _createVNode(_component_BaseInput, {
                    id: "companyName",
                    modelValue: _ctx.companyName,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.companyName) = $event)),
                    placeholder: "Slashexpert",
                    className: "px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full",
                    required: ""
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.invitationToken)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _cache[15] || (_cache[15] = _createElementVNode("label", {
                    for: "softwareUrl",
                    class: "font-medium text-zinc-800"
                  }, "Company Website", -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[14] || (_cache[14] = _createElementVNode("span", { class: "self-stretch py-2.5 pr-3 pl-3.5 bg-gray-100 rounded-md" }, "https://", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      id: "url",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.companyWebsite) = $event)),
                      class: "overflow-hidden flex-1 shrink self-stretch py-2.5 pr-3.5 pl-3 my-auto bg-white rounded-none",
                      placeholder: "www.example.com",
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.companyWebsite]
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_BaseLabel, {
                forAttr: "title",
                text: "Your Title",
                class: "text-sm font-medium leading-5 text-zinc-800 text-left"
              }),
              _createVNode(_component_BaseInput, {
                type: "text",
                id: "title",
                modelValue: _ctx.title,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.title) = $event)),
                className: "flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full",
                required: ""
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_BaseLabel, {
                forAttr: "password",
                text: "Set-up Password",
                class: "text-sm font-medium leading-5 text-zinc-800"
              }),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_BaseInput, {
                  type: _ctx.showPassword ? 'text' : 'password',
                  id: "password",
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.password) = $event)),
                  className: "px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full",
                  required: ""
                }, null, 8, ["type", "modelValue"]),
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.togglePasswordVisibility && _ctx.togglePasswordVisibility(...args))),
                  class: "absolute right-3 top-1/2 transform -translate-y-1/2"
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass([{
                      'fas fa-eye': _ctx.showPassword,
                      'fas fa-eye-slash': !_ctx.showPassword,
                    }, "w-5 h-5 cursor-pointer"]),
                    title: _ctx.showPassword ? 'Hide password' : 'Show password'
                  }, null, 10, _hoisted_16)
                ])
              ])
            ]),
            _createVNode(_component_PasswordStrength, {
              password: _ctx.password,
              confirmPassword: _ctx.confirmPassword
            }, null, 8, ["password", "confirmPassword"]),
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_BaseLabel, {
                forAttr: "confirmPassword",
                text: "Confirm Password",
                class: "text-sm font-medium leading-5 text-zinc-800"
              }),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_BaseInput, {
                  type: _ctx.showConfirmPassword ? 'text' : 'password',
                  id: "confirmPassword",
                  modelValue: _ctx.confirmPassword,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.confirmPassword) = $event)),
                  className: "px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full",
                  required: ""
                }, null, 8, ["type", "modelValue"]),
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.toggleConfirmPasswordVisibility && _ctx.toggleConfirmPasswordVisibility(...args))),
                  class: "absolute right-3 top-1/2 transform -translate-y-1/2"
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass([{
                      'fas fa-eye': _ctx.showConfirmPassword,
                      'fas fa-eye-slash': !_ctx.showConfirmPassword,
                    }, "w-5 h-5 cursor-pointer"]),
                    title: 
                      _ctx.showConfirmPassword ? 'Hide password' : 'Show password'
                    
                  }, null, 10, _hoisted_19)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_CheckBox, {
                modelValue: _ctx.agreedToTerms,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.agreedToTerms) = $event)),
                id: "termsAgreement",
                "base-classes": "relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none",
                "checked-classes": "bg-violet-600 border-violet-600",
                "unchecked-classes": "bg-white border-violet-600",
                "label-classes": "text-gray-500 ml-2"
              }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode(" I've read and agree with "),
                  _createElementVNode("a", {
                    href: "https://www.slashexperts.com/terms",
                    class: "text-violet-600 ml-1"
                  }, "Terms & Conditions", -1)
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode(_component_BaseButton, {
              type: "submit",
              className: _ctx.buttonClasses,
              disabled: _ctx.disableButton
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode(" Continue ")
              ])),
              _: 1
            }, 8, ["className", "disabled"])
          ], 32),
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "flex gap-2 justify-center items-center px-px mt-6 text-sm leading-5 text-center text-gray-500" }, [
            _createElementVNode("div", { class: "flex-1 shrink-0 self-stretch my-auto h-px bg-gray-300" }),
            _createElementVNode("span", { class: "self-stretch" }, "Or"),
            _createElementVNode("div", { class: "flex-1 shrink-0 self-stretch my-auto h-px bg-gray-300" })
          ], -1)),
          _createElementVNode("p", _hoisted_21, [
            _cache[18] || (_cache[18] = _createElementVNode("span", { class: "text-gray-500" }, "Already have an account?", -1)),
            _createElementVNode("span", {
              class: "font-medium text-violet-600",
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.navigateToSignIn && _ctx.navigateToSignIn(...args)))
            }, "Sign in")
          ])
        ])
      ]),
      _cache[22] || (_cache[22] = _createStaticVNode("<aside class=\"flex flex-col ml-5 w-1/2 max-md:ml-0 max-md:w-full bg-primary-purple\"><div class=\"flex flex-col mt-14 flex-grow\"><div class=\"flex flex-col ml-10\"><div class=\"flex w-full self-start\"><img src=\"" + _imports_1 + "\" alt=\"Top Image\" class=\"w-full h-auto max-w-full object-contain\"></div><div class=\"flex w-1/2 mt-14 self-start\"><img src=\"" + _imports_2 + "\" alt=\"Middle Image\" class=\"w-full h-auto max-w-full object-contain\"></div></div><div class=\"flex justify-center mt-auto\"><img src=\"" + _imports_3 + "\" alt=\"Footer Image\" class=\"w-full h-auto max-w-full object-contain\"></div></div></aside>", 1))
    ])
  ]))
}