<template>
  <div
    class="flex flex-col justify-center mb-2 bg-white rounded-xl border border-gray-300 border-solid"
  >
    <div class="flex flex-col justify-center w-full rounded-xl">
      <div
        class="flex justify-between items-center px-6 py-5 rounded-xl w-full bg-slate-50"
      >
        <!-- Header aligned at the start -->
        <h3 class="text-sm font-semibold text-black">Top Header Bar</h3>

        <!-- Toggle button and additional button side by side at the end -->
        <div class="flex items-center gap-4 py-2 px-2">
          <!-- Toggle Button -->
          <button
            class="flex items-center w-12 h-6 rounded-xl transition-colors duration-300"
            :class="{
              'bg-violet-600': isVisible,
              'bg-gray-200': !isVisible,
            }"
            @click="toggleVisibility()"
            aria-label="Toggle button"
          >
            <span
              class="flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform"
              :class="{
                'translate-x-6': isVisible,
                'translate-x-0.5': !isVisible,
              }"
            ></span>
          </button>

          <!-- Button for toggle top header bar -->
          <button
            class="w-5"
            aria-label="Toggle top header bar"
            @click="toggleCollapse"
          >
            <div v-html="isCollapsed ? ARROW_DOWN : ARROW_UP"></div>
          </button>
        </div>
      </div>

      <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div
          class="flex flex-col w-full px-6 pb-5 rounded-xl max-md:px-5"
          v-if="!isCollapsed"
          ref="collapsible"
        >
          <div class="flex flex-col mb-4 mt-4 text-left pb-4">
            <div class="flex flex-col mb-2">
              <BaseLabel
                forAttr="headerText"
                text="Header Text"
                class="font-inter text-sm font-medium leading-normal tracking-normal"
              />
              <textarea
                v-model="topHeaderText"
                @input="handleHeaderTextChange($event)"
                placeholder="Type your text here..."
                class="w-full h-12 border rounded-md p-2 resize-none focus:outline-none"
                :class="{
                  'border-red-500': headerTextWarning,
                  'border-gray-300': !headerTextWarning,
                }"
                rows="2"
              ></textarea>
              <span
                class="text-xs mt-1 text-gray-400"
                :class="{ 'text-red-500': headerTextWarning }"
              >
                {{ topHeaderText.length }} / 80
              </span>
            </div>

            <div class="flex flex-col mb-1">
              <BaseLabel
                forAttr="buttonText"
                text="Button Text"
                class="font-inter text-sm font-medium leading-normal tracking-normal"
              />
              <BaseInput
                id="headerButtonText"
                v-model="headerButtonText"
                type="text"
                @input="handleButtonTextChange($event)"
                placeholder="Type your text here..."
                :className="{
                  'flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
                  'border-red-500': headerButtonTextWarning,
                  'border-gray-300': !headerButtonTextWarning,
                }"
              />
              <span
                class="text-xs mt-1 text-gray-400"
                :class="{ 'text-red-500': headerButtonTextWarning }"
              >
                {{ headerButtonText.length }} / 10
              </span>
            </div>

            <div class="flex flex-col">
              <BaseLabel
                forAttr="expertsDirectoryUrl"
                text="Experts Directroy Url"
                class="font-inter text-sm font-medium leading-normal tracking-normal"
              />
              <BaseInput
                id="expertsDirectoryUrl"
                v-model="expertsDirectoryUrl"
                type="text"
                @input="handleExpertsDirectoryUrlChange($event)"
                placeholder="Type your text here..."
                :className="{
                  'flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
                  'border-red-500': expertsDirectoryUrlWarning,
                  'border-gray-300': !expertsDirectoryUrlWarning,
                }"
              />
              <span
                class="text-xs mt-1 text-gray-400"
                :class="{ 'text-red-500': expertsDirectoryUrlWarning }"
              >
                {{ expertsDirectoryUrl.length }} / 60
              </span>
            </div>

            <div class="flex flex-col mt-1">
              <BaseLabel
                forAttr="selectedFontFamily"
                text="Font"
                class="font-inter text-sm font-medium leading-normal tracking-normal"
              />
              <select
                v-model="selectedFontFamily"
                @change="applyFontFamily($event)"
                class="border rounded px-2 py-1"
              >
                <option value="">Select Font</option>
                <option v-for="font in fonts" :key="font" :value="font">
                  {{ font }}
                </option>
              </select>
            </div>

            <div class="flex flex-row mt-3 gap-2">
              <select
                v-model="selectedBoldness"
                @change="applyBoldness($event)"
                class="border rounded py-1 w-1/2"
              >
                <option value="">Select Boldness</option>
                <option
                  v-for="(weight, index) in fontWeights"
                  :key="index"
                  :value="weight.value"
                >
                  {{ weight.label }}
                </option>
              </select>

              <select
                v-model="selectedFontSize"
                @change="applyFontSize($event)"
                class="border rounded py-1 w-1/2"
              >
                <option value="">Select Font Size</option>
                <option v-for="size in fontSizes" :key="size" :value="size">
                  {{ size }}
                </option>
              </select>
            </div>

            <div class="flex flex-col mb-1">
              <BaseLabel
                forAttr="textColor"
                text="Button Text Color"
                class="font-inter text-sm font-medium leading-normal tracking-normal mt-1"
              />

              <div
                class="flex w-1/2 mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
              >
                <input
                  v-model="textColor"
                  type="color"
                  @input="applyTextColor($event)"
                  class="w-7 h-7 shrink-0"
                />
                <span class="ml-2">{{ textColor }}</span>
              </div>
            </div>

            <div class="flex flex-col mb-1">
              <BaseLabel
                forAttr="buttonBackgroundColor"
                text="Button Background Color"
                class="font-inter text-sm font-medium leading-normal tracking-normal mt-1"
              />

              <div
                class="flex w-1/2 mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
              >
                <input
                  v-model="buttonBackgroundColor"
                  type="color"
                  @input="applyButtonBackgroundColor($event)"
                  class="w-7 h-7 shrink-0"
                />
                <span class="ml-2">{{ buttonBackgroundColor }}</span>
              </div>
            </div>

            <div class="flex flex-col mt-1">
              <!-- Background Color Selector -->
              <BaseLabel
                forAttr="headerBackgroundColor"
                class="font-inter text-sm font-medium leading-normal tracking-normal mt-5"
                text="Header Background Color"
              />

              <div
                class="flex w-1/2 mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
              >
                <input
                  v-model="headerBackgroundColor"
                  type="color"
                  @input="applyHeaderBackgroundColor($event)"
                  class="w-7 h-7 shrink-0"
                />
                <span class="ml-2">{{ headerBackgroundColor }}</span>
              </div>
            </div>

            <div class="flex flex-col mt-1">
              <BaseLabel
                forAttr="headerTextColor"
                class="font-inter text-sm font-medium leading-normal tracking-normal mt-5"
                text="Header Text Color"
              />

              <div
                class="flex w-1/2 mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
              >
                <input
                  v-model="headerTextColor"
                  type="color"
                  @input="applyHeaderTextColor($event)"
                  class="w-7 h-7 shrink-0"
                />
                <span class="ml-2">{{ headerTextColor }}</span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, Ref, ref } from "vue";
import { useEditor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import { Mark } from "@tiptap/core";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import { ARROW_DOWN, ARROW_UP } from "@/assets/svg/shared/svg-constants";
import { fontFamilies } from "@/data/expert-program/font-families";

const Boldness = Mark.create({
  name: "boldness",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          boldness: {
            default: null,
            parseHTML: (element) => element.style.fontWeight || null,
            renderHTML: (attributes) => {
              if (!attributes.boldness) {
                return {};
              }
              return { style: `font-weight: ${attributes.boldness};` };
            },
          },
        },
      },
    ];
  },
});

const FontFamily = Mark.create({
  name: "fontFamily",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: (element) =>
              element.style.fontFamily.replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (!attributes.fontFamily) {
                return {};
              }
              return { style: `font-family: ${attributes.fontFamily}` };
            },
          },
        },
      },
    ];
  },
});

const FontSize = Mark.create({
  name: "fontSize",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) => element.style.fontSize.replace(/px/, ""),
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }
              return { style: `font-size: ${attributes.fontSize}px` };
            },
          },
        },
      },
    ];
  },
});

const TextColor = Mark.create({
  name: "textColor",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: (element) => element.style.color,
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }
              return { style: `color: ${attributes.color}` };
            },
          },
        },
      },
    ];
  },
});

const ButtonBackgroundColor = Mark.create({
  name: "buttonBackgroundColor",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          buttonBackgroundColor: {
            default: null,
            parseHTML: (element) => element.style.backgroundColor,
            renderHTML: (attributes) => {
              if (!attributes.buttonBackgroundColor) {
                return {};
              }
              return {
                style: `background-color: ${attributes.buttonBackgroundColor}`,
              };
            },
          },
        },
      },
    ];
  },
});

const HeaderBackgroundColor = Mark.create({
  name: "headerBackgroundColor",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          headerBackgroundColor: {
            default: null,
            parseHTML: (element) => element.style.backgroundColor,
            renderHTML: (attributes) => {
              if (!attributes.headerBackgroundColor) {
                return {};
              }
              return {
                style: `background-color: ${attributes.headerBackgroundColor}`,
              };
            },
          },
        },
      },
    ];
  },
});

const HeaderTextColor = Mark.create({
  name: "headerTextColor",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          headerTextColor: {
            default: null,
            parseHTML: (element) => element.style.color,
            renderHTML: (attributes) => {
              if (!attributes.headerTextColor) {
                return {};
              }
              return {
                style: `color: ${attributes.headerTextColor}`,
              };
            },
          },
        },
      },
    ];
  },
});

export default defineComponent({
  name: "TopHeaderBarDesign",
  components: {
    BaseLabel,
    BaseInput,
  },
  props: {
    fontFamily: {
      type: String,
      default: "",
    },
    boldness: {
      type: String,
      default: "",
    },
    buttonTextColor: {
      type: String,
      default: "",
    },
    topHeaderbuttonBackgroundColor: {
      type: String,
      default: "",
    },
    topHeaderBackgroundColor: {
      type: String,
      default: "",
    },
    topHeaderTextColor: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "",
    },
    headerText: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    topHeaderExpertsDirectoryUrl: {
      type: String,
      default: "",
    },
  },
  emits: [
    "fontFamilyChanged",
    "boldnessChanged",
    "fontSizeChanged",
    "buttonTextColorChanged",
    "buttonBackgroundColorChanged",
    "headerBackgroundColorChanged",
    "headerTextColorChanged",
    "toggleVisibility",
    "headerTextChanged",
    "buttonTextChanged",
    "expertsDirectoryUrlChanged",
  ],
  setup(props, { emit }) {
    const editor = useEditor({
      extensions: [
        StarterKit,
        TextStyle,
        FontFamily,
        FontSize,
        TextColor,
        HeaderBackgroundColor,
        ButtonBackgroundColor,
        HeaderTextColor,
        Boldness,
      ],
    });

    const fonts = fontFamilies;

    const fontSizes = [
      "12",
      "14",
      "16",
      "18",
      "20",
      // "24",
      // "28",
      // "32",
      // "36",
      // "40",
    ];

    const fontWeights = [
      // { value: "100", label: "Thin" },
      // { value: "200", label: "Extra Light" },
      // { value: "300", label: "Light" },
      { value: "400", label: "Regular" },
      // { value: "500", label: "Medium" },
      // { value: "600", label: "Semi-Bold" },
      { value: "700", label: "Bold" },
      // { value: "800", label: "Extra Bold" },
    ];

    const selectedFontFamily = ref(props.fontFamily);
    const selectedFontSize = ref(props.fontSize);
    const textColor = ref(props.buttonTextColor);
    const buttonBackgroundColor = ref(props.topHeaderbuttonBackgroundColor);
    const headerBackgroundColor = ref(props.topHeaderBackgroundColor);
    const headerTextColor = ref(props.topHeaderTextColor);
    const selectedBoldness = ref(props.boldness);
    const topHeaderText = ref(props.headerText);
    const headerButtonText = ref(props.buttonText);
    const headerTextWarning = ref(false);
    const headerButtonTextWarning = ref(false);
    const expertsDirectoryUrl = ref(props.topHeaderExpertsDirectoryUrl);
    const expertsDirectoryUrlWarning = ref(false);
    const isCollapsed = ref(false);

    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const applyFontFamily = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const font = target.value;
      selectedFontFamily.value = font;
      emit("fontFamilyChanged", font);
      if (font) {
        editor.value
          ?.chain()
          .focus()
          .setMark("fontFamily", { fontFamily: font })
          .run();
      }
    };

    const applyFontSize = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const size = target.value;
      selectedFontSize.value = size;
      emit("fontSizeChanged", size);
      if (size) {
        editor.value
          ?.chain()
          .focus()
          .setMark("fontSize", { fontSize: size })
          .run();
      }
    };

    const applyBoldness = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const boldness = target.value;
      selectedBoldness.value = boldness;
      emit("boldnessChanged", boldness);
      if (boldness) {
        editor.value?.chain().focus().setMark("boldness", { boldness }).run();
      }
    };

    const applyTextColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      textColor.value = target.value;
      emit("buttonTextColorChanged", textColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("textColor", { color: textColor.value })
        .run();
    };

    const applyHeaderBackgroundColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      headerBackgroundColor.value = target.value;
      emit("headerBackgroundColorChanged", headerBackgroundColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("headerBackgroundColor", {
          backgroundColor: headerBackgroundColor.value,
        })
        .run();
    };

    const applyButtonBackgroundColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      buttonBackgroundColor.value = target.value;
      emit("buttonBackgroundColorChanged", buttonBackgroundColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("buttonBackgroundColor", {
          backgroundColor: buttonBackgroundColor.value,
        })
        .run();
    };

    const applyHeaderTextColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      headerTextColor.value = target.value;
      emit("headerTextColorChanged", headerTextColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("headerTextColor", { color: headerTextColor.value })
        .run();
    };

    const handleHeaderTextChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.value.length > 80) {
        alertUser(headerTextWarning);
      }
      topHeaderText.value = target.value.slice(0, 80);
      emit("headerTextChanged", topHeaderText.value);
    };

    const handleButtonTextChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.value.length > 10) {
        alertUser(headerButtonTextWarning);
      }
      headerButtonText.value = target.value.slice(0, 10);
      emit("buttonTextChanged", headerButtonText.value);
    };

    const handleExpertsDirectoryUrlChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.value.length > 60) {
        alertUser(expertsDirectoryUrlWarning);
      }
      expertsDirectoryUrl.value = target.value.slice(0, 60);
      emit("expertsDirectoryUrlChanged", expertsDirectoryUrl.value);
    };

    const alertUser = (warningState: Ref<boolean>) => {
      warningState.value = true;

      setTimeout(() => {
        warningState.value = false;
      }, 1000);
    };

    const beforeEnter = (el: Element) => {
      const target = el as HTMLElement;
      target.style.height = "0";
      target.style.opacity = "0";
    };

    const enter = (el: Element) => {
      const target = el as HTMLElement;
      const height = target.scrollHeight;
      target.style.transition = "height 0.4s ease, opacity 0.4s ease";
      target.style.height = height + "px";
      target.style.opacity = "1";
    };

    const leave = (el: Element) => {
      const target = el as HTMLElement;
      target.style.transition = "height 0.4s ease, opacity 0.4s ease";
      target.style.height = "0";
      target.style.opacity = "0";
    };

    const toggleVisibility = () => {
      // isVisible.value = !isVisible.value;
      emit("toggleVisibility", !props.isVisible);
      if (!props.isVisible === false && isCollapsed.value === false) {
        toggleCollapse();
      } else if (props.isVisible === false && isCollapsed.value === true) {
        toggleCollapse();
      }
    };

    onBeforeUnmount(() => {
      editor.value?.destroy();
    });

    return {
      editor,
      applyFontFamily,
      applyFontSize,
      applyTextColor,
      applyHeaderBackgroundColor,
      applyHeaderTextColor,
      applyBoldness,
      fonts,
      fontSizes,
      fontWeights,
      textColor,
      headerBackgroundColor,
      headerTextColor,
      selectedFontSize,
      selectedFontFamily,
      selectedBoldness,
      ARROW_DOWN,
      ARROW_UP,
      isCollapsed,
      toggleCollapse,
      beforeEnter,
      enter,
      leave,
      toggleVisibility,
      handleHeaderTextChange,
      topHeaderText,
      headerTextWarning,
      headerButtonTextWarning,
      headerButtonText,
      handleButtonTextChange,
      buttonBackgroundColor,
      applyButtonBackgroundColor,
      expertsDirectoryUrl,
      expertsDirectoryUrlWarning,
      handleExpertsDirectoryUrlChange,
      // isVisible,
    };
  },
});
</script>
