<template>
  <main class="bg-white min-h-screen flex flex-col">
    <div class="flex flex-grow max-md:flex-col">
      <aside
        class="flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div class="flex flex-col mt-14 flex-grow">
          <div class="flex flex-col ml-10 text-left">
            <span class="text-white text-3xl font-semibold leading-9">
              Create An <br />
              Expert Program
            </span>
            <span
              class="text-white text-base font-normal leading-6 font-sans mt-3"
            >
              Start setting up an expert program</span
            >
          </div>

          <!-- Third Image, or Footer Image -->
          <StepsList :currentStep="2" />
        </div>
      </aside>
      <section
        class="flex flex-col justify-start w-4/5 max-md:ml-0 max-md:w-full"
      >
        <div class="flex flex-col mt-20 w-full max-md:mt-10 max-md:max-w-full">
          <!-- directroy + code step list -->
          <div class="flex flex-col ml-16 max-w-full">
            <h2
              class="self-start text-3xl font-semibold leading-tight text-zinc-800"
            >
              Set-up Directory
            </h2>
            <CurrentStep
              :currentStep="currentStep"
              firstStepText="Setup Directory"
            />
          </div>

          <!-- Error div -->
          <div class="flex justify-center mb-4">
            <div
              v-if="errorMessage"
              role="alert"
              class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
              style="max-height: 80px; overflow-y: auto"
            >
              <div
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <img
                  src="../../assets/shared/failure.svg"
                  alt="Error Icon"
                  class="w-4 h-4"
                />
              </div>
              <p>{{ errorMessage }}</p>
            </div>
          </div>

          <!-- set up directory view -->
          <div
            v-if="!userStore.createExpertProgram?.directoryCreated"
            class="self-center mt-8 w-full px-10"
          >
            <div class="flex gap-5 max-md:flex-col">
              <!-- directory preview in browser -->
              <section class="flex flex-col w-[63%] max-md:ml-0 max-md:w-full">
                <div
                  id="unevenGridContainer"
                  class="flex flex-col w-full mx-auto w-full bg-white max-md:mt-8 max-md:max-w-full"
                >
                  <div
                    class="flex flex-wrap items-center pt-2 pr-2 pl-3.5 text-xs whitespace-nowrap text-zinc-700 max-md:pr-5"
                  >
                    <!-- browser first row -->
                    <div
                      class="flex flex-col bg-gray-200 w-full rounded-t-lg px-2"
                    >
                      <div
                        class="flex flex-row justify-start gap-2 ml-2 pt-2 pt-3"
                      >
                        <img
                          loading="lazy"
                          src="../../assets/expert-program/directory/traffic-lights.svg"
                          class="object-fit shrink-0 self-stretch my-auto"
                          alt=""
                        />
                        <!-- tab -->
                        <div
                          class="flex flex-row bg-white rounded-t-2xl w-full justify-between max-w-xs p-1"
                        >
                          <div class="flex flex-row justify-start items-center">
                            <div class="ml-2">
                              <img
                                loading="lazy"
                                :src="
                                  logoFromWebsite(
                                    userStore.companyWebsite || ''
                                  )
                                "
                                class="w-8 h-8 mr-2 rounded-full"
                              />
                            </div>
                            <div>
                              <span>{{ userStore.companyName }}</span>
                            </div>
                          </div>
                          <div
                            class="flex flex-row justify-end items-center mr-3"
                          >
                            <div v-html="CLOSE_ICON_SM"></div>
                          </div>
                        </div>

                        <div class="flex flex-row items-center ml-2">
                          <div v-html="PLUS_ICON" class="text-gray-600"></div>
                        </div>
                      </div>
                    </div>

                    <!-- browser second row -->
                    <div class="flex flex-col w-full px-2">
                      <div
                        class="flex flex-row justify-start items-center gap-2 ml-2 py-2"
                      >
                        <img
                          loading="lazy"
                          src="../../assets/expert-program/directory/browser-actions.svg"
                          class="object-fit shrink-0 self-stretch my-auto"
                          alt=""
                        />

                        <div
                          class="flex flex-row bg-gray-100 w-full p-1 rounded-full items-center whitespace-nowrap overflow-ellipsis overflow-hidden"
                        >
                          <div
                            v-html="LOCK_ICON"
                            class="text-gray-600 ml-2"
                          ></div>
                          <div class="ml-2 overflow-hidden">
                            <span>{{ userStore.companyWebsite }}/experts</span>
                          </div>
                        </div>

                        <div>
                          <div
                            v-html="BROWSER_MENU_ICON"
                            class="text-gray-600 ml-2"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="isGridUneven"
                      class="flex gap-2 bg-gray-100 p-6 justify-center w-full max-md:max-w-full"
                    >
                      <div v-for="n in columns" :key="n" class="space-y-3">
                        <!-- Loop over the profiles array and place them in the respective column div based on index -->
                        <ProfileCard
                          v-for="profile in profiles.filter(
                            (_, index) => index % columns === n - 1
                          )"
                          :key="profile.id"
                          :profile="profile"
                          :isCentered="profileCenterAligned"
                          :isGridUneven="isGridUneven"
                          :profileImageClass="profileImageClass"
                          :profileImageAndNameLayoutClass="
                            profileImageAndNameLayoutClass
                          "
                          :fontFamily="fontFamily"
                          :boldness="boldness"
                          :textColor="textColor"
                          :backgroundColor="backgroundColor"
                          :fontSize="fontSize"
                          class="flex-shrink-0"
                        />
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="flex flex-wrap bg-gray-100 p-6 items-start w-full max-md:max-w-full"
                        :class="profileCardsGap"
                      >
                        <ProfileCard
                          v-for="profile in profiles"
                          :key="profile.id"
                          :profile="profile"
                          :isCentered="profileCenterAligned"
                          :profileImageClass="profileImageClass"
                          :isGridUneven="isGridUneven"
                          :profileImageAndNameLayoutClass="
                            profileImageAndNameLayoutClass
                          "
                          :fontFamily="fontFamily"
                          :boldness="boldness"
                          :textColor="textColor"
                          :backgroundColor="backgroundColor"
                          :fontSize="fontSize"
                          class="flex-shrink-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <!-- directory style + layout controls -->
              <section
                class="flex flex-col ml-5 w-[37%] max-md:ml-0 max-md:w-full"
              >
                <div class="flex flex-col max-md:mt-8">
                  <div class="flex-flex-col">
                    <TemplateSelector
                      :initialSelected="selectedTemplate"
                      @templateSelected="selectTemplate"
                    />
                  </div>
                  <div class="flex flex-col">
                    <CardStyling
                      :fontFamily="fontFamily"
                      :fontSize="fontSize"
                      :buttonTextColor="textColor"
                      :boldness="boldness"
                      :buttonBackgroundColor="backgroundColor"
                      @fontFamilyChanged="handleFontChange"
                      @boldnessChanged="handleBoldnessChange"
                      @buttonTextColorChanged="handleTextColorChange"
                      @buttonBackgroundColorChanged="
                        handleBackgroundColorChange
                      "
                      @fontSizeChanged="handleFontSizeChange"
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>

          <!-- get code view -->
          <div v-else><GetCode /></div>
        </div>

        <!-- footer section -->
        <footer class="flex flex-wrap justify-center mt-auto items-center">
          <FooterSection :isDisabled="isDisabled" @next="goNext" />
        </footer>
      </section>
    </div>
  </main>
</template>

<script lang="ts">
import StepsList from "@/components/expert-program/StepsList.vue";
import FooterSection from "@/components/expert-program/FooterSection.vue";
import ProfileCard from "@/components/expert-program/directory/ProfileCard.vue";
import TemplateSelector from "@/components/expert-program/directory/TemplateSelector.vue";
import router from "@/router";
import { directoryProfiles } from "@/data/expert-program/directory-preview-profiles";
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import GetCode from "@/components/expert-program/directory/GetCode.vue";
import CurrentStep from "@/components/expert-program/CurrentStep.vue";
import CardStyling from "@/components/expert-program/directory/CardStyling.vue";
import {
  CLOSE_ICON_SM,
  PLUS_ICON,
  LOCK_ICON,
  BROWSER_MENU_ICON,
  ARROW_UP,
} from "@/assets/svg/shared/svg-constants";
import { googleFontFamiliesLink } from "@/data/expert-program/font-families";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";

const UPDATE_DIRECTORY = gql`
  mutation updateDirectory($input: UpdateDirectoryInput!) {
    updateDirectory(input: $input) {
      directory {
        id
        companyId
      }
      errors
    }
  }
`;

interface UserProgramProfile {
  summary: string;
}

interface Profile {
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  country: string;
  userProgramProfile: UserProgramProfile[];
}

export default defineComponent({
  name: "AddDirectory",
  components: {
    StepsList,
    FooterSection,
    ProfileCard,
    TemplateSelector,
    CardStyling,
    GetCode,
    CurrentStep,
  },
  mounted() {
    const link = document.createElement("link");
    link.href = googleFontFamiliesLink;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  },
  setup() {
    const isDisabled = ref(false);
    const errorMessage = ref("");
    const directoryCreated = ref(false);
    const createdDirectoryCompanyId = ref("");
    const selectedTemplate = ref("template-1");
    const isTemplateSelectorVisible = ref(true);
    const profileImageClass = ref("rounded-full");
    const profileCardsGap = ref("gap-3");
    const fontFamily = ref("Lato");
    const boldness = ref("400");
    const textColor = ref("#FFFFFF");
    const backgroundColor = ref("#121111");
    const fontSize = ref("14");
    const profileCenterAligned = ref(false);
    const profileImageAndNameLayoutClass = ref("flex-row");
    const isGridUneven = ref(false);
    const columns = ref(5);
    const userStore = useUserStore();

    const {
      mutate: updateDirectory,
      onDone: updateDirectoryDone,
      onError: updateDirectoryError,
    } = useMutation(UPDATE_DIRECTORY);

    const profiles: Profile[] = directoryProfiles;

    const goNext = () => {
      errorMessage.value = "";
      if (!userStore.createExpertProgram?.directoryCreated) {
        isDisabled.value = true;
        callUpdateDirectoryMutation();
      } else {
        router.push({ name: "AddWidget" });
      }
    };

    const logoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";

      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");

        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    const selectTemplate = (template: string) => {
      selectedTemplate.value = template;
      console.log("Select template", selectedTemplate.value);
      updateProfileImageClass();
      updateProfileCardsGap();
      updateProfileImageAndNameLayoutClass();
      updateProfileCenterAlignment();
      updateGridLayout();
    };

    const updateProfileImageClass = () => {
      if (
        selectedTemplate.value === "template-1" ||
        selectedTemplate.value === "template-4"
      ) {
        profileImageClass.value = "rounded-full";
      } else {
        profileImageClass.value = "";
      }
    };

    const updateProfileCardsGap = () => {
      if (selectedTemplate.value === "template-3") {
        profileCardsGap.value = "gap-0.5";
      } else {
        profileCardsGap.value = "gap-3";
      }
    };

    const updateProfileImageAndNameLayoutClass = () => {
      if (
        selectedTemplate.value === "template-5" ||
        selectedTemplate.value === "template-3"
      ) {
        profileImageAndNameLayoutClass.value = "flex-col";
      } else {
        profileImageAndNameLayoutClass.value = "flex-row";
      }
    };

    const updateProfileCenterAlignment = () => {
      if (selectedTemplate.value === "template-5") {
        profileCenterAligned.value = true;
      } else {
        profileCenterAligned.value = false;
      }
    };

    const updateGridLayout = () => {
      if (selectedTemplate.value === "template-4") {
        isGridUneven.value = true;
      } else {
        isGridUneven.value = false;
      }
    };

    const breakpoints = {
      sm: 640, // Small screens
      md: 768, // Medium screens
      lg: 1024, // Large screens
      xl: 1280, // Extra-large screens
      // "2xl": 1536, // 2X Large screens
    };

    const getColumnCount = () => {
      const gridContainer = document.querySelector(
        "#unevenGridContainer"
      ) as HTMLElement;

      const width = gridContainer?.clientWidth;
      if (width < breakpoints.sm) {
        return 1;
      } else if (width < breakpoints.md) {
        return 2;
      } else if (width < breakpoints.lg) {
        return 3;
      } else if (width < breakpoints.xl) {
        return 4;
      } else {
        return 5;
      }
    };

    const handleResize = () => {
      columns.value = getColumnCount();
    };

    handleResize();

    onMounted(async () => {
      await nextTick();
      columns.value = getColumnCount();
      window.addEventListener("resize", handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    const handleBackgroundColorChange = (color: string) => {
      backgroundColor.value = color;
      console.log("Background color changed", backgroundColor.value);
    };

    const handleFontChange = (font: string) => {
      fontFamily.value = font;
    };

    const handleBoldnessChange = (weight: string) => {
      boldness.value = weight;
    };

    const handleTextColorChange = (color: string) => {
      textColor.value = color;
    };

    const handleFontSizeChange = (size: string) => {
      fontSize.value = size;
    };

    const callUpdateDirectoryMutation = async () => {
      updateDirectory({
        input: {
          directoryParams: {
            programId: userStore.createExpertProgram?.id,
            style: selectedTemplate.value,
            fontFamily: fontFamily.value,
            bold: boldness.value,
            textColor: textColor.value,
            backgroundColor: backgroundColor.value,
            fontSize: fontSize.value,
            textAlign: "left",
          },
        },
      });

      updateDirectoryDone((response) => {
        if (response) {
          isDisabled.value = false;
          const data = response.data.updateDirectory;
          if (data.errors.length > 0) {
            errorMessage.value = data.errors.join(".");
          } else {
            console.log("Directory created ", data);
            createdDirectoryCompanyId.value = data.directory.companyId;
            userStore.updateDirectoryCreated(true);
            userStore.updateCompanyId(createdDirectoryCompanyId.value);
            directoryCreated.value = true;
          }
        }
      });

      updateDirectoryError((error: ApolloError) => {
        isDisabled.value = false;
        console.log("Error creating directory ", error.message);
        errorMessage.value = error.message;
      });
    };

    const currentStep = computed(() =>
      userStore.createExpertProgram?.directoryCreated ? 2 : 1
    );

    return {
      isDisabled,
      goNext,
      CLOSE_ICON_SM,
      PLUS_ICON,
      LOCK_ICON,
      BROWSER_MENU_ICON,
      ARROW_UP,
      logoFromWebsite,
      selectTemplate,
      selectedTemplate,
      profiles,
      isTemplateSelectorVisible,
      profileImageClass,
      profileCardsGap,
      handleFontChange,
      handleBoldnessChange,
      handleTextColorChange,
      handleBackgroundColorChange,
      handleFontSizeChange,
      fontFamily,
      boldness,
      textColor,
      backgroundColor,
      fontSize,
      profileImageAndNameLayoutClass,
      profileCenterAligned,
      errorMessage,
      directoryCreated,
      currentStep,
      isGridUneven,
      columns,
      userStore,
    };
  },
});
</script>
