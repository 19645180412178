<template>
  <div class="flex flex-col w-full max:md-w-1/2 space-y-4">
    <div
      class="flex flex-wrap justify-between gap-6 py-6 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5">Name</span>
      </div>
      <div class="flex flex-col flex-1 gap-2 text-left">
        <div class="flex gap-2 w-full">
          <div class="flex-1">
            <span class="text-gray-900 text-sm font-normal leading-5"
              >{{ firstName }} {{ lastName }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Email Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Email Address</span
        >
      </div>
      <div class="flex flex-col flex-1 text-left">
        <span class="text-gray-900 text-sm font-normal leading-5">{{
          email
        }}</span>
      </div>
    </div>

    <!-- Profile Photo Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Your photo</span
        >
        <span class="text-gray-500 text-sm font-normal leading-5"
          >Your photo will be displayed on your profile.</span
        >
      </div>
      <div class="flex flex-wrap flex-1 text-left gap-4 items-center">
        <div
          class="relative w-12 h-12 overflow-hidden rounded-full flex items-center justify-center"
        >
          <img
            v-if="profilePictureUrl"
            :src="profilePictureUrl"
            alt="User profile picture"
            class="w-full h-full object-cover"
            loading="lazy"
          />
          <img
            v-else
            src="@/assets/shared/avatar.svg"
            alt="Default profile picture"
            class="w-full h-full object-cover"
            loading="lazy"
          />
        </div>
      </div>
    </div>

    <!-- Title Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5">Title</span>
      </div>
      <div class="flex flex-col flex-1 text-left">
        <span class="text-gray-900 text-sm font-normal leading-5">{{
          title
        }}</span>
      </div>
    </div>

    <!-- Company Name Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Company Name</span
        >
      </div>
      <div class="flex flex-col flex-1 text-left">
        <span class="text-gray-900 text-sm font-normal leading-5">{{
          companyName
        }}</span>
      </div>
    </div>

    <!-- Company Website Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Company Website</span
        >
      </div>
      <div class="flex flex-col flex-1 text-left">
        <span class="text-gray-900 text-sm font-normal leading-5">{{
          companyWebsite
        }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "CurrentDetails",
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    profilePicture: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
    companyWebsite: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const profilePictureUrl = computed(() => {
      if (props.profilePicture.startsWith("https://")) {
        return `${props.profilePicture}?t=${new Date().getTime()}`;
      } else {
        return props.profilePicture;
      }
    });

    return {
      profilePictureUrl,
      // name,
      // emailAddress,
      // title,
      // companyName,
      // companyWebsite,
    };
  },
});
</script>
