import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../../../assets/shared/failure.svg'


const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
}
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = {
  id: "sync-salesforce-modal",
  class: "bg-white p-6 rounded-lg shadow-lg w-full sm:w-full md:w-4/5 lg:w-1/2 overflow-x-auto max-h-[80vh] overflow-y-auto"
}
const _hoisted_4 = { class: "flex flex-wrap justify-between text-left py-3" }
const _hoisted_5 = { class: "flex flex-col border border-solid max-w-max border-primary-purple bg-white rounded-lg p-0.5" }
const _hoisted_6 = { class: "flex flex-col border border-solid border-gray-300 rounded-lg p-4" }
const _hoisted_7 = { class: "flex flex-wrap gap-3" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "flex items-center justify-center w-8 h-8 bg-blue-500 text-white rounded-full" }
const _hoisted_10 = { class: "flex flex-col text-left" }
const _hoisted_11 = { class: "text-sm leading-5 font-medium text-gray-900" }
const _hoisted_12 = { class: "text-sm leading-5 font-normal text-gray-500" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "flex justify-center mb-4" }
const _hoisted_15 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_16 = { class: "flex flex-col text-left gap-4 py-4 max-w-[60%] md:max-w-[70%] lg:max-w-[80%]" }
const _hoisted_17 = { class: "flex justify-end gap-3" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_LeadFieldMapping = _resolveComponent("LeadFieldMapping")!
  const _component_ContactFieldMapping = _resolveComponent("ContactFieldMapping")!
  const _component_CreateNewActivityToggle = _resolveComponent("CreateNewActivityToggle")!
  const _component_CreateNewLeadOrContactToggle = _resolveComponent("CreateNewLeadOrContactToggle")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoadingData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[10] || (_cache[10] = [
              _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-sm leading-5 font-medium text-neutral-800" }, "Sync'd Salesforce Account", -1)),
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "text-lg leading-5 font-medium text-neutral-800",
              disabled: _ctx.createSalesforceMappingAndSettingLoading,
              onClick: _ctx.closeModal
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("X")
              ])),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.initials), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.providerAccountName), 1),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.providerAccountEmail), 1)
                ]),
                _createElementVNode("div", {
                  innerHTML: _ctx.HOLLOW_CHECK_CIRCLE,
                  class: "text-primary-purple"
                }, null, 8, _hoisted_13)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "Error Icon",
                      class: "w-4 h-4"
                    })
                  ], -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", null, [
              _createVNode(_component_LeadFieldMapping, {
                leadMappingFields: _ctx.leadMappingFields,
                leadFirstName: _ctx.salesforceData.leadFirstName.value.value,
                leadLastName: _ctx.salesforceData.leadLastName.value.value,
                leadCompany: _ctx.salesforceData.leadCompany.value.value,
                leadTitle: _ctx.salesforceData.leadTitle.value.value,
                "onUpdate:leadFirstName": _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.salesforceData.leadFirstName.value.value = $event
            )),
                "onUpdate:leadLastName": _cache[1] || (_cache[1] = ($event: any) => (
              _ctx.salesforceData.leadLastName.value.value = $event
            )),
                "onUpdate:leadCompany": _cache[2] || (_cache[2] = ($event: any) => (
              _ctx.salesforceData.leadCompany.value.value = $event
            )),
                "onUpdate:leadTitle": _cache[3] || (_cache[3] = ($event: any) => (_ctx.salesforceData.leadTitle.value.value = $event))
              }, null, 8, ["leadMappingFields", "leadFirstName", "leadLastName", "leadCompany", "leadTitle"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_ContactFieldMapping, {
                contactMappingFields: _ctx.contactMappingFields,
                contactFirstName: _ctx.salesforceData.contactFirstName.value.value,
                contactLastName: _ctx.salesforceData.contactLastName.value.value,
                contactCompany: _ctx.salesforceData.contactCompany.value.value,
                contactTitle: _ctx.salesforceData.contactTitle.value.value,
                "onUpdate:contactFirstName": _cache[4] || (_cache[4] = ($event: any) => (
              _ctx.salesforceData.contactFirstName.value.value = $event
            )),
                "onUpdate:contactLastName": _cache[5] || (_cache[5] = ($event: any) => (
              _ctx.salesforceData.contactLastName.value.value = $event
            )),
                "onUpdate:contactCompany": _cache[6] || (_cache[6] = ($event: any) => (
              _ctx.salesforceData.contactCompany.value.value = $event
            )),
                "onUpdate:contactTitle": _cache[7] || (_cache[7] = ($event: any) => (
              _ctx.salesforceData.contactTitle.value.value = $event
            ))
              }, null, 8, ["contactMappingFields", "contactFirstName", "contactLastName", "contactCompany", "contactTitle"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_CreateNewActivityToggle, {
                createNewActivity: _ctx.createActivity,
                "onUpdate:createNewActivity": _cache[8] || (_cache[8] = ($event: any) => (_ctx.createActivity = $event))
              }, null, 8, ["createNewActivity"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_CreateNewLeadOrContactToggle, {
                createNewLeadOrContact: _ctx.createLeadOrContact,
                "onUpdate:createNewLeadOrContact": _cache[9] || (_cache[9] = ($event: any) => (_ctx.createLeadOrContact = $event))
              }, null, 8, ["createNewLeadOrContact"])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_BaseButton, {
              type: "button",
              onClick: _ctx.closeModal,
              disabled: _ctx.createSalesforceMappingAndSettingLoading,
              class: "text-black border border-gray-300 px-4 py-2 rounded"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" Close ")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"]),
            _createVNode(_component_BaseButton, {
              type: "button",
              onClick: _ctx.handleUpdate,
              disabled: _ctx.createSalesforceMappingAndSettingLoading,
              class: "bg-primary-purple text-white px-4 py-2 rounded"
            }, {
              default: _withCtx(() => [
                (!_ctx.createSalesforceMappingAndSettingLoading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Update"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_19, "Updating..."))
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}