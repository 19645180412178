import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 border border-gray-200 rounded-lg p-4" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex flex-row justify-between" }
const _hoisted_4 = { class: "flex flex-col items-start gap-2" }
const _hoisted_5 = { class: "text-black text-base font-medium leading-6" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "flex flex-col gap-1" }
const _hoisted_8 = { class: "flex flex-row justify-between" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "text-gray-600 text-sm font-normal leading-5" }
const _hoisted_11 = { class: "flex flex-col" }
const _hoisted_12 = { class: "text-gray-600 text-sm font-normal leading-5" }
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = { class: "flex flex-wrap justify-between gap-2" }
const _hoisted_15 = { class: "flex flex-col text-left gap-2 bg-gray-150 p-4 rounded-lg flex-1" }
const _hoisted_16 = {
  key: 0,
  class: "text-gray-600 text-sm font-normal leading-5"
}
const _hoisted_17 = {
  key: 1,
  class: "text-gray-600 text-sm font-normal leading-5"
}
const _hoisted_18 = { class: "flex flex-col border border-gray-200 rounded-lg p-4 flex-1" }
const _hoisted_19 = { class: "text-gray-800 text-base text-left font-semibold leading-6" }
const _hoisted_20 = { class: "flex flex-row justify-between" }
const _hoisted_21 = { class: "flex flex-col text-left rounded-lg flex-1" }
const _hoisted_22 = { class: "flex flex-wrap bg-gray-150 p-4 rounded-lg justify-between" }
const _hoisted_23 = { class: "flex flex-col" }
const _hoisted_24 = {
  key: 0,
  class: "text-gray-800 text-base font-semibold leading-6"
}
const _hoisted_25 = {
  key: 1,
  class: "text-gray-800 text-base font-semibold leading-6"
}
const _hoisted_26 = { class: "text-gray-600 text-sm font-normal leading-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_StatusBar = _resolveComponent("StatusBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-gray-800 text-base font-semibold leading-6" }, "Available balance", -1)),
          _createElementVNode("span", _hoisted_5, "$" + _toDisplayString(_ctx.availableBalance), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_BaseButton, {
            type: "button",
            onClick: _ctx.updateAccountBalance,
            class: "flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Update ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_StatusBar, {
          current: _ctx.availableBalance,
          total: _ctx.totalBalance
        }, null, 8, ["current", "total"]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, "$" + _toDisplayString(_ctx.usedBalance) + " used", 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, "$" + _toDisplayString(_ctx.totalBalance) + " total", 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-gray-800 text-base font-semibold leading-6" }, "Auto Top-up", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-gray-800 text-base font-semibold leading-6" }, "The amount you want to auto top-up", -1)),
            (_ctx.autoTopup)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, " If amount goes below $ " + _toDisplayString(_ctx.lowBalanceAmount) + " auto top-up for $ " + _toDisplayString(_ctx.autoRechargeAmount), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_17, "Auto top-up is disabled"))
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.paymentMethodText()), 1),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    (_ctx.paymentMethodSource === 'credit_card')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_24, " Card ending with *** **** " + _toDisplayString(_ctx.paymentMethodCardNumber.slice(-4)), 1))
                      : (_ctx.paymentMethodSource === 'invoice')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_25, " Invoice email: " + _toDisplayString(_ctx.paymentMethodInvoiceRecipientEmail), 1))
                        : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_26, "Date added: " + _toDisplayString(_ctx.paymentMethodCreatedAt), 1)
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex flex-col" }, [
                    _createElementVNode("span", { class: "px-2 rounded-lg bg-primary-purple text-white font-sans text-xs font-normal leading-normal" }, "DEFAULT")
                  ], -1))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}