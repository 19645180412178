<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex justify-end bg-black bg-opacity-50 z-50"
  >
    <div
      class="bg-white w-1/3 h-full p-6 shadow-lg transform transition-transform ease-in-out duration-300"
    >
      <div class="flex flex-col justify-between h-full">
        <div class="flex flex-col">
          <div class="flex justify-end">
            <BaseButton
              type="button"
              @click="closeModal"
              class="mb-4 text-gray-500 justify-end hover:text-gray-700"
            >
              X
            </BaseButton>
          </div>

          <div class="flex justify-center mb-4">
            <div
              v-if="errorMessage"
              role="alert"
              class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
              style="max-height: 80px; overflow-y: auto"
            >
              <!-- Icon Container -->
              <div
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <!-- Custom Image Icon -->
                <img
                  src="../../../../assets/shared/failure.svg"
                  alt="Error Icon"
                  class="w-4 h-4"
                />
              </div>
              <p class="text-sm text-left">{{ errorMessage }}</p>
            </div>
          </div>

          <div class="flex flex-col gap-2 text-left font-inter">
            <div class="flex flex-col">
              <div class="text-2xl font-bold">
                {{ expert.firstName }} {{ expert.lastName }}
              </div>
              <div class="text-sm text-gray-500">{{ expert.email }}</div>
            </div>
            <div class="flex flex-col border-b border-gray-200 pb-4">
              <BaseLabel
                forAttr="summary"
                text="Summary"
                class="text-black text-sm font-medium leading-5 text-left"
              />
              <div class="text-sm">{{ summary }}</div>
            </div>
            <div class="text-black text-sm font-medium leading-5 text-left">
              Uploaded Video
            </div>
            <div class="flex flex-col items-start w-full p-4">
              <div class="relative">
                <video
                  ref="video"
                  class="w-full h-auto border border-gray-300"
                  controls
                  type="video/mp4"
                  @canplay="handleCanPlay"
                  @loadstart="handleLoadStart"
                  :key="introductionVideo"
                  :src="introductionVideo || ''"
                ></video>
                <!-- Loading text, displayed when `isLoading` is true -->
                <div
                  v-if="isVideoLoading"
                  class="absolute inset-0 flex items-center justify-center bg-gray-800 text-white text-lg font-semibold z-10"
                >
                  Loading video...
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-end gap-2">
          <BaseButton
            type="button"
            @click="handleReject"
            class="bg-white text-black rounded-md border border-gray-300 px-4 py-2"
          >
            Reject
          </BaseButton>
          <BaseButton
            type="button"
            @click="handleAccept"
            class="bg-primary-purple text-white rounded-md px-4 py-2"
          >
            Approve
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { ReviewEntryExpert } from "@/types/dashboard-types";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";

const GET_EXPERT_USER_PROGRAM_PROFILE_QUERY = gql`
  query getCompanyExpertUserProgramProfile($userId: ID!, $programId: ID!) {
    getCompanyExpertUserProgramProfile(userId: $userId, programId: $programId) {
      summary
      introductionVideo
    }
  }
`;

const CHANGE_EXPERT_JOIN_PROGRAM_REQUEST_STATUS = gql`
  mutation handleJoinProgramRequestStatus(
    $input: HandleJoinProgramRequestStatusInput!
  ) {
    handleJoinProgramRequestStatus(input: $input) {
      error
      success
    }
  }
`;

export default defineComponent({
  name: "ReviewEnteryModal",
  components: {
    BaseButton,
    BaseLabel,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    expert: {
      type: Object as PropType<ReviewEntryExpert>,
      required: true,
    },
    requestId: {
      type: String,
      required: true,
    },
    onClose: Function as PropType<() => void>,
  },
  emits: ["refreshRequests"],
  setup(props, { emit }) {
    const summary = ref<string>("");
    const introductionVideo = ref<string>("");
    const userStore = useUserStore();
    const errorMessage = ref<string>("");
    const isVideoLoading = ref<boolean>(false);
    // const requestId = ref<string>("");

    const {
      mutate: changeExpertJoinProgramRequestStatus,
      onDone: changeExpertJoinProgramRequestStatusDone,
      onError: changeExpertJoinProgramRequestStatusError,
    } = useMutation(CHANGE_EXPERT_JOIN_PROGRAM_REQUEST_STATUS);

    const closeModal = () => {
      if (props.onClose) props.onClose();
    };

    const handleCanPlay = () => {
      isVideoLoading.value = false;
    };

    const handleLoadStart = () => {
      isVideoLoading.value = true;
    };

    const fetchEntryData = () => {
      console.log("calling fetchEntryData with epxer id ", props?.expert?.id);
      const { onResult: onResultEntryData, onError: onErrorEntryData } =
        useQuery(
          GET_EXPERT_USER_PROGRAM_PROFILE_QUERY,
          {
            userId: props?.expert?.id,
            programId: userStore.selectedProgram?.id,
          },
          { fetchPolicy: "network-only" }
        );

      onResultEntryData((result) => {
        if (result.data) {
          console.log("result.data on fetchEntryData", result.data);
          summary.value =
            result.data.getCompanyExpertUserProgramProfile.summary;
          introductionVideo.value =
            result.data.getCompanyExpertUserProgramProfile.introductionVideo;
        }
      });

      onErrorEntryData((error) => {
        console.log("error ", error);
        if (error) {
          errorMessage.value = error.message;
          console.log("errorMessage.value ", errorMessage.value);
        }
      });
    };

    const handleReject = () => {
      // console.log("handleReject", requestId.value);
      handleStatusChange("rejected");
    };

    const handleAccept = () => {
      // console.log("handleAccept", requestId.value);
      handleStatusChange("approved");
    };

    const handleStatusChange = (status: string) => {
      // console.log("handleStatusChange", requestId.value, status);
      changeExpertJoinProgramRequestStatus({
        input: {
          requestId: props.requestId,
          status: status,
        },
      });

      changeExpertJoinProgramRequestStatusDone((result) => {
        if (result) {
          if (result.data.handleJoinProgramRequestStatus.success) {
            closeModal();
            emit("refreshRequests");
            console.log("success");
          } else {
            errorMessage.value =
              result.data.handleJoinProgramRequestStatus.error;
          }
        }
      });

      changeExpertJoinProgramRequestStatusError((error) => {
        if (error) {
          errorMessage.value = error.message;
          console.log("errorMessage.value ", errorMessage.value);
        }
      });
    };

    onMounted(() => {
      fetchEntryData();
    });

    return {
      closeModal,
      summary,
      introductionVideo,
      errorMessage,
      handleCanPlay,
      handleLoadStart,
      isVideoLoading,
      handleReject,
      handleAccept,
    };
  },
});
</script>
