<template>
  <div class="overflow-x-auto w-full pr-4">
    <div class="flex flex-col mb-4">
      <div class="flex flex-row gap-2">
        <!-- Flex container for equal height -->
        <!-- Directory Edit -->
        <div
          v-if="isDirectoryVisible"
          class="flex-1 border border-gray-200 rounded-lg flex flex-col"
        >
          <!-- Flex to keep height equal -->
          <div class="flex-1">
            <!-- Allow this div to grow -->
            <ViewDirectory @hideWidgets="hideWidgets" />
          </div>
        </div>

        <!-- Widget Edit -->
        <div
          v-if="isWidgetVisible"
          class="flex-1 border border-gray-200 rounded-lg flex flex-col"
        >
          <!-- Flex to keep height equal -->
          <div class="flex-1">
            <!-- Allow this div to grow -->
            <ViewWidget @hideDirectory="hideDirectory" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ViewDirectory from "@/components/dashboard/programs/directory-and-widget/ViewDirectory.vue";
import ViewWidget from "@/components/dashboard/programs/directory-and-widget/ViewWidget.vue";

export default defineComponent({
  name: "DirectoryAndWidgetContainer",
  components: {
    ViewDirectory,
    ViewWidget,
  },
  setup() {
    const isWidgetVisible = ref(true);
    const isDirectoryVisible = ref(true);

    const hideWidgets = (value: boolean) => {
      isWidgetVisible.value = !value;
    };

    const hideDirectory = (value: boolean) => {
      isDirectoryVisible.value = !value;
    };

    return {
      hideWidgets,
      hideDirectory,
      isWidgetVisible,
      isDirectoryVisible,
    };
  },
});
</script>
