import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col ml-6 gap-6" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-col gap-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurrentAccountBalance = _resolveComponent("CurrentAccountBalance")!
  const _component_InvoiceHistory = _resolveComponent("InvoiceHistory")!
  const _component_UpdateAccountBalance = _resolveComponent("UpdateAccountBalance")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoadingAccountBalance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col items-start" }, [
      _createElementVNode("span", { class: "text-black text-3xl font-semibold leading-9" }, "Billing")
    ], -1)),
    (_ctx.showCurrentAccountBalance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_CurrentAccountBalance, {
            availableBalance: _ctx.availableBalance,
            totalBalance: _ctx.totalBalance,
            usedBalance: _ctx.usedBalance,
            autoTopup: _ctx.autoTopup,
            autoRechargeAmount: _ctx.autoRechargeAmount,
            lowBalanceAmount: _ctx.lowBalanceAmount,
            paymentMethodCardNumber: _ctx.paymentMethodCardNumber,
            paymentMethodCreatedAt: _ctx.paymentMethodCreatedAt,
            paymentMethodSource: _ctx.paymentMethodSource,
            paymentMethodInvoiceRecipientEmail: _ctx.paymentMethodInvoiceRecipientEmail,
            updateAccountBalance: _ctx.updateAccountBalance
          }, null, 8, ["availableBalance", "totalBalance", "usedBalance", "autoTopup", "autoRechargeAmount", "lowBalanceAmount", "paymentMethodCardNumber", "paymentMethodCreatedAt", "paymentMethodSource", "paymentMethodInvoiceRecipientEmail", "updateAccountBalance"]),
          _createVNode(_component_InvoiceHistory)
        ]))
      : (_openBlock(), _createBlock(_component_UpdateAccountBalance, {
          key: 2,
          accountBalanceId: _ctx.accountBalanceId,
          availableBalance: _ctx.availableBalance,
          autoTopup: _ctx.autoTopup,
          autoRechargeAmount: _ctx.autoRechargeAmount,
          lowBalanceAmount: _ctx.lowBalanceAmount,
          paymentMethodExpirationDate: _ctx.paymentMethodExpirationDate,
          paymentMethodCardNumber: _ctx.paymentMethodCardNumber,
          paymentMethodInvoiceRecipientEmail: _ctx.paymentMethodInvoiceRecipientEmail,
          paymentMethodSource: _ctx.paymentMethodSource,
          paymentMethodId: _ctx.paymentMethodId,
          cancel: _ctx.cancelUpdateAccountBalance,
          onRefetchAccountBalance: _ctx.refetchAccountBalance
        }, null, 8, ["accountBalanceId", "availableBalance", "autoTopup", "autoRechargeAmount", "lowBalanceAmount", "paymentMethodExpirationDate", "paymentMethodCardNumber", "paymentMethodInvoiceRecipientEmail", "paymentMethodSource", "paymentMethodId", "cancel", "onRefetchAccountBalance"]))
  ]))
}