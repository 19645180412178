<template>
  <div class="flex flex-col ml-6 gap-6 mb-4">
    <div class="flex flex-col items-start">
      <span class="text-black text-3xl font-semibold leading-9 text-left"
        >Team Management</span
      >
    </div>
    <!-- total member + add members -->
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex flex-wrap gap-2 items-center">
        <span class="text-gray-800 text-base font-semibold leading-6"
          >Team Members</span
        ><span class="text-gray-500 text-sm font-normal leading-5"
          >({{ teamMembersCount }})</span
        >
      </div>
      <div class="flex flex-col">
        <BaseButton
          type="button"
          class="px-4 py-2 rounded-lg text-white text-sm font-medium leading-5 bg-primary-purple"
          @click="handleAddTeamMember"
        >
          Add member +
        </BaseButton>
      </div>
    </div>
    <div class="flex flex-col">
      <transition name="fade">
        <span
          v-if="sentSuccessfully"
          class="text-primary-purple text-sm font-medium leading-5 text-left"
        >
          Invites sent successfully
        </span>
      </transition>
    </div>

    <!-- members list -->
    <TeamMembersList
      :newInvitesSent="newInvitesSent"
      @teamMembersCount="teamMembersCount = $event"
    />
  </div>
  <AddTeamMemberModal
    v-if="isAddMemberModalOpen"
    :isVisible="isAddMemberModalOpen"
    invitationType="company"
    @update:isVisible="isAddMemberModalOpen = $event"
    @update:emailsSent="handleEmailsSent"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import TeamMembersList from "@/components/dashboard/team-mangement/TeamMembersList.vue";
import AddTeamMemberModal from "@/components/dashboard/team-mangement/AddTeamMemberModal.vue";

export default defineComponent({
  name: "TeamManagementContainer",
  components: {
    BaseButton,
    TeamMembersList,
    AddTeamMemberModal,
  },
  setup() {
    const isAddMemberModalOpen = ref(false);
    const newInvitesSent = ref(false);
    const sentSuccessfully = ref(false);
    const teamMembersCount = ref(0);

    const handleAddTeamMember = () => {
      isAddMemberModalOpen.value = true;
    };

    const handleEmailsSent = () => {
      isAddMemberModalOpen.value = false;
      newInvitesSent.value = !newInvitesSent.value;
      sentSuccessfully.value = true;
      setTimeout(() => {
        sentSuccessfully.value = false;
      }, 2000);
    };

    return {
      isAddMemberModalOpen,
      handleAddTeamMember,
      newInvitesSent,
      handleEmailsSent,
      sentSuccessfully,
      teamMembersCount,
    };
  },
});
</script>

<style>
/* Fade transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease; /* Duration and easing for the transition */
}

.fade-enter,
.fade-leave-to {
  opacity: 0; /* Initial and final state for fade */
}

/* Custom styles for the message */
.success-message {
  color: #6b46c1; /* Purple color for success message */
  font-size: 0.875rem; /* Text size */
  font-weight: 500; /* Medium font weight */
  text-align: left; /* Align text to the left */
  display: inline-block; /* Makes sure the span behaves like a block */
}
</style>
