import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-1 p-4 border border-solid border-gray-300 bg-white rounded-lg" }
const _hoisted_2 = { class: "flex flex-row justify-between items-center gap-4" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-col justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col gap-3" }, [
        _createElementVNode("span", { class: "text-gray-800 font-sans text-lg font-semibold leading-6 tracking-tight" }, "Create New Activity"),
        _createElementVNode("span", { class: "text-gray-600 font-sans text-sm font-medium leading-5" }, " When an Attendee schedules an Expert meeting and when an Attendee completes an Expert meeting, add Activities. ")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: _normalizeClass(["flex items-center w-12 h-6 rounded-xl transition-colors duration-300", {
              'bg-violet-600': _ctx.createNewActivity,
              'bg-gray-200': !_ctx.createNewActivity,
            }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCreateNewActivity && _ctx.toggleCreateNewActivity(...args))),
            "aria-label": "Toggle button"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform", {
                'translate-x-6': _ctx.createNewActivity,
                'translate-x-0.5': !_ctx.createNewActivity,
              }])
            }, null, 2)
          ], 2)
        ])
      ])
    ])
  ]))
}