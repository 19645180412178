<template>
  <div class="flex items-center justify-center h-screen">
    <h1 class="text-4xl font-bold text-center">
      Hello, this is Company Dashboard!
    </h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "DashboardView",
});
</script>
