<template>
  <div class="flex flex-col items-center">
    <div class="w-full bg-gray-300 rounded-lg h-2">
      <div
        class="bg-primary-purple h-full rounded-lg"
        :style="{ width: status + '%' }"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";

export default defineComponent({
  name: "StatusBar",
  props: {
    current: {
      type: Number as PropType<number>,
      required: true,
    },
    total: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props) {
    const status = computed(() => {
      // Avoid division by zero and calculate the percentage
      return props.total > 0
        ? ((props.current / props.total) * 100).toFixed(2)
        : 0;
    });

    return {
      status,
    };
  },
});
</script>

<style scoped>
/* Optional: Additional styles can be added here */
</style>
