import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client/core";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

import { setContext } from "@apollo/client/link/context";
import { provideApolloClient } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";

const uploadLink = createUploadLink({
  uri: process.env.VUE_APP_API_TARGET,
});

const authLink = setContext((_, { headers }) => {
  const userStore = useUserStore();
  const token = userStore.token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const link = ApolloLink.from([authLink, uploadLink]);

const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

export function setupApolloClient() {
  provideApolloClient(apolloClient);
}
