import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/shared/failure.svg'


const _hoisted_1 = { class: "bg-white min-h-screen flex flex-col" }
const _hoisted_2 = { class: "flex justify-center mb-4" }
const _hoisted_3 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_4 = { class: "flex flex-wrap items-center pt-2 pr-2 pl-3.5 text-xs whitespace-nowrap text-zinc-700 max-md:pr-5" }
const _hoisted_5 = {
  key: 0,
  class: "flex gap-2 bg-gray-100 p-6 justify-center w-full max-md:max-w-full"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "flex flex-col items-center mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileCard = _resolveComponent("ProfileCard")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", null, "Experts Directory", -1)),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Error Icon",
                  class: "w-4 h-4"
                })
              ], -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isGridUneven)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (n) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: n,
                  class: "space-y-3"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles.filter(
              (_, index) => index % _ctx.columns === n - 1
            ), (profile) => {
                    return (_openBlock(), _createBlock(_component_ProfileCard, {
                      key: profile.id,
                      profile: profile,
                      isCentered: _ctx.profileCenterAligned,
                      isGridUneven: _ctx.isGridUneven,
                      profileImageClass: _ctx.profileImageClass,
                      profileImageAndNameLayoutClass: _ctx.profileImageAndNameLayoutClass,
                      fontFamily: _ctx.fontFamily,
                      boldness: _ctx.boldness,
                      textColor: _ctx.textColor,
                      backgroundColor: _ctx.backgroundColor,
                      fontSize: _ctx.fontSize,
                      enableBookMeeting: true,
                      programUuid: _ctx.programUuid as string,
                      class: "flex-shrink-0"
                    }, null, 8, ["profile", "isCentered", "isGridUneven", "profileImageClass", "profileImageAndNameLayoutClass", "fontFamily", "boldness", "textColor", "backgroundColor", "fontSize", "programUuid"]))
                  }), 128))
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex flex-wrap bg-gray-100 p-6 items-start w-full max-md:max-w-full", _ctx.profileCardsGap])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles, (profile) => {
                  return (_openBlock(), _createBlock(_component_ProfileCard, {
                    key: profile.id,
                    profile: profile,
                    isCentered: _ctx.profileCenterAligned,
                    isGridUneven: _ctx.isGridUneven,
                    profileImageClass: _ctx.profileImageClass,
                    profileImageAndNameLayoutClass: _ctx.profileImageAndNameLayoutClass,
                    fontFamily: _ctx.fontFamily,
                    boldness: _ctx.boldness,
                    textColor: _ctx.textColor,
                    backgroundColor: _ctx.backgroundColor,
                    fontSize: _ctx.fontSize,
                    enableBookMeeting: true,
                    programUuid: _ctx.programUuid as string,
                    class: "flex-shrink-0"
                  }, null, 8, ["profile", "isCentered", "isGridUneven", "profileImageClass", "profileImageAndNameLayoutClass", "fontFamily", "boldness", "textColor", "backgroundColor", "fontSize", "programUuid"]))
                }), 128))
              ], 2)
            ]))
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.hasMoreExperts)
          ? (_openBlock(), _createBlock(_component_BaseButton, {
              key: 0,
              type: "button",
              onClick: _ctx.loadMoreExperts,
              class: "flex overflow-hidden flex-wrap gap-3 justify-center items-center self-stretch py-2.5 pr-4 pl-4 my-auto text-violet-600 rounded-md max-md:max-w-full"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("span", { class: "shadow-sm hover:shadow-md transition-shadow duration-200 ease-in-out p-2 rounded-md" }, " Load More Experts ", -1)
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}