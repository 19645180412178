import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../../../assets/shared/failure.svg'


const _hoisted_1 = { class: "flex flex-col z-20 gap-5 justify-between mt-2 text-left" }
const _hoisted_2 = { class: "flex justify-center mb-4" }
const _hoisted_3 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_4 = { class: "flex flex-col gap-2 flex-grow basis-1/2" }
const _hoisted_5 = { class: "flex flex-col gap-1" }
const _hoisted_6 = { class: "text-lg" }
const _hoisted_7 = { class: "flex flex-col gap-1" }
const _hoisted_8 = { class: "flex flex-col border b-1 p-6" }
const _hoisted_9 = { class: "flex flex-row justify-between items-center" }
const _hoisted_10 = {
  key: 0,
  class: "flex flex-wrap items-center my-3 gap-4 justify-start overflow-x-hidden"
}
const _hoisted_11 = { class: "flex flex-col sm:flex-row flex-1" }
const _hoisted_12 = { class: "flex-1 w-1/2 sm:w-2/3 md:w-full" }
const _hoisted_13 = { class: "flex flex-col sm:flex-row flex-1" }
const _hoisted_14 = { class: "flex-1 w-1/2 sm:w-2/3 md:w-full" }
const _hoisted_15 = { class: "flex flex-wrap border b-1 p-6 rounded-md gap-2" }
const _hoisted_16 = { class: "flex flex-col gap-2" }
const _hoisted_17 = { class: "flex flex-col" }
const _hoisted_18 = {
  key: 0,
  class: "flex flex-col text-xs font-medium leading-5 text-zinc-800 text-left"
}
const _hoisted_19 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "flex flex-col" }
const _hoisted_22 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_23 = { class: "flex flex-col font-bold text-sm" }
const _hoisted_24 = { class: "flex flex-row p-3 bg-gray-200 rounded-md" }
const _hoisted_25 = { class: "flex flex-row w-1/2 rounded-md" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = { class: "flex flex-row w-1/2" }
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "flex flex-row justify-end" }
const _hoisted_31 = { class: "flex flex-row gap-2" }
const _hoisted_32 = {
  key: 0,
  class: "flex flex-col justify-center items-center text-primary-purple"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_StripeCardFields = _resolveComponent("StripeCardFields")!
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Error Icon",
                class: "w-4 h-4"
              })
            ], -1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BaseLabel, {
          forAttr: "currentAccountBalance",
          text: "Available Balance",
          class: "text-sm font-medium leading-5 text-zinc-800 text-left"
        }),
        _createElementVNode("span", _hoisted_6, "$ " + _toDisplayString(_ctx.availableBalance), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_BaseLabel, {
          forAttr: "accountBalance",
          text: "Add Balance",
          class: "text-sm font-medium leading-5 text-zinc-800 text-left"
        }),
        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-xs" }, " Enter the amount to add to your total balance right now ", -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_CurrencyInput, {
            modelValue: _ctx.accountBalance,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accountBalance) = $event)),
            leadingText: "$",
            trailingText: "USD",
            placeholder: "100"
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "font-semibold leading-9 text-left text-zinc-800" }, " Auto Top-up ", -1)),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleAutoTopup && _ctx.toggleAutoTopup(...args))),
          class: _normalizeClass([
            _ctx.isAutoTopupEnabled ? 'bg-primary-purple' : 'bg-gray-200',
            'flex items-center h-6 w-11 rounded-full p-1 transition-colors ease-in-out duration-300',
          ])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass([
              _ctx.isAutoTopupEnabled ? 'translate-x-5' : 'translate-x-0',
              'block w-4 h-4 transform bg-white rounded-full transition-transform ease-in-out duration-300',
            ])
          }, null, 2)
        ], 2)
      ]),
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex flex-col my-3" }, [
        _createElementVNode("span", { class: "text-sm leading-tight" }, "Enter the amount you want to automatically top up your account.")
      ], -1)),
      (_ctx.isAutoTopupEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "text-sm mr-2 font-bold" }, " If amount goes below", -1)),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_CurrencyInput, {
                  modelValue: _ctx.topUpLimit,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.topUpLimit) = $event)),
                  leadingText: "$",
                  trailingText: "USD",
                  placeholder: "100"
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[11] || (_cache[11] = _createElementVNode("span", { class: "text-sm mx-2 font-bold" }, " Auto top-up for", -1)),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_CurrencyInput, {
                  modelValue: _ctx.topUpAmount,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.topUpAmount) = $event)),
                  leadingText: "$",
                  trailingText: "USD",
                  placeholder: "100"
                }, null, 8, ["modelValue"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_BaseButton, {
            type: "button",
            class: _normalizeClass(["text-sm font-medium leading-5 text-center rounded-md p-2", {
              'bg-primary-purple text-white': _ctx.isPaymentMethodCurrent,
              'bg-gray-200 text-zinc-800': !_ctx.isPaymentMethodCurrent,
            }]),
            onClick: _ctx.setPaymentMethodToCurrent
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("Use current payment method")
            ])),
            _: 1
          }, 8, ["class", "onClick"])
        ]),
        (_ctx.isPaymentMethodCurrent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              (_ctx.paymentMethodSource === 'credit_card')
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("span", null, "Card Number: " + _toDisplayString(_ctx.paymentMethodCardNumber), 1),
                    _createElementVNode("span", null, "Expiration Date: " + _toDisplayString(_ctx.paymentMethodExpirationDate), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("span", null, "Invoice Recipient Email: " + _toDisplayString(_ctx.paymentMethodInvoiceRecipientEmail), 1)
                  ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _cache[15] || (_cache[15] = _createElementVNode("div", { class: "flex flex-col" }, [
        _createElementVNode("span", { class: "text-sm font-medium leading-5 p-2 text-zinc-800 text-left" }, "OR")
      ], -1)),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_BaseButton, {
          type: "button",
          class: _normalizeClass(["text-sm font-medium leading-5 rounded-md text-center p-2", {
            'bg-primary-purple text-white': !_ctx.isPaymentMethodCurrent,
            'bg-gray-200 text-zinc-800': _ctx.isPaymentMethodCurrent,
          }]),
          onClick: _ctx.setPaymentMethodToNew
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createTextVNode("Add New Payment Method")
          ])),
          _: 1
        }, 8, ["class", "onClick"])
      ])
    ]),
    (!_ctx.isPaymentMethodCurrent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _cache[18] || (_cache[18] = _createElementVNode("span", null, "Add Billing Method", -1)),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-row p-3 w-full justify-center items-center rounded-md", {
                'bg-primary-purple text-white': _ctx.isPaymentMethodCard,
                'text-secondary-gray': !_ctx.isPaymentMethodCard,
              }]),
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.setPaymentMethodToCard && _ctx.setPaymentMethodToCard(...args)))
                }, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.CARD_ICON,
                    class: _normalizeClass(["mr-2", {
                  'text-white': _ctx.isPaymentMethodCard,
                  'text-secondary-gray': !_ctx.isPaymentMethodCard,
                }])
                  }, null, 10, _hoisted_26),
                  _cache[16] || (_cache[16] = _createTextVNode(" Card "))
                ], 2)
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-row p-3 w-full justify-center items-center rounded-md", {
                'bg-primary-purple text-white': !_ctx.isPaymentMethodCard,
                'text-secondary-gray': _ctx.isPaymentMethodCard,
              }]),
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.setPaymentMethodToInvoice && _ctx.setPaymentMethodToInvoice(...args)))
                }, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.DOCUMENT_DOLLAR_ICON,
                    class: _normalizeClass(["mr-2", {
                  'text-white': !_ctx.isPaymentMethodCard,
                  'text-secondary-gray': _ctx.isPaymentMethodCard,
                }])
                  }, null, 10, _hoisted_28),
                  _cache[17] || (_cache[17] = _createTextVNode(" Invoice "))
                ], 2)
              ])
            ])
          ]),
          (_ctx.isPaymentMethodCard)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createVNode(_component_StripeCardFields, {
                  onPaymentMethodId: _ctx.handlePaymentMethodId,
                  onError: _ctx.handleError
                }, null, 8, ["onPaymentMethodId", "onError"])
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_BaseLabel, {
              forAttr: "invoiceEmail",
              text: "Invoice Recipient Email",
              class: "text-sm font-medium leading-5 text-zinc-800 text-left"
            }),
            _createVNode(_component_BaseInput, {
              type: "email",
              id: "invoiceEmail",
              modelValue: _ctx.invoiceEmail,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.invoiceEmail) = $event)),
              placeholder: "you@example.com",
              required: "",
              className: "px-3.5 py-2.5 mt-1 text-gray-500 bg-white rounded-md border border-solid shadow-sm w-full"
            }, null, 8, ["modelValue"])
          ], 512), [
            [_vShow, !_ctx.isPaymentMethodCard]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_30, [
      _createElementVNode("div", _hoisted_31, [
        (_ctx.isLoadingAccountBalance)
          ? (_openBlock(), _createElementBlock("span", _hoisted_32, "Updating...."))
          : _createCommentVNode("", true),
        _createVNode(_component_BaseButton, {
          type: "button",
          class: "flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center",
          disabled: _ctx.isLoadingAccountBalance,
          onClick: _ctx.cancel
        }, {
          default: _withCtx(() => _cache[19] || (_cache[19] = [
            _createTextVNode("Cancel")
          ])),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_BaseButton, {
          type: "button",
          class: "flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center",
          onClick: _ctx.update,
          disabled: _ctx.isLoadingAccountBalance
        }, {
          default: _withCtx(() => _cache[20] || (_cache[20] = [
            _createTextVNode("Update")
          ])),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ])
  ]))
}