import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../../assets/shared/avatar-group.svg'


const _hoisted_1 = {
  key: 0,
  class: "flex flex-col px-3"
}
const _hoisted_2 = { class: "flex flex-wrap items-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "flex flex-wrap p-2 gap-2 justify-between rounded-lg items-center relative",
          style: _normalizeStyle({
        fontFamily: _ctx.fontFamily,
        fontWeight: _ctx.fontBoldness,
        fontSize: _ctx.fontSize + 'px',
        backgroundColor: _ctx.headerBackgroundColor,
      })
        }, [
          _createElementVNode("div", {
            class: "absolute top-0 right-0 w-6 h-6 bg-white rounded-full text-black flex items-center justify-center text-xl cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
          }, " × "),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "text-purple-50 text-left",
              style: _normalizeStyle({ fontFamily: _ctx.fontFamily, color: _ctx.headerTextColor })
            }, _toDisplayString(_ctx.topheaderText), 5),
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              src: _imports_0,
              alt: "Error Icon",
              class: "h-full",
              loading: "lazy"
            }, null, -1))
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "flex flex-col justify-center items-start p-2 px-5 w-full text-sm font-medium leading-none text-indigo-600 bg-gray-50 rounded-lg",
              onClick: _ctx.goToExpertsDirectory,
              style: _normalizeStyle({
            color: _ctx.buttonTextColor,
            fontSize: _ctx.fontSize + 'px',
            fontWeight: _ctx.fontBoldness,
            backgroundColor: _ctx.buttonBackgrounColor,
          })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
              ]),
              _: 1
            }, 8, ["onClick", "style"])
          ])
        ], 4)
      ]))
    : _createCommentVNode("", true)
}