import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../../../../assets/shared/failure.svg'


const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 flex items-center justify-center z-50"
}
const _hoisted_2 = {
  id: "edit-program-modal",
  class: "flex flex-col bg-white p-6 max-h-[80vh] overflow-y-auto rounded-lg gap-2 shadow-lg z-10 max-w-md w-full mx-4"
}
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-wrap justify-between items-center" }
const _hoisted_5 = { class: "text-right" }
const _hoisted_6 = { class: "flex justify-center mb-4" }
const _hoisted_7 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_8 = { class: "flex flex-col gap-2 text-left" }
const _hoisted_9 = { class: "relative flex flex-col justify-start mt-6 text-base font-medium leading-6 z-10" }
const _hoisted_10 = { class: "flex flex-wrap justify-end gap-2 mt-2" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_GoalsCheckboxList = _resolveComponent("GoalsCheckboxList")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "fixed inset-0 bg-black opacity-50" }, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[10] || (_cache[10] = _createElementVNode("div", null, [
                _createElementVNode("span", { class: "text-2xl font-medium leading-8 text-gray-800" }, "Program Details")
              ], -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_BaseButton, {
                  onClick: _ctx.closeModal,
                  class: "text-lg text-gray-500 hover:text-black"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" X ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "Error Icon",
                      class: "w-4 h-4"
                    })
                  ], -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_BaseLabel, {
              forAttr: "aboutProgram",
              text: "About Program",
              class: "text-sm font-medium leading-5 text-gray-800"
            }),
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localAboutProgram) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleAboutProgramChange($event))),
              placeholder: "Type your text here...",
              class: _normalizeClass(["w-full border rounded-md p-2 resize-none focus:outline-none", {
            'border-red-500': _ctx.aboutProgramWarning,
            'border-gray-300': !_ctx.aboutProgramWarning,
          }]),
              rows: "4"
            }, null, 34), [
              [_vModelText, _ctx.localAboutProgram]
            ]),
            _createElementVNode("span", {
              class: _normalizeClass(["text-xs mt-1 text-gray-400", { 'text-red-500': _ctx.aboutProgramWarning }])
            }, _toDisplayString(_ctx.localAboutProgram.length) + " / 1056 ", 3)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BaseLabel, {
              forAttr: "Goals",
              text: "Goals & Use Cases",
              class: "text-lg font-medium leading-5 text-zinc-800 text-left"
            }),
            _createVNode(_component_GoalsCheckboxList, {
              convertWebsiteVisitors: _ctx.localConvertWebsiteVisitors,
              "onUpdate:convertWebsiteVisitors": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localConvertWebsiteVisitors) = $event)),
              generateIntentSignals: _ctx.localGenerateIntentSignals,
              "onUpdate:generateIntentSignals": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localGenerateIntentSignals) = $event)),
              enableSales: _ctx.localEnableSales,
              "onUpdate:enableSales": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localEnableSales) = $event)),
              engageNewCustomer: _ctx.localEngageNewCustomer,
              "onUpdate:engageNewCustomer": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localEngageNewCustomer) = $event)),
              driveConversion: _ctx.localDriveConversion,
              "onUpdate:driveConversion": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localDriveConversion) = $event)),
              sdrSequenceStep: _ctx.localSdrSequenceStep,
              "onUpdate:sdrSequenceStep": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localSdrSequenceStep) = $event)),
              lateStageCustomerReferences: _ctx.localLateStageCustomerReferences,
              "onUpdate:lateStageCustomerReferences": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localLateStageCustomerReferences) = $event))
            }, null, 8, ["convertWebsiteVisitors", "generateIntentSignals", "enableSales", "engageNewCustomer", "driveConversion", "sdrSequenceStep", "lateStageCustomerReferences"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_BaseButton, {
              type: "button",
              onClick: _ctx.closeModal,
              disabled: _ctx.updateProgramLoading,
              class: "text-black border border-gray-300 px-4 py-2 rounded"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" Close ")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"]),
            _createVNode(_component_BaseButton, {
              type: "button",
              onClick: _ctx.handleUpdate,
              disabled: _ctx.updateProgramLoading,
              class: "bg-primary-purple text-white px-4 py-2 rounded"
            }, {
              default: _withCtx(() => [
                (!_ctx.updateProgramLoading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Update"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_12, "Updating..."))
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}