export const EARNINGS_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.8434 9.65538C14.2053 10.0725 14.8369 10.1173 15.254 9.7553C15.6712 9.39335 15.7159 8.76176 15.354 8.34462L13.8434 9.65538ZM10.1567 14.3446C9.79471 13.9275 9.16313 13.8827 8.74599 14.2447C8.32885 14.6067 8.28411 15.2382 8.64607 15.6554L10.1567 14.3446ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7H13ZM11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17L11 17ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM12 11C11.3415 11 10.7905 10.8202 10.4334 10.5822C10.0693 10.3394 10 10.1139 10 10H8C8 10.9907 8.6023 11.7651 9.32398 12.2463C10.0526 12.732 11.0017 13 12 13V11ZM10 10C10 9.8861 10.0693 9.66058 10.4334 9.41784C10.7905 9.17976 11.3415 9 12 9V7C11.0017 7 10.0526 7.26796 9.32398 7.75374C8.6023 8.23485 8 9.00933 8 10H10ZM12 9C12.9038 9 13.563 9.33231 13.8434 9.65538L15.354 8.34462C14.5969 7.47209 13.3171 7 12 7V9ZM12 13C12.6585 13 13.2095 13.1798 13.5666 13.4178C13.9308 13.6606 14 13.8861 14 14H16C16 13.0093 15.3977 12.2348 14.676 11.7537C13.9474 11.268 12.9983 11 12 11V13ZM11 7V8H13V7H11ZM11 16L11 17L13 17L13 16L11 16ZM12 15C11.0962 15 10.437 14.6677 10.1567 14.3446L8.64607 15.6554C9.40317 16.5279 10.683 17 12 17L12 15ZM14 14C14 14.1139 13.9308 14.3394 13.5666 14.5822C13.2095 14.8202 12.6586 15 12 15V17C12.9983 17 13.9474 16.732 14.676 16.2463C15.3977 15.7651 16 14.9907 16 14H14ZM11 8L11 16L13 16L13 8L11 8Z"
      fill="currentColor"
    />
  </svg>
`;

export const DASHBOARD_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M4 4V3H3V4H4ZM20 4H21V3H20V4ZM6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071C6.68342 13.0976 7.31658 13.0976 7.70711 12.7071L6.29289 11.2929ZM10 9L10.7071 8.29289C10.3166 7.90237 9.68342 7.90237 9.29289 8.29289L10 9ZM13 12L12.2929 12.7071C12.6834 13.0976 13.3166 13.0976 13.7071 12.7071L13 12ZM17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289C17.3166 6.90237 16.6834 6.90237 16.2929 7.29289L17.7071 8.70711ZM7.29289 20.2929C6.90237 20.6834 6.90237 21.3166 7.29289 21.7071C7.68342 22.0976 8.31658 22.0976 8.70711 21.7071L7.29289 20.2929ZM12 17L12.7071 16.2929C12.3166 15.9024 11.6834 15.9024 11.2929 16.2929L12 17ZM15.2929 21.7071C15.6834 22.0976 16.3166 22.0976 16.7071 21.7071C17.0976 21.3166 17.0976 20.6834 16.7071 20.2929L15.2929 21.7071ZM3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5V3ZM21 5C21.5523 5 22 4.55228 22 4C22 3.44772 21.5523 3 21 3V5ZM4 5H20V3H4V5ZM19 4V16H21V4H19ZM19 16H5V18H19V16ZM5 16V4H3V16H5ZM5 16H3C3 17.1046 3.89543 18 5 18V16ZM19 16V18C20.1046 18 21 17.1046 21 16H19ZM7.70711 12.7071L10.7071 9.70711L9.29289 8.29289L6.29289 11.2929L7.70711 12.7071ZM9.29289 9.70711L12.2929 12.7071L13.7071 11.2929L10.7071 8.29289L9.29289 9.70711ZM13.7071 12.7071L17.7071 8.70711L16.2929 7.29289L12.2929 11.2929L13.7071 12.7071ZM8.70711 21.7071L12.7071 17.7071L11.2929 16.2929L7.29289 20.2929L8.70711 21.7071ZM11.2929 17.7071L15.2929 21.7071L16.7071 20.2929L12.7071 16.2929L11.2929 17.7071ZM3 5H21V3H3V5Z"
      fill="currentColor"
    />
  </svg>
  `;

export const EXPERTISE_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <path
      d="M9 17V7M9 17C9 18.1046 8.10457 19 7 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5H7C8.10457 5 9 5.89543 9 7M9 17C9 18.1046 9.89543 19 11 19H13C14.1046 19 15 18.1046 15 17M9 7C9 5.89543 9.89543 5 11 5H13C14.1046 5 15 5.89543 15 7M15 17V7M15 17C15 18.1046 15.8954 19 17 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H17C15.8954 5 15 5.89543 15 7"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const PERSON_ICON = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M10.4993 4C10.4993 4.66304 10.2359 5.29893 9.7671 5.76777C9.29826 6.23661 8.66237 6.5 7.99933 6.5C7.33629 6.5 6.70041 6.23661 6.23157 5.76777C5.76273 5.29893 5.49933 4.66304 5.49933 4C5.49933 3.33696 5.76273 2.70107 6.23157 2.23223C6.70041 1.76339 7.33629 1.5 7.99933 1.5C8.66237 1.5 9.29826 1.76339 9.7671 2.23223C10.2359 2.70107 10.4993 3.33696 10.4993 4ZM3 13.412C3.02142 12.1002 3.55756 10.8494 4.49278 9.92936C5.42801 9.00929 6.68739 8.49365 7.99933 8.49365C9.31127 8.49365 10.5707 9.00929 11.5059 9.92936C12.4411 10.8494 12.9772 12.1002 12.9987 13.412C11.4303 14.1312 9.72476 14.5023 7.99933 14.5C6.21533 14.5 4.522 14.1107 3 13.412Z" stroke="currentColor" stroke-width="1.48571" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
  </svg>
`;

export const MONEY_ICON = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M1.5 12.5C5.05707 12.4971 8.59867 12.9681 12.0313 13.9007C12.516 14.0327 13 13.6727 13 13.17V12.5M2.5 3V3.5C2.5 3.63261 2.44732 3.75979 2.35355 3.85355C2.25979 3.94732 2.13261 4 2 4H1.5M1.5 4V3.75C1.5 3.336 1.836 3 2.25 3H13.5M1.5 4V10M13.5 3V3.5C13.5 3.776 13.724 4 14 4H14.5M13.5 3H13.75C14.164 3 14.5 3.336 14.5 3.75V10.25C14.5 10.664 14.164 11 13.75 11H13.5M1.5 10V10.25C1.5 10.4489 1.57902 10.6397 1.71967 10.7803C1.86032 10.921 2.05109 11 2.25 11H2.5M1.5 10H2C2.13261 10 2.25979 10.0527 2.35355 10.1464C2.44732 10.2402 2.5 10.3674 2.5 10.5V11M13.5 11V10.5C13.5 10.3674 13.5527 10.2402 13.6464 10.1464C13.7402 10.0527 13.8674 10 14 10H14.5M13.5 11H2.5M10 7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7ZM12 7H12.0053V7.00533H12V7ZM4 7H4.00533V7.00533H4V7Z" stroke="currentColor" stroke-width="1.14286" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const PEOPLE_ICON_2 = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M10.0007 12.7364C10.5693 12.9015 11.1585 12.985 11.7507 12.9844C12.7029 12.9858 13.6428 12.7686 14.498 12.3497C14.5233 11.7522 14.3531 11.1627 14.0132 10.6706C13.6733 10.1785 13.1823 9.81062 12.6145 9.62271C12.0467 9.43481 11.4331 9.43712 10.8668 9.62931C10.3004 9.82149 9.81217 10.1931 9.476 10.6877M10.0007 12.7364V12.7344C10.0007 11.9924 9.81 11.2944 9.476 10.6877M10.0007 12.7364V12.807C8.71765 13.5798 7.24774 13.9869 5.75 13.9844C4.196 13.9844 2.742 13.5544 1.50067 12.807L1.5 12.7344C1.49949 11.7907 1.81308 10.8737 2.39132 10.1279C2.96957 9.38211 3.77958 8.85 4.69366 8.61544C5.60774 8.38089 6.57388 8.45723 7.43978 8.83244C8.30567 9.20764 9.02206 9.86037 9.476 10.6877M8.00067 4.23437C8.00067 4.83111 7.76361 5.40341 7.34166 5.82536C6.9197 6.24732 6.3474 6.48437 5.75067 6.48437C5.15393 6.48437 4.58163 6.24732 4.15968 5.82536C3.73772 5.40341 3.50067 4.83111 3.50067 4.23437C3.50067 3.63764 3.73772 3.06534 4.15968 2.64338C4.58163 2.22143 5.15393 1.98438 5.75067 1.98438C6.3474 1.98438 6.9197 2.22143 7.34166 2.64338C7.76361 3.06534 8.00067 3.63764 8.00067 4.23437ZM13.5007 5.73437C13.5007 6.1985 13.3163 6.64362 12.9881 6.97181C12.6599 7.3 12.2148 7.48437 11.7507 7.48437C11.2865 7.48437 10.8414 7.3 10.5132 6.97181C10.185 6.64362 10.0007 6.1985 10.0007 5.73437C10.0007 5.27025 10.185 4.82513 10.5132 4.49694C10.8414 4.16875 11.2865 3.98437 11.7507 3.98437C12.2148 3.98437 12.6599 4.16875 12.9881 4.49694C13.3163 4.82513 13.5007 5.27025 13.5007 5.73437Z" stroke="currentColor" stroke-width="1.25714" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;
