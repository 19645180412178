import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen" }
const _hoisted_2 = { class: "flex flex-col w-full border-b" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_4 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_5 = { class: "flex flex-col w-full pt-12 pl-6 gap-4" }
const _hoisted_6 = { class: "flex flex-col w-full" }
const _hoisted_7 = { class: "text-black text-3xl font-semibold leading-9 text-left" }
const _hoisted_8 = { class: "flex flex-col w-full" }
const _hoisted_9 = { class: "flex flex-wrap gap-2 items-start gap-6" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_ProgramsList = _resolveComponent("ProgramsList")!
  const _component_ProgramDetailsContainer = _resolveComponent("ProgramDetailsContainer")!
  const _component_DirectoryAndWidgetContainer = _resolveComponent("DirectoryAndWidgetContainer")!
  const _component_ExpertsContainer = _resolveComponent("ExpertsContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_Navbar, { activeTab: 1 })
    ]),
    (!_ctx.programSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ProgramsList, { onProgramSelected: _ctx.handleProgramSelected }, null, 8, ["onProgramSelected"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("main", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.userStore.selectedProgram?.name), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-col", {
                'border-b border-primary-purple text-primary-purple':
                  _ctx.activeTab === 0,
                'text-gray-500': _ctx.activeTab !== 0,
              }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = 0))
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("span", { class: "text-sm font-medium leading-5" }, "Program Details", -1)
                ]), 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-col", {
                'border-b border-primary-purple text-primary-purple':
                  _ctx.activeTab === 1,
                'text-gray-500': _ctx.activeTab !== 1,
              }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = 1))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "text-sm font-medium leading-5" }, "Directory & Widgets", -1)
                ]), 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex flex-col", {
                'border-b border-primary-purple text-primary-purple':
                  _ctx.activeTab === 2,
                'text-gray-500': _ctx.activeTab !== 2,
              }]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeTab = 2))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("span", { class: "text-sm font-medium leading-5" }, "Experts", -1)
                ]), 2)
              ])
            ]),
            (_ctx.activeTab === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_ProgramDetailsContainer)
                ]))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_DirectoryAndWidgetContainer)
                ]))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_ExpertsContainer)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
  ]))
}