<template>
  <div>
    <input
      :id="id"
      :value="internalValue"
      :type="type"
      :class="computedClasses"
      :placeholder="placeholder"
      :required="required"
      @input="handleInput"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch, computed } from "vue";

export default defineComponent({
  name: "BaseInput",
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    type: {
      type: String as PropType<"text" | "email" | "password" | "number">,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    // Accept both string and object types for class prop
    className: {
      type: [String, Object] as PropType<string | Record<string, boolean>>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const internalValue = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newVal) => {
        internalValue.value = newVal;
      }
    );

    const handleInput = (event: Event) => {
      const inputElement = event.target as HTMLInputElement;
      internalValue.value = inputElement.value;
      emit("update:modelValue", inputElement.value);
    };

    // Use a computed property to handle dynamic classes
    const computedClasses = computed(() => {
      if (typeof props.className === "string") {
        return props.className; // Return string directly
      } else {
        // Type guard for object
        return Object.keys(props.className)
          .filter(
            (key) => (props.className as Record<string, boolean>)[key] === true
          ) // Use type assertion
          .join(" ");
      }
    });

    return {
      internalValue,
      handleInput,
      computedClasses,
    };
  },
});
</script>
