<template>
  <article
    class="flex flex-col grow shrink p-2 bg-white rounded-lg min-w-[7rem] max-w-[14rem] sm:min-w-[14rem] overflow-hidden"
    :class="{
      'items-center justify-center text-center': isCentered,
      'items-start text-left': !isCentered,
    }"
    :style="{
      fontFamily: fontFamily,
      fontWeight: boldness,
      fontSize: fontSize + 'px',
      height: !isGridUneven ? computedHeight : 'auto',
    }"
  >
    <div
      class="flex gap-2.5"
      :class="{
        'items-start': !isCentered,
        'justify-center items-center': isCentered,
        [profileImageAndNameLayoutClass]: true,
      }"
    >
      <img
        :src="profile.imageUrl"
        :class="profileImageClass"
        alt=""
        class="object-cover shrink-0 w-8 h-8"
      />
      <div
        class="flex flex-col"
        :class="{
          'justify-center': isCentered,
        }"
      >
        <h3
          class="text-xs font-semibold leading-loose text-zinc-800"
          :style="{ fontSize: fontSize + 'px' }"
          :class="{ 'text-center': isCentered }"
        >
          {{ expertName(profile) }}
        </h3>
      </div>
    </div>
    <div
      class="flex mb-2 pb-1 overflow-hidden pr-4"
      :class="{
        'justify-center': isCentered,
      }"
    >
      <p
        class="mt-3 text-xs leading-5 text-neutral-700 whitespace-normal break-words overflow-hidden"
        :class="{ 'text-center': isCentered, 'text-left': !isCentered }"
        :style="{ fontSize: fontSize + 'px' }"
      >
        {{ truncateText(expertDescription(profile)) }}
      </p>
    </div>

    <div
      class="flex flex-col justify-end w-full"
      :class="{
        'items-center': isCentered,
        'mt-auto': !isGridUneven,
      }"
    >
      <div class="overflow-hidden" v-if="profile.country">
        <span
          class="overflow-hidden gap-1.5 self-stretch px-1.5 py-0.5 my-auto whitespace-nowrap rounded-sm bg-slate-50"
        >
          {{ profile.country }}
        </span>
      </div>
      <div class="w-full" :class="{ 'flex justify-center': isCentered }">
        <button
          @click="handleBookMeeting(profile.uuid)"
          class="overflow-hidden gap-2 w-full py-1.5 pr-2.5 pl-2.5 mt-2.5 font-medium rounded shadow-sm"
          :disabled="!enableBookMeeting"
          :style="{
            backgroundColor: backgroundColor,
            color: textColor,
          }"
        >
          Book a Meeting
        </button>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, PropType } from "vue";
import { PostHog } from "posthog-js";

interface UserProgramProfile {
  summary: string;
}

interface Profile {
  id: string;
  uuid: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  country: string;
  userProgramProfile: UserProgramProfile[]; // An array containing user program profiles
}

export default defineComponent({
  name: "ProfileCard",
  props: {
    profile: {
      type: Object as PropType<Profile>,
      required: true,
    },
    profileImageClass: {
      type: String,
      default: "",
    },
    profileImageAndNameLayoutClass: {
      type: String,
      default: "",
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
    isGridUneven: {
      type: Boolean,
      default: false,
    },
    fontFamily: {
      type: String,
      default: "",
    },
    boldness: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "",
    },
    programUuid: {
      type: String,
      default: "",
    },
    enableBookMeeting: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const baseHeight = 220; // Base height in pixels
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    const truncateText = (text: string, maxLength = 125) => {
      if (parseInt(props.fontSize) === 14) {
        maxLength = 125;
      } else if (parseInt(props.fontSize) === 16) {
        maxLength = 100;
      } else if (parseInt(props.fontSize) >= 18) {
        maxLength = 70;
      }
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    };

    const imageUrl = (profile: Profile) => {
      return profile.imageUrl;
    };

    const expertName = (profile: Profile) => {
      return `${profile.firstName} ${profile.lastName}`;
    };

    const expertDescription = (profile: Profile) => {
      return profile.userProgramProfile[0].summary;
    };

    const handleBookMeeting = (expertId: string) => {
      if (posthog) {
        try {
          posthog.capture("expert_book_meeting_button_clicked", {
            expert_uuid: expertId,
            program_uuid: props.programUuid,
          });

          const url = `${process.env.VUE_APP_BASE_URL}/company/expert-meeting/book-meeting?expert=${expertId}&program=${props.programUuid}`;
          window.open(url, "_blank");
        } catch (error) {
          console.error("Error capturing posthog event: ", error);
        }
      } else {
        console.error("PostHog is not initialized");
      }
    };

    const computedHeight = computed(() => {
      let extraHeight = 0;
      if (props.profileImageAndNameLayoutClass === "flex-col") {
        extraHeight += 40;
      }
      if (props.fontSize === "12") {
        extraHeight += 20;
      } else if (props.fontSize === "14") {
        extraHeight += 25;
      } else if (props.fontSize === "16") {
        extraHeight += 30;
      } else if (props.fontSize === "18") {
        extraHeight += 35;
      } else if (props.fontSize === "20") {
        extraHeight += 45;
      }
      return `${baseHeight + extraHeight}px`;
    });

    return {
      truncateText,
      imageUrl,
      computedHeight,
      expertName,
      expertDescription,
      handleBookMeeting,
    };
  },
});
</script>
