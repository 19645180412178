import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2" }
const _hoisted_2 = { class: "flex flex-col sm:flex-row gap-4 justify-center items-center sm:items-start" }
const _hoisted_3 = { class: "flex flex-col w-full" }
const _hoisted_4 = { class: "flex flex-col w-full justify-center items-center relative" }
const _hoisted_5 = {
  key: 0,
  class: "absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-10"
}
const _hoisted_6 = { class: "w-full my-calendar" }
const _hoisted_7 = { class: "flex flex-col gap-1 text-left" }
const _hoisted_8 = { class: "flex wrap gap-2" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "text-gray-900 text-center font-inter text-sm font-normal leading-5" }
const _hoisted_11 = {
  key: 0,
  class: "flex flex-col w-full"
}
const _hoisted_12 = { class: "flex flex-col w-full gap-3" }
const _hoisted_13 = { class: "flex flex-col w-full" }
const _hoisted_14 = { class: "flex flex-col w-full gap-2 overflow-y-auto max-h-[40vh] overflow-x-hidden" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 1,
  class: "flex flex-col w-full text-gray-500 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VCalendar = _resolveComponent("VCalendar")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex flex-col" }, [
      _createElementVNode("p", { class: "text-gray-900 font-sans text-xl font-bold leading-7 text-left pl-4" }, " Select a Date & Time ")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
                _createElementVNode("div", { class: "w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full animate-spin" }, null, -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_VCalendar, {
              modelValue: _ctx.selectedDate,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDate) = $event)),
              modelModifiers: { string: true },
              borderless: "",
              attributes: _ctx.attributes,
              "min-date": _ctx.minimumDate,
              onDayclick: _ctx.handleDayClick,
              onDidMove: _ctx.onMonthChanged
            }, null, 8, ["modelValue", "attributes", "min-date", "onDayclick", "onDidMove"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col" }, [
            _createElementVNode("span", { class: "text-gray-900 font-inter text-base font-bold leading-6" }, "Current Timezone")
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              innerHTML: _ctx.GLOBE_ICON,
              class: "w-4 h-4 text-gray-900"
            }, null, 8, _hoisted_9),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.timeZone) + " " + _toDisplayString(_ctx.utcOffset), 1)
          ])
        ])
      ]),
      (_ctx.selectedDate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", null, _toDisplayString(_ctx.formattedDate(_ctx.selectedDate)), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                (_ctx.availableSlots.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableSlots, (slot, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "flex flex-col my-2"
                        }, [
                          (_ctx.pickedSlotIndex !== index)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createVNode(_component_BaseButton, {
                                  type: "button",
                                  class: "text-gray-700 md:px-10 lg:px-20 py-2 rounded-md border border-gray-200 w-full text-center font-sans text-base font-normal leading-6 whitespace-nowrap",
                                  onClick: ($event: any) => (_ctx.pickedSlotIndex = index)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.displayStartTime(slot.start)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]))
                            : (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(["flex flex-row justify-center gap-2 transition-transform transform", {
                    'animate-slide-in': _ctx.pickedSlotIndex === index,
                    'animate-slide-out': _ctx.pickedSlotIndex !== index,
                  }])
                              }, [
                                _createVNode(_component_BaseButton, {
                                  type: "button",
                                  disabled: "",
                                  class: "text-gray-700 bg-gray-300 text-sm px-4 py-2 w-full rounded-md border border-gray-200 font-sans text-base font-normal leading-6 whitespace-nowrap"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.displayStartTime(slot.start)), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_BaseButton, {
                                  type: "button",
                                  onClick: ($event: any) => (_ctx.next(slot.start)),
                                  class: "text-white bg-primary-purple text-sm px-4 py-2 w-full rounded-md border border-gray-200 font-sans text-base font-normal leading-6 whitespace-nowrap"
                                }, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode(" Next ")
                                  ])),
                                  _: 2
                                }, 1032, ["onClick"])
                              ], 2))
                        ]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_17, " No slots available "))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}