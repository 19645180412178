<template>
  <div class="flex flex-col gap-2 justify-start items-start">
    <div class="flex flex-col">
      <div v-html="COMPANY_LOGO"></div>
    </div>
    <div class="flex flex-col">
      <span class="text-gray-900 font-sans text-2xl font-bold leading-7"
        >Meeting with Expert</span
      >
    </div>
    <div class="flex flex-wrap gap-2">
      <div v-html="CLOCK_ICON"></div>
      <span class="text-gray-900/60 font-sans text-base font-bold leading-6"
        >30 min</span
      >
    </div>
    <div class="flex flex-wrap gap-2">
      <div v-html="PHONE_ICON"></div>
      <span class="text-gray-900/60 font-sans text-base font-bold leading-6"
        >Phone call</span
      >
    </div>
    <div class="flex flex-row gap-2" v-if="hasDateAndTime">
      <div v-html="CALENDAR_ICON"></div>
      <span class="text-gray-900/60 font-sans text-base font-bold leading-6">{{
        dateAndTime
      }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { CLOCK_ICON, PHONE_ICON } from "@/assets/svg/shared/svg-constants";
import { COMPANY_LOGO, CALENDAR_ICON } from "@/assets/svg/shared/svg-constants";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();

    const formatDate = (dateString: string): string => {
      const date = new Date(dateString);

      // Define options for the date format
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long", // Full weekday name (e.g., "Monday")
        month: "short", // Short month name (e.g., "Nov")
        day: "numeric", // Day of the month (e.g., "18")
        year: "numeric", // Full year (e.g., "2024")
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const formatTimeRange = (timeString: string): string => {
      // Create a Date object for the starting time (8:00 PM)
      const [hour, minute] = timeString.split(":").map(Number);
      const startTime = new Date();
      startTime.setHours(hour, minute, 0, 0);

      // Calculate the ending time (8:30 PM)
      const endTime = new Date(startTime.getTime() + 30 * 60000); // Add 30 minutes

      // Format both times
      const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };

      const startFormatted = new Intl.DateTimeFormat("en-US", options).format(
        startTime
      );
      const endFormatted = new Intl.DateTimeFormat("en-US", options).format(
        endTime
      );

      return `${startFormatted} - ${endFormatted}`;
    };

    const hasDateAndTime = computed(() => {
      // Check for the presence of the query param (example: "userId")
      return route.query.date && route.query.startTime;
    });

    const dateAndTime = computed(() => {
      const date = route.query.date as string;
      const time = route.query.startTime as string;
      return `${formatTimeRange(time)}, ${formatDate(date)} `;
    });

    return {
      CLOCK_ICON,
      PHONE_ICON,
      COMPANY_LOGO,
      CALENDAR_ICON,
      hasDateAndTime,
      dateAndTime,
    };
  },
});
</script>
