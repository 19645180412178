<template>
  <table class="min-w-full border border-gray-300 mb-8">
    <thead class="justify-between">
      <tr class="border-b border-gray-300">
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Name
        </th>
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Email
        </th>
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Total Payouts
        </th>
        <th
          class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Status
        </th>
        <th
          class="px-4 py-2 text-center text-gray-500 text-xs font-medium tracking-wider leading-4"
        >
          Actions
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in expertsRows"
        :key="row.id"
        class="hover:bg-gray-50 text-left"
        :class="{
          'opacity-50':
            row.active === false ||
            row.joinProgramRequest?.[0]?.status === 'rejected',
        }"
      >
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>{{ row.firstName }} {{ row.lastName }}</span>
          </div>
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>{{ row.email }}</span>
          </div>
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <div class="flex flex-col">
            <span>${{ row.credits / 100 }}</span>
          </div>
        </td>
        <td class="px-4 py-2 font-medium text-xs leading-4 border-b">
          <span
            class="px-2 rounded-lg"
            :class="{
              'text-green-800 bg-green-100':
                row.joinProgramRequest?.[0]?.status === 'approved',
              'text-red-800 bg-red-100':
                row.joinProgramRequest?.[0]?.status === 'rejected' ||
                row.active === false,
              'text-gray-800 bg-gray-100':
                row.joinProgramRequest?.[0]?.status === 'pending',
              'text-yellow-800 bg-yellow-100':
                status(row.joinProgramRequest?.[0]?.status, row.active) ===
                'Pending Submission',
            }"
            >{{ status(row.joinProgramRequest?.[0]?.status, row.active) }}</span
          >
        </td>
        <td
          class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
        >
          <div class="flex justify-center relative">
            <BaseButton
              type="button"
              class="dropdown-menu"
              style="width: auto"
              :disabled="
                deleteExpertLoading ||
                row.active === false ||
                row.joinProgramRequest?.[0]?.status === 'rejected'
              "
              @click="toggleDropdown(row.id)"
            >
              <div v-html="DOTS_VERTICAL_ICON" class="ml-3 text-gray-800"></div>
            </BaseButton>
            <div
              v-if="isDropdownOpen === row.id"
              class="absolute left-0 top-full w-32 bg-white rounded shadow-lg border border-gray-200 z-20"
            >
              <BaseButton
                type="button"
                @click="handleDelete(row.id, 'expert')"
                class="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Delete
              </BaseButton>
            </div>
          </div>
        </td>
      </tr>
      <tr
        v-for="row in invitationsRows"
        :key="row.id"
        :class="{ 'opacity-50': row.status === 'expired' }"
        class="hover:bg-gray-50 text-left"
      >
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          --
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          {{ row.userEmail }}
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          --
        </td>
        <td
          class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
        >
          <span
            class="px-2 rounded-lg"
            :class="{
              'text-green-800 bg-green-100': row.status === 'accepted',
              'text-red-800 bg-red-100': row.status === 'expired',
              'text-gray-600 bg-gray-200': row.status === 'pending',
            }"
            >{{ invitationStatus(row.status) }}</span
          >
        </td>
        <td
          class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b relative flex justify-center"
        >
          <BaseButton
            type="button"
            class="flex justify-center items-center dropdown-menu"
            style="width: auto"
            :disabled="
              expireInvitationLoading ||
              row.status === 'expired' ||
              row.status === 'accepted'
            "
            @click="toggleDropdown(row.id)"
          >
            <div v-html="DOTS_VERTICAL_ICON" class="ml-3 text-gray-800"></div>
          </BaseButton>
          <div
            v-if="isDropdownOpen === row.id"
            class="absolute left-0 top-full w-32 bg-white rounded shadow-lg border border-gray-200 z-20"
          >
            <button
              @click="handleDelete(row.id, 'invitation')"
              class="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { Expert, Invitation } from "@/types/expert-programs-types";
import { useUserStore } from "@/stores/user";
import { DOTS_VERTICAL_ICON } from "@/assets/svg/shared/svg-constants";
import BaseButton from "@/components/shared/BaseButton.vue";

const LIST_INVITED_EXPERTS_QUERY = gql`
  query listProgramExperts($programId: ID!) {
    listProgramExperts(programId: $programId) {
      experts {
        id
        firstName
        lastName
        email
        active
        credits
        joinProgramRequest(programId: $programId) {
          status
        }
      }
      invitations {
        id
        userEmail
        status
      }
    }
  }
`;

const DELETE_EXPERT_MUTATION = gql`
  mutation deleteExpert($input: DeleteExpertInput!) {
    deleteExpert(input: $input) {
      success
    }
  }
`;

const EXPIRE_INVITATION_MUTATION = gql`
  mutation expireInvitation($input: ExpireInvitationInput!) {
    expireInvitation(input: $input) {
      success
      error
    }
  }
`;

export default defineComponent({
  name: "ExpertsList",
  components: {
    BaseButton,
  },
  setup() {
    const expertsRows = ref<Expert[]>([]);
    const invitationsRows = ref<Invitation[]>([]);
    // const isAddMemberModalOpen = ref(false);
    const isDropdownOpen = ref<string | null>(null);
    const errorMessage = ref("");
    const userStore = useUserStore();
    // const {
    //   result: queryResult,
    //   error: queryError,
    //   loading: queryLoading,
    //   refetch: refetchExperts,
    // } = useQuery(LIST_INVITED_EXPERTS_QUERY, {
    //   programId: userStore.selectedProgram?.id,
    // });

    const {
      mutate: deleteExpert,
      onDone: deleteExpertDone,
      onError: deleteExpertError,
      loading: deleteExpertLoading,
    } = useMutation(DELETE_EXPERT_MUTATION);

    const {
      mutate: expireInvitation,
      onDone: expireInvitationDone,
      onError: expireInvitationError,
      loading: expireInvitationLoading,
    } = useMutation(EXPIRE_INVITATION_MUTATION);

    const invitationStatus = (status: string) => {
      console.log("status ", status);
      return status === "accepted"
        ? "Accepted"
        : status === "expired"
        ? "Expired"
        : "Pending";
    };

    const toggleDropdown = (id: string) => {
      isDropdownOpen.value = isDropdownOpen.value === id ? null : id;
    };

    const handleDelete = (id: string, type: string) => {
      console.log(`Delete clicked for item ${id} of type ${type}`);
      isDropdownOpen.value = null; // Close the dropdown after delete
      if (type === "expert") {
        handleDeleteExpert(id);
      } else {
        handleDeleteInvitation(id);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown-menu")) {
        isDropdownOpen.value = null;
      }
    };

    const handleDeleteExpert = (id: string) => {
      deleteExpert({ input: { userId: id } });

      deleteExpertDone((response) => {
        if (response) {
          console.log("deleteExpertDone ", response);
          if (response.data.deleteExpert.success) {
            fetchExperts();
          } else {
            setErrorMessage("Failed to delete expert.");
          }
        }
      });

      deleteExpertError((error: ApolloError) => {
        if (error) {
          console.log("deleteExpertError ", error);
          setErrorMessage(error.message);
        }
      });
    };

    const setErrorMessage = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 2500);
    };

    const handleDeleteInvitation = (id: string) => {
      console.log("deleteInvitation ", id);
      expireInvitation({ input: { id: id } });

      expireInvitationDone((response) => {
        if (response) {
          if (response.data.expireInvitation.success) {
            // refetchExperts();
            fetchExperts();
          } else {
            setErrorMessage(response.data.expireInvitation.error);
          }
        }
      });

      expireInvitationError((error: ApolloError) => {
        // console.log("expireInvitationError ", error);
        if (error) {
          setErrorMessage(error.message);
        }
      });
    };

    const fetchExperts = () => {
      // console.log("fetchExperts");
      const { onResult: onResultExperts, onError: onErrorExperts } = useQuery(
        LIST_INVITED_EXPERTS_QUERY,
        {
          programId: userStore.selectedProgram?.id,
        },
        { fetchPolicy: "network-only" }
      );

      onResultExperts((result) => {
        if (result.data) {
          console.log("result.data ", result.data);
          expertsRows.value = result.data.listProgramExperts.experts;
          invitationsRows.value = result.data.listProgramExperts.invitations;
        }
      });

      onErrorExperts((error) => {
        if (error) {
          setErrorMessage(error.message);
          console.log("onErrorExperts ", error);
        }
      });
    };

    const status = (status: string, active: boolean) => {
      if (!active) {
        return "Inactive";
      }
      // console.log("status ", status);
      if (status === "approved") {
        return "Active";
      } else if (status === "rejected") {
        return "Rejected";
      } else if (status === "pending") {
        return "Pending";
      } else {
        return "Pending Submission";
      }
    };

    const refreshExperts = () => {
      console.log("refreshExperts from experts list");
      fetchExperts();
    };

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    onMounted(() => {
      // console.log("onMounted");
      document.addEventListener("click", handleClickOutside);
      fetchExperts();
    });

    return {
      expertsRows,
      invitationsRows,
      errorMessage,
      deleteExpertLoading,
      expireInvitationLoading,
      toggleDropdown,
      handleDelete,
      invitationStatus,
      DOTS_VERTICAL_ICON,
      isDropdownOpen,
      status,
      refreshExperts,
    };
  },
});
</script>
