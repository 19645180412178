import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AddProgramDetails from "../views/expert-program/AddProgramDetailsView.vue";
import Dashboard from "../views/dashboard/DashboardView.vue";
import SignUp from "../views/SignUpView.vue";
import AddAccountBalance from "../views/expert-program/AddAccountBalanceView.vue";
import AddDirectory from "../views/expert-program/AddDirectoryView.vue";
import ExpertsDirectory from "../views/expert-program/ExpertsDirectoryView.vue";
import AddWidget from "../views/expert-program/AddWidgetView.vue";
import AddIntegration from "../views/expert-program/AddIntegrationView.vue";
import AddSalesforceIntegration from "../views/expert-program/AddSalesforceIntegrationView.vue";
import InviteExperts from "../views/expert-program/InviteExpertsView.vue";
import BillingPage from "../views/dashboard/BillingPageView.vue";
import ExpertPrograms from "../views/dashboard/ExpertProgramsView.vue";
import SignInLanding from "../views/SignInLandingView.vue";
import BookMeeting from "../views/expert-meeting/BookMeetingView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/invite/company",
    redirect: "/company/signup",
  },
  {
    path: "/company/signup",
    name: "CompanySignUp",
    component: SignUp,
  },
  {
    path: "/company/dashboard",
    name: "CompanyDashboard",
    component: Dashboard,
  },
  {
    path: "/company/expert-program/add-program-details",
    name: "AddProgramDetails",
    component: AddProgramDetails,
  },
  {
    path: "/company/expert-program/add-account-balance",
    name: "AddAccountBalance",
    component: AddAccountBalance,
  },
  {
    path: "/company/expert-program/add-directory",
    name: "AddDirectory",
    component: AddDirectory,
  },
  {
    path: "/experts_directory",
    name: "ExpertsDirectory",
    component: ExpertsDirectory,
  },
  {
    path: "/company/expert-program/add-widget",
    name: "AddWidget",
    component: AddWidget,
  },
  {
    path: "/company/expert-program/add-integration",
    name: "AddIntegration",
    component: AddIntegration,
  },
  {
    path: "/company/integration/salesforce",
    name: "AddSalesforceIntegration",
    component: AddSalesforceIntegration,
  },
  {
    path: "/company/expert-program/invite-experts",
    name: "InviteExperts",
    component: InviteExperts,
  },
  {
    path: "/company/billing-page",
    name: "BillingPage",
    component: BillingPage,
  },
  {
    path: "/company/programs",
    name: "ExpertPrograms",
    component: ExpertPrograms,
  },
  {
    path: "/company/signin/landing",
    name: "SignInLanding",
    component: SignInLanding,
  },
  {
    path: "/company/expert-meeting/book-meeting",
    name: "BookMeeting",
    component: BookMeeting,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
