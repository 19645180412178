import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center self-center mt-11 max-md:mt-10" }
const _hoisted_2 = { class: "flex gap-0.5 items-center self-stretch my-auto" }
const _hoisted_3 = { class: "flex flex-col justify-center self-stretch p-0.5 my-auto w-5" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex gap-3 items-center self-stretch my-auto rounded-xl" }
const _hoisted_8 = { class: "flex flex-col justify-center self-stretch px-1 py-1.5 my-auto w-5" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass({
          'flex gap-3 items-center self-stretch my-auto rounded-xl': true,
          'text-indigo-600': _ctx.currentStep === 1,
          'text-gray-500': _ctx.currentStep === 2,
        })
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.currentStep === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "flex flex-col justify-center items-center px-0.5 w-4 h-4 rounded-full bg-indigo-200" }, [
                  _createElementVNode("div", { class: "flex shrink-0 w-2 h-2 rounded-full bg-indigo-600" })
                ], -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", {
                  innerHTML: _ctx.CHECK_CIRCLE,
                  class: "text-violet-600"
                }, null, 8, _hoisted_6)
              ]))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass({
            'self-stretch my-auto text-sm font-medium leading-none': true,
            'text-indigo-600': _ctx.currentStep === 1,
            'text-gray-500': _ctx.currentStep === 2,
          })
        }, _toDisplayString(_ctx.firstStepText), 3)
      ], 2)
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "hidden sm:block w-16 h-px bg-gray-300 mx-2" }, null, -1)),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.currentStep === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "flex shrink-0 w-2 h-2 rounded-full bg-gray-300" }, null, -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "flex flex-col justify-center items-center px-0.5 w-4 h-4 rounded-full bg-indigo-200" }, [
                _createElementVNode("div", { class: "flex shrink-0 w-2 h-2 rounded-full bg-indigo-600" })
              ], -1)
            ])))
      ]),
      _createElementVNode("div", {
        class: _normalizeClass({
          'self-stretch my-auto text-sm font-medium leading-none': true,
          'text-indigo-600': _ctx.currentStep === 2,
          'text-gray-500': _ctx.currentStep < 2,
        })
      }, " Get Code ", 2)
    ])
  ]))
}