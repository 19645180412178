<template>
  <footer
    class="flex flex-wrap gap-7 justify-center mt-14 items-center self-stretch py-4 w-full text-base font-medium whitespace-nowrap border-t border-gray-300 border-solid max-md:max-w-full"
  >
    <div class="flex w-1/2 gap-4 justify-center items-center">
      <BaseButton
        type="button"
        class="flex overflow-hidden w-1/4 flex-wrap gap-3 justify-center items-center self-stretch py-2.5 pr-4 pl-4 my-auto text-white bg-violet-600 rounded-md shadow-sm max-md:max-w-full"
        @click="next"
        :disabled="isDisabled"
      >
        <span v-if="!isDisabled" class="self-stretch my-auto">
          Next
          <i class="fas fa-arrow-right shrink-0 w-5 aspect-square"></i>
        </span>
        <span v-else class="self-stretch my-auto">Submitting.....</span>
      </BaseButton>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "FooterSection",
  components: {
    BaseButton,
  },
  props: {
    isDisabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    backButtonVisible: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  emits: ["back", "next"],
  setup(_, { emit }) {
    const next = () => {
      emit("next");
    };

    return {
      next,
    };
  },
});
</script>
