import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-7 justify-center mt-14 items-center self-stretch py-4 w-full text-base font-medium whitespace-nowrap border-t border-gray-300 border-solid max-md:max-w-full" }
const _hoisted_2 = { class: "flex w-1/2 gap-4 justify-center items-center" }
const _hoisted_3 = {
  key: 0,
  class: "self-stretch my-auto"
}
const _hoisted_4 = {
  key: 1,
  class: "self-stretch my-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseButton, {
        type: "button",
        class: "flex overflow-hidden w-1/4 flex-wrap gap-3 justify-center items-center self-stretch py-2.5 pr-4 pl-4 my-auto text-white bg-violet-600 rounded-md shadow-sm max-md:max-w-full",
        onClick: _ctx.next,
        disabled: _ctx.isDisabled
      }, {
        default: _withCtx(() => [
          (!_ctx.isDisabled)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _cache[0] || (_cache[0] = [
                _createTextVNode(" Next "),
                _createElementVNode("i", { class: "fas fa-arrow-right shrink-0 w-5 aspect-square" }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, "Submitting....."))
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ])
  ]))
}