import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../../../assets/shared/failure.svg'


const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 flex justify-end bg-black bg-opacity-50 z-50"
}
const _hoisted_2 = { class: "bg-white w-1/3 h-full p-6 shadow-lg transform transition-transform ease-in-out duration-300" }
const _hoisted_3 = { class: "flex flex-col justify-between h-full" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "flex justify-end" }
const _hoisted_6 = { class: "flex justify-center mb-4" }
const _hoisted_7 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_8 = { class: "text-sm text-left" }
const _hoisted_9 = { class: "flex flex-col gap-2 text-left font-inter" }
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = { class: "text-2xl font-bold" }
const _hoisted_12 = { class: "text-sm text-gray-500" }
const _hoisted_13 = { class: "flex flex-col border-b border-gray-200 pb-4" }
const _hoisted_14 = { class: "text-sm" }
const _hoisted_15 = { class: "flex flex-col items-start w-full p-4" }
const _hoisted_16 = { class: "relative" }
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  key: 0,
  class: "absolute inset-0 flex items-center justify-center bg-gray-800 text-white text-lg font-semibold z-10"
}
const _hoisted_19 = { class: "flex justify-end gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!

  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_BaseButton, {
                  type: "button",
                  onClick: _ctx.closeModal,
                  class: "mb-4 text-gray-500 justify-end hover:text-gray-700"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" X ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.errorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: "Error Icon",
                          class: "w-4 h-4"
                        })
                      ], -1)),
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.errorMessage), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.expert.firstName) + " " + _toDisplayString(_ctx.expert.lastName), 1),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.expert.email), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "summary",
                    text: "Summary",
                    class: "text-black text-sm font-medium leading-5 text-left"
                  }),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.summary), 1)
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-black text-sm font-medium leading-5 text-left" }, " Uploaded Video ", -1)),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    (_openBlock(), _createElementBlock("video", {
                      ref: "video",
                      class: "w-full h-auto border border-gray-300",
                      controls: "",
                      type: "video/mp4",
                      onCanplay: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCanPlay && _ctx.handleCanPlay(...args))),
                      onLoadstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleLoadStart && _ctx.handleLoadStart(...args))),
                      key: _ctx.introductionVideo,
                      src: _ctx.introductionVideo || ''
                    }, null, 40, _hoisted_17)),
                    (_ctx.isVideoLoading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, " Loading video... "))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_BaseButton, {
                type: "button",
                onClick: _ctx.handleReject,
                class: "bg-white text-black rounded-md border border-gray-300 px-4 py-2"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Reject ")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_BaseButton, {
                type: "button",
                onClick: _ctx.handleAccept,
                class: "bg-primary-purple text-white rounded-md px-4 py-2"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Approve ")
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}