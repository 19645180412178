<template>
  <div class="flex flex-wrap justify-between p-4">
    <div class="flex flex-col text-left">
      <span class="text-gray-900 font-inter text-base font-medium leading-6"
        >Widgets</span
      >
      <span class="text-gray-500 font-inter text-sm font-normal leading-8"
        >You can update the widget components</span
      >
    </div>
    <div class="flex flex-col" v-if="!showEditControls">
      <BaseButton
        type="button"
        class="gap-2 text-primary-purple font-inter text-base font-medium leading-6"
        @click="handleEditWidget"
      >
        <div class="flex flex-wrap gap-2">
          <div v-html="EDIT_ICON"></div>
          Edit
        </div></BaseButton
      >
    </div>
    <div v-else class="flex flex-col">
      <div class="flex flex-wrap gap-2 items-center">
        <span v-if="updateWidgetLoading" class="text-primary-purple"
          >Updating...</span
        >
        <BaseButton
          type="button"
          class="text-black border p-2 rounded-lg border-gray-300 font-inter text-base font-medium leading-6"
          @click="cancelEditWidget"
          :disabled="updateWidgetLoading"
        >
          Cancel
        </BaseButton>
        <BaseButton
          type="button"
          class="bg-primary-purple text-white p-2 rounded-lg font-inter text-base font-medium leading-6"
          @click="handleUpdateWidget"
          :disabled="updateWidgetLoading"
        >
          Update
        </BaseButton>
      </div>
    </div>
  </div>
  <div class="flex justify-center mb-4">
    <div
      v-if="errorMessage"
      role="alert"
      class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      style="max-height: 80px; overflow-y: auto"
    >
      <!-- Icon Container -->
      <div
        class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
      >
        <!-- Custom Image Icon -->
        <img
          src="../../../../assets/shared/failure.svg"
          alt="Error Icon"
          class="w-4 h-4"
        />
      </div>
      <p>{{ errorMessage }}</p>
    </div>
  </div>
  <div class="flex gap-5 max-md:flex-col">
    <section
      class="flex flex-col mx-auto bg-gray-200 rounded-lg"
      :style="{
        height: !showEditControls ? widgetPreviewHeight + 'px' : 'auto',
      }"
      :class="{
        'w-[63%]': showEditControls,
        'w-full': !showEditControls,
      }"
    >
      <div v-if="topHeaderBarVisible" class="flex flex-col mt-2">
        <TopHeaderBar
          :topheaderText="topheaderText"
          :buttonText="buttonText"
          :buttonTextColor="buttonTextColor"
          :fontSize="fontSize"
          :fontFamily="fontFamily"
          :fontBoldness="boldness"
          :headerBackgroundColor="headerBackgroundColor"
          :headerTextColor="headerTextColor"
          :buttonBackgrounColor="headerButtonBackgroundColor"
          :expertsDirectoryUrl="topHeaderExpertsDirectoryUrl"
          :disableCloseButton="false"
        />
      </div>

      <div
        v-if="popupModalVisible"
        class="flex flex-col"
        :class="{
          'items-end': !popupModalLeftOrientation,
          'items-start': popupModalLeftOrientation,
          'mt-auto': topHeaderBarVisible,
        }"
      >
        <PopupModal
          :fontFamily="popupModalfontFamily"
          :fontSize="popupModalfontSize"
          :fontBoldness="popupModalBoldness"
          :expertsDirectoryUrl="popupModalExpertsDirectoryUrl"
          :disableCloseButton="false"
          :buttonTextColor="popupModalButtonTextColor"
          :headingText="popupModalHeadingText"
          :buttonText="popupModalButtonText"
          :bodyText="popupModalBodyText"
          :buttonBackgroundColor="popupModalButtonBackgroundColor"
          :textColor="popupModalTextColor"
          :bannerBackgroundColor="popupModalBannerBackgroundColor"
        />
      </div>
    </section>
    <section
      v-if="showEditControls"
      class="flex flex-col h-full ml-5 w-[37%] max-md:ml-0 max-md:w-full"
    >
      <div class="flex flex-col max-md:mt-8">
        <div class="flex flex-col">
          <TopHeaderBarDesign
            :isVisible="topHeaderBarVisible"
            :fontFamily="fontFamily"
            :fontSize="fontSize"
            :buttonTextColor="buttonTextColor"
            :boldness="boldness"
            :topHeaderBackgroundColor="headerBackgroundColor"
            :topHeaderbuttonBackgroundColor="headerButtonBackgroundColor"
            :topHeaderTextColor="headerTextColor"
            :headerText="topheaderText"
            :buttonText="buttonText"
            :topHeaderExpertsDirectoryUrl="topHeaderExpertsDirectoryUrl"
            @expertsDirectoryUrlChanged="
              handleTopHeaderExpertsDirectoryUrlChange
            "
            @toggleVisibility="handleTopHeaderBarVisibilityChange"
            @buttonTextChanged="handleButtonTextChange"
            @fontFamilyChanged="handleFontChange"
            @boldnessChanged="handleBoldnessChange"
            @buttonTextColorChanged="handleButtonTextColorChange"
            @headerBackgroundColorChanged="handleHeaderBackgroundColorChange"
            @buttonBackgroundColorChanged="handleButtonBackgroundColorChange"
            @headerTextColorChanged="handleHeaderTextColorChange"
            @fontSizeChanged="handleFontSizeChange"
            @headerTextChanged="handleHeaderTextChange"
          />

          <PopupModalDesign
            :isVisible="popupModalVisible"
            :fontFamily="popupModalfontFamily"
            :fontSize="popupModalfontSize"
            :boldness="popupModalBoldness"
            :popupButtonTextColor="popupModalButtonTextColor"
            :popupHeadingText="popupModalHeadingText"
            :popupButtonText="popupModalButtonText"
            :popupBodyText="popupModalBodyText"
            :popupExpertsDirectoryUrl="popupModalExpertsDirectoryUrl"
            :popupLeftOrientation="popupModalLeftOrientation"
            :popupButtonBackgroundColor="popupModalButtonBackgroundColor"
            :popupTextColor="popupModalTextColor"
            :popupBannerBackgroundColor="popupModalBannerBackgroundColor"
            @toggleVisibility="handlePopupModalVisibilityChange"
            @fontFamilyChanged="handlePopupModalFontChange"
            @boldnessChanged="handlePopupModalBoldnessChange"
            @fontSizeChanged="handlePopupModalFontSizeChange"
            @leftOrientationChanged="handlePopupModalLeftOrientationChange"
            @buttonTextColorChanged="handlePopupModalButtonTextColorChange"
            @buttonTextChanged="handlePopupModalButtonTextChange"
            @headingTextChanged="handlePopupModalHeadingTextChange"
            @bodyTextChanged="handlePopupModalBodyTextChange"
            @expertsDirectoryUrlChanged="
              handlePopupModalExpertsDirectoryUrlChange
            "
            @buttonBackgroundColorChanged="
              handlePopupModalButtonBackgroundColorChange
            "
            @textColorChanged="handlePopupModalTextColorChanged"
            @bannerBackgroundColorChanged="
              handlePopupModalBannerBackgroundColorChange
            "
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { defineComponent, onMounted, ref, watch } from "vue";
import { WidgetConfig } from "@/types/dashboard-types";
import { googleFontFamiliesLink } from "@/data/expert-program/font-families";
import TopHeaderBar from "@/components/expert-program/widget/TopHeaderBar.vue";
import PopupModal from "@/components/expert-program/widget/PopupModal.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { EDIT_ICON } from "@/assets/svg/shared/svg-constants";
import TopHeaderBarDesign from "@/components/expert-program/widget/TopHeaderBarDesign.vue";
import PopupModalDesign from "@/components/expert-program/widget/PopupModalDesign.vue";
import { useUserStore } from "@/stores/user";
// import TopHeaderBar from "./TopHeaderBar.vue";
// import PopupModal from "./PopupModal.vue";
const GET_WIDGET_DATA = gql`
  query GetProgramWidgetData($programId: ID!) {
    getProgramWidgetDetail(programId: $programId) {
      id
      errors
      widgetCosmetic {
        headerBar
        headerText
        buttonText
        buttonColor
        popUp
        headingText
        bodyText
        fontFamily
        fontSize
        textColor
        backgroundColor
        popUpOrientationLeft
        bold
        expertsDirectoryUrl
        buttonTextColor
      }
    }
  }
`;

const UPDATE_WIDGET = gql`
  mutation updateWidget($input: UpdateWidgetInput!) {
    updateWidget(input: $input) {
      widgetIds
      success
      errors
    }
  }
`;

export default defineComponent({
  name: "ViewWidget",
  components: {
    TopHeaderBar,
    PopupModal,
    BaseButton,
    TopHeaderBarDesign,
    PopupModalDesign,
  },

  mounted() {
    const link = document.createElement("link");
    link.href = googleFontFamiliesLink;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  },

  emits: ["hideDirectory"],
  setup(_, { emit }) {
    const userStore = useUserStore();
    const widgetData = ref<WidgetConfig>();
    const topHeaderBarId = ref("");
    const popupModalId = ref("");
    const topHeaderBarWidgetData = ref<WidgetConfig>();
    const popupModalWidgetData = ref<WidgetConfig>();
    const showEditControls = ref(false);
    const errorMessage = ref("");
    const topHeaderBarVisible = ref(true);
    const popupModalVisible = ref(true);
    const fontFamily = ref("Oswald");
    const fontSize = ref("14");
    const boldness = ref("400");
    const buttonTextColor = ref("#121111");
    const headerBackgroundColor = ref("#121111");
    const headerButtonBackgroundColor = ref("#FFFFFF");
    const headerTextColor = ref("#FFFFFF");
    const topheaderText = ref("Directory");
    const buttonText = ref("Button");
    const topHeaderExpertsDirectoryUrl = ref(
      `https://${userStore.companyWebsite}/experts`
    );
    const popupModalExpertsDirectoryUrl = ref(
      `https://${userStore.companyWebsite}/experts`
    );
    const popupModalfontFamily = ref("Oswald");
    const popupModalfontSize = ref("14");
    const popupModalBoldness = ref("400");
    const popupModalButtonTextColor = ref("#121111");
    const popupModalHeadingText = ref("Headin");
    const popupModalButtonText = ref("Button");
    const popupModalBodyText = ref("Body");
    const popupModalLeftOrientation = ref(false);
    const popupModalButtonBackgroundColor = ref("#FFFFFF");
    const popupModalTextColor = ref("#FFFFFF");
    const popupModalBannerBackgroundColor = ref("#6a48f3");
    const widgetPreviewHeight = ref(0);

    const {
      result: widgetResult,
      error: widgetError,
      refetch: refetchWidgetData,
    } = useQuery(GET_WIDGET_DATA, {
      programId: userStore.selectedProgram?.id,
    });

    const {
      mutate: updateWidget,
      onDone: updateWidgetDone,
      onError: updateWidgetError,
      loading: updateWidgetLoading,
    } = useMutation(UPDATE_WIDGET);

    const handleEditWidget = () => {
      showEditControls.value = true;
      emit("hideDirectory", true);
    };

    const cancelEditWidget = () => {
      showEditControls.value = false;
      emit("hideDirectory", false);
    };

    const handleTopHeaderExpertsDirectoryUrlChange = (value: string) => {
      console.log("bew website url ", value);
      topHeaderExpertsDirectoryUrl.value = value;
    };

    const handleTopHeaderBarVisibilityChange = (value: boolean) => {
      topHeaderBarVisible.value = value;
    };

    const handleButtonTextChange = (value: string) => {
      buttonText.value = value;
    };

    const handleFontChange = (value: string) => {
      fontFamily.value = value;
    };

    const handleBoldnessChange = (value: string) => {
      boldness.value = value;
    };

    const handleButtonTextColorChange = (value: string) => {
      buttonTextColor.value = value;
    };

    const handleHeaderBackgroundColorChange = (value: string) => {
      headerBackgroundColor.value = value;
    };

    const handleButtonBackgroundColorChange = (value: string) => {
      headerButtonBackgroundColor.value = value;
    };

    const handleHeaderTextColorChange = (value: string) => {
      headerTextColor.value = value;
    };

    const handleFontSizeChange = (value: string) => {
      fontSize.value = value;
    };

    const handleHeaderTextChange = (value: string) => {
      topheaderText.value = value;
    };

    const handlePopupModalVisibilityChange = (value: boolean) => {
      popupModalVisible.value = value;
    };

    const handlePopupModalFontChange = (value: string) => {
      popupModalfontFamily.value = value;
    };

    const handlePopupModalBoldnessChange = (value: string) => {
      popupModalBoldness.value = value;
    };

    const handlePopupModalFontSizeChange = (value: string) => {
      popupModalfontSize.value = value;
    };

    const handlePopupModalLeftOrientationChange = (value: boolean) => {
      popupModalLeftOrientation.value = value;
    };

    const handlePopupModalButtonTextColorChange = (value: string) => {
      popupModalButtonTextColor.value = value;
    };

    const handlePopupModalButtonTextChange = (value: string) => {
      popupModalButtonText.value = value;
    };

    const handlePopupModalHeadingTextChange = (value: string) => {
      popupModalHeadingText.value = value;
    };

    const handlePopupModalBodyTextChange = (value: string) => {
      popupModalBodyText.value = value;
    };

    const handlePopupModalExpertsDirectoryUrlChange = (value: string) => {
      popupModalExpertsDirectoryUrl.value = value;
    };

    const handlePopupModalButtonBackgroundColorChange = (value: string) => {
      popupModalButtonBackgroundColor.value = value;
    };

    const handlePopupModalTextColorChanged = (value: string) => {
      popupModalTextColor.value = value;
    };

    const handlePopupModalBannerBackgroundColorChange = (value: string) => {
      popupModalBannerBackgroundColor.value = value;
    };

    const updateTopHeaderBarWidgetData = (widgetData: WidgetConfig) => {
      // console.log(
      //   "widget data on update top header bar ",
      //   widgetData.headerBar
      // );
      // topHeaderBarWidgetData.value = widgetData;
      topHeaderBarVisible.value = widgetData?.headerBar ?? true;
      fontFamily.value = widgetData.fontFamily || "Oswald";
      fontSize.value = widgetData.fontSize || "14";
      boldness.value = widgetData.bold || "400";
      buttonTextColor.value = widgetData.buttonTextColor || "#121111";
      headerBackgroundColor.value = widgetData.backgroundColor || "#121111";
      headerButtonBackgroundColor.value = widgetData.buttonColor || "#FFFFFF";
      headerTextColor.value = widgetData.textColor || "#FFFFFF";
      topheaderText.value = widgetData.headerText || "Directory";
      buttonText.value = widgetData.buttonText || "Button";
      topHeaderExpertsDirectoryUrl.value = widgetData.expertsDirectoryUrl || "";
    };

    const updatePopupModalWidgetData = (widgetData: WidgetConfig) => {
      // popupModalWidgetData.value = widgetData;
      popupModalVisible.value = widgetData.popUp ?? true;
      popupModalfontFamily.value = widgetData.fontFamily || "Oswald";
      popupModalfontSize.value = widgetData.fontSize || "14";
      popupModalBoldness.value = widgetData.bold || "400";
      popupModalButtonTextColor.value = widgetData.buttonTextColor || "#121111";
      popupModalHeadingText.value = widgetData.headingText || "";
      popupModalButtonText.value = widgetData.buttonText || "";
      popupModalBodyText.value = widgetData.bodyText || "";
      popupModalExpertsDirectoryUrl.value =
        widgetData.expertsDirectoryUrl || "";
      popupModalLeftOrientation.value =
        widgetData.popUpOrientationLeft || false;
      popupModalButtonBackgroundColor.value =
        widgetData.buttonColor || "#121111";
      popupModalTextColor.value = widgetData.textColor || "#FFFFFF";
      popupModalBannerBackgroundColor.value =
        widgetData.backgroundColor || "#121111";
    };

    const handleUpdateWidget = () => {
      // console.log("i am updating widget");
      if (topHeaderBarVisible.value || popupModalVisible.value) {
        updateWidgetData();
      } else {
        handleError("Please select at least one widget");
      }
    };

    const prepareTopHeaderBarPayload = (widgetParams: WidgetConfig[]) => {
      widgetParams.push({
        id: topHeaderBarId.value,
        programId: userStore.selectedProgram?.id,
        headerBar: topHeaderBarVisible.value,
        headerText: topheaderText.value,
        buttonText: buttonText.value,
        buttonTextColor: buttonTextColor.value,
        buttonColor: headerButtonBackgroundColor.value,
        style: "top header bar",
        fontFamily: fontFamily.value,
        fontSize: fontSize.value,
        textColor: headerTextColor.value,
        backgroundColor: headerBackgroundColor.value,
        bold: boldness.value,
        expertsDirectoryUrl: topHeaderExpertsDirectoryUrl.value,
      });
    };

    const preparePopupModalPayload = (widgetParams: WidgetConfig[]) => {
      widgetParams.push({
        id: popupModalId.value,
        programId: userStore.selectedProgram?.id,
        popUp: popupModalVisible.value,
        headingText: popupModalHeadingText.value,
        bodyText: popupModalBodyText.value,
        style: "popup modal",
        fontFamily: popupModalfontFamily.value,
        fontSize: popupModalfontSize.value,
        textColor: popupModalTextColor.value,
        buttonTextColor: popupModalButtonTextColor.value,
        buttonColor: popupModalButtonBackgroundColor.value,
        backgroundColor: popupModalBannerBackgroundColor.value,
        buttonText: popupModalButtonText.value,
        bold: popupModalBoldness.value,
        expertsDirectoryUrl: popupModalExpertsDirectoryUrl.value,
        popUpOrientationLeft: popupModalLeftOrientation.value,
      });
    };

    const updateWidgetData = () => {
      console.log("i am updating widget data");
      let widgetParams: WidgetConfig[] = [];

      if (topHeaderBarVisible.value || topHeaderBarId.value) {
        prepareTopHeaderBarPayload(widgetParams);
      }

      if (popupModalVisible.value || popupModalId.value) {
        preparePopupModalPayload(widgetParams);
      }

      console.log("widgetParams ", widgetParams);
      console.log("topHeaderBarId ", topHeaderBarId.value);
      // return;

      updateWidget({
        input: {
          widgetParams,
        },
      });

      updateWidgetDone((response) => {
        if (response) {
          if (response.data.updateWidget.errors.length > 0) {
            handleError(response.data.updateWidget.errors.join(", "));
          } else {
            hideEditControls();
            refetchWidgetData();
          }
        }
      });

      updateWidgetError((error) => {
        console.log("error ", error);
        if (error) {
          handleError(error.message);
        }
      });
    };

    const handleError = (message: string) => {
      setTimeout(() => {
        errorMessage.value = message;
      }, 2500);
    };

    const hideEditControls = () => {
      showEditControls.value = false;
      emit("hideDirectory", false);
    };

    const handleResize = () => {
      const directoryPreview = document.querySelector(
        "#directoryPreview"
      ) as HTMLElement;
      const height = directoryPreview?.clientHeight;
      widgetPreviewHeight.value = height;
      console.log("height ", height);
    };

    watch(
      widgetResult,
      (newVal) => {
        if (newVal) {
          // console.log("widgetCosmetic ", newVal);
          handleResize();
          if (newVal?.getProgramWidgetDetail) {
            console.log("widget data on page load query 1 ", newVal);
            // console.log("i am true");
            for (const detail of newVal.getProgramWidgetDetail) {
              const widgetData = detail?.widgetCosmetic;
              if (widgetData) {
                console.log("widget data on page load query  2", widgetData);
                if (
                  widgetData.headerBar === true ||
                  widgetData.headerBar === false
                ) {
                  topHeaderBarId.value = detail?.id;
                  // topHeaderBarWidgetData.value = widgetData;
                  updateTopHeaderBarWidgetData(widgetData);
                } else if (
                  widgetData.popUp === true ||
                  widgetData.popUp === false
                ) {
                  popupModalId.value = detail?.id;
                  popupModalWidgetData.value = widgetData;
                  updatePopupModalWidgetData(widgetData);
                }
              }
            }
            if (!topHeaderBarId.value) {
              topHeaderBarVisible.value = false;
            }
            if (!popupModalId.value) {
              popupModalVisible.value = false;
            }
          }
        }
      },
      { immediate: true }
    );

    watch(
      widgetError,
      (newVal) => {
        if (newVal) {
          handleResize();
          if (newVal.message.includes("No widget settings found.")) {
            topHeaderBarVisible.value = false;
            popupModalVisible.value = false;
          } else {
            handleError(newVal.message);
          }
        }
      },
      { immediate: true }
    );

    // watch(loading)
    // console.log("widgetData ", widgetData);

    onMounted(() => {
      setTimeout(() => {
        handleResize();
      }, 300);

      window.addEventListener("resize", () => {
        handleResize();
      });
    });

    return {
      cancelEditWidget,
      widgetData,
      topHeaderBarWidgetData,
      popupModalWidgetData,
      handleEditWidget,
      showEditControls,
      handleUpdateWidget,
      EDIT_ICON,
      errorMessage,
      updateWidgetLoading,
      widgetPreviewHeight,
      topHeaderBarVisible,
      popupModalVisible,
      fontFamily,
      fontSize,
      boldness,
      buttonTextColor,
      headerBackgroundColor,
      headerButtonBackgroundColor,
      headerTextColor,
      topheaderText,
      buttonText,
      topHeaderExpertsDirectoryUrl,
      popupModalfontFamily,
      popupModalfontSize,
      popupModalBoldness,
      popupModalButtonTextColor,
      popupModalExpertsDirectoryUrl,
      popupModalHeadingText,
      popupModalButtonText,
      popupModalBodyText,
      popupModalLeftOrientation,
      popupModalButtonBackgroundColor,
      popupModalTextColor,
      popupModalBannerBackgroundColor,
      handleTopHeaderExpertsDirectoryUrlChange,
      handleTopHeaderBarVisibilityChange,
      handleButtonTextChange,
      handleFontChange,
      handleBoldnessChange,
      handleButtonTextColorChange,
      handleHeaderBackgroundColorChange,
      handleButtonBackgroundColorChange,
      handleHeaderTextColorChange,
      handleFontSizeChange,
      handleHeaderTextChange,
      handlePopupModalVisibilityChange,
      handlePopupModalFontChange,
      handlePopupModalBoldnessChange,
      handlePopupModalFontSizeChange,
      handlePopupModalLeftOrientationChange,
      handlePopupModalButtonTextColorChange,
      handlePopupModalButtonTextChange,
      handlePopupModalHeadingTextChange,
      handlePopupModalBodyTextChange,
      handlePopupModalExpertsDirectoryUrlChange,
      handlePopupModalButtonBackgroundColorChange,
      handlePopupModalTextColorChanged,
      handlePopupModalBannerBackgroundColorChange,
    };
  },
});
</script>
