export function getCurrentTimeZoneInfo(): {
  timeZone: string;
  utcOffset: string;
} {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const offset = -new Date().getTimezoneOffset() / 60;
  const utcOffset = `UTC${offset >= 0 ? "+" : ""}${offset
    .toString()
    .padStart(2, "0")}:00`;

  return { timeZone, utcOffset };
}
