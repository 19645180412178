import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 justify-between p-4 w-full max-h-[60vh] overflow-y-auto lg:w-[40vw] max-w-[500px]" }
const _hoisted_2 = { class: "flex flex-col gap-6" }
const _hoisted_3 = { class: "flex flex-wrap gap-2" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex flex-wrap gap-2" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex flex-row gap-2 text-left" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "text-gray-900/60 font-sans text-base font-bold leading-6" }
const _hoisted_10 = { class: "flex wrap gap-2" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "text-gray-900 text-center font-inter text-sm font-normal leading-5" }
const _hoisted_13 = { class: "flex flex-col gap-2 text-left" }
const _hoisted_14 = { class: "text-primary-purple max-w-max bg-indigo-50 px-4 py-1 rounded-md font-sans text-base font-bold leading-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col justify-center items-center" }, [
      _createElementVNode("span", { class: "text-gray-900 font-sans text-2xl font-bold leading-7" }, "Meeting confirmed")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", { innerHTML: _ctx.CLOCK_ICON }, null, 8, _hoisted_4),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-gray-900/60 font-sans text-base font-bold leading-6" }, "30 min", -1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", { innerHTML: _ctx.PHONE_ICON }, null, 8, _hoisted_6),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-gray-900/60 font-sans text-base font-bold leading-6" }, "Phone call", -1))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", { innerHTML: _ctx.CALENDAR_ICON }, null, 8, _hoisted_8),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.dateAndTime), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", {
          innerHTML: _ctx.GLOBE_ICON,
          class: "w-4 h-4 text-gray-900"
        }, null, 8, _hoisted_11),
        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.timeZone), 1)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("span", _hoisted_14, "Meeting Passcode - " + _toDisplayString(_ctx.meetingCode), 1)
      ])
    ])
  ]))
}