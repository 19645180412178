import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center border border-gray-300 rounded-md w-full overflow-hidden" }
const _hoisted_2 = { class: "pl-3 pr-1 text-gray-500 whitespace-nowrap" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "pr-3 text-gray-500 whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.leadingText), 1),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      placeholder: _ctx.placeholder,
      class: "flex-1 pl-1 pr-3 py-2 outline-none text-gray-500 min-w-0",
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.formatValue && _ctx.formatValue(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitValue && _ctx.emitValue(...args)))
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.inputValue]
    ]),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.trailingText), 1)
  ]))
}