import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "min-w-full border border-gray-300 mb-8" }
const _hoisted_2 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }
const _hoisted_7 = { class: "px-2 rounded-lg" }
const _hoisted_8 = { class: "flex justify-end px-4 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ReviewEntryModal = _resolveComponent("ReviewEntryModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("table", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("thead", { class: "justify-between" }, [
        _createElementVNode("tr", { class: "border-b border-gray-300" }, [
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Name "),
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Email "),
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Requested At "),
          _createElementVNode("th", { class: "px-4 py-2 text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" })
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requestsRows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            class: "hover:bg-gray-50 text-left"
          }, [
            _createElementVNode("td", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(row.user.firstName + " " + row.user.lastName), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(row.user.email), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.convertToDate(row.createdAt)), 1)
            ]),
            _createElementVNode("td", _hoisted_8, [
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "flex justify-center items-center py-2 px-4 rounded-md bg-indigo-100 text-primary-purple",
                style: {"width":"auto"},
                onClick: ($event: any) => (_ctx.handleReviewEntry(index))
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Review Entry ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ])
          ]))
        }), 128))
      ])
    ]),
    (_ctx.showReviewEntryModal)
      ? (_openBlock(), _createBlock(_component_ReviewEntryModal, {
          key: 0,
          isOpen: _ctx.showReviewEntryModal,
          onClose: () => (_ctx.showReviewEntryModal = false),
          expert: _ctx.requestsRows[_ctx.selectedRequestIndex]?.user,
          requestId: _ctx.requestsRows[_ctx.selectedRequestIndex]?.id,
          onRefreshRequests: _ctx.fetchRequests
        }, null, 8, ["isOpen", "onClose", "expert", "requestId", "onRefreshRequests"]))
      : _createCommentVNode("", true)
  ], 64))
}