import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'


const _hoisted_1 = { class: "flex flex-col gap-2 justify-center items-center min-h-screen w-full p-4" }
const _hoisted_2 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_3 = { class: "flex flex-col lg:flex-row max-w-max justify-center gap-2 px-5 border border-gray-200 rounded-md" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col text-left border-b lg:border-b-0 lg:border-r border-gray-200 py-5 px-2"
}
const _hoisted_5 = {
  key: 1,
  class: "flex flex-col p-5"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "flex flex-col py-5 w-full" }
const _hoisted_8 = {
  key: 3,
  class: "flex flex-col py-5 w-full"
}
const _hoisted_9 = {
  key: 4,
  class: "flex flex-col py-5 w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MeetingInformation = _resolveComponent("MeetingInformation")!
  const _component_MeetingCalendar = _resolveComponent("MeetingCalendar")!
  const _component_AttendeeInformation = _resolveComponent("AttendeeInformation")!
  const _component_MeetingConfirmation = _resolveComponent("MeetingConfirmation")!
  const _component_BookedMeetingDetails = _resolveComponent("BookedMeetingDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Error Icon",
              class: "w-4 h-4"
            })
          ], -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.meetingCode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_MeetingInformation)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.dateSelected && !_ctx.meetingCode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_MeetingCalendar)
          ]))
        : _createCommentVNode("", true),
      (_ctx.dateSelected && !_ctx.attendeeDetails && !_ctx.meetingCode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_AttendeeInformation)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.dateSelected && _ctx.attendeeDetails && !_ctx.meetingCode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_MeetingConfirmation, { errorMessage: _ctx.setErrorMessage }, null, 8, ["errorMessage"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.meetingCode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_BookedMeetingDetails)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}