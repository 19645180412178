import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col ml-6 gap-6 mb-4" }
const _hoisted_2 = { class: "flex flex-wrap justify-between items-center" }
const _hoisted_3 = { class: "flex flex-wrap gap-2 items-center" }
const _hoisted_4 = { class: "text-gray-500 text-sm font-normal leading-5" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = {
  key: 0,
  class: "text-primary-purple text-sm font-medium leading-5 text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_TeamMembersList = _resolveComponent("TeamMembersList")!
  const _component_AddTeamMemberModal = _resolveComponent("AddTeamMemberModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex flex-col items-start" }, [
        _createElementVNode("span", { class: "text-black text-3xl font-semibold leading-9 text-left" }, "Team Management")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-gray-800 text-base font-semibold leading-6" }, "Team Members", -1)),
          _createElementVNode("span", _hoisted_4, "(" + _toDisplayString(_ctx.teamMembersCount) + ")", 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BaseButton, {
            type: "button",
            class: "px-4 py-2 rounded-lg text-white text-sm font-medium leading-5 bg-primary-purple",
            onClick: _ctx.handleAddTeamMember
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Add member + ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.sentSuccessfully)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Invites sent successfully "))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_TeamMembersList, {
        newInvitesSent: _ctx.newInvitesSent,
        onTeamMembersCount: _cache[0] || (_cache[0] = ($event: any) => (_ctx.teamMembersCount = $event))
      }, null, 8, ["newInvitesSent"])
    ]),
    (_ctx.isAddMemberModalOpen)
      ? (_openBlock(), _createBlock(_component_AddTeamMemberModal, {
          key: 0,
          isVisible: _ctx.isAddMemberModalOpen,
          invitationType: "company",
          "onUpdate:isVisible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isAddMemberModalOpen = $event)),
          "onUpdate:emailsSent": _ctx.handleEmailsSent
        }, null, 8, ["isVisible", "onUpdate:emailsSent"]))
      : _createCommentVNode("", true)
  ], 64))
}