import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../assets/shared/failure.svg'


const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
}
const _hoisted_2 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
}
const _hoisted_3 = { class: "flex flex-col w-full text-sm leading-none whitespace-nowrap" }
const _hoisted_4 = { class: "flex flex-col mt-6 w-full text-sm leading-none text-left" }
const _hoisted_5 = { class: "flex overflow-hidden items-center mt-1 w-full text-gray-500 whitespace-nowrap rounded-md border border-gray-300 border-solid" }
const _hoisted_6 = { class: "flex flex-col mt-6 w-full" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "flex items-center w-full mt-2 border border-gray-300 rounded-md"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "flex sm:flex-row ml-auto gap-2" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "flex flex-col mt-6 w-full text-base whitespace-nowrap" }
const _hoisted_13 = { class: "flex flex-col w-full" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "bg-white p-6 rounded-md shadow-lg w-full sm:w-96",
          onClick: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
        }, [
          _cache[17] || (_cache[17] = _createElementVNode("header", { class: "flex flex-col w-full text-center" }, [
            _createElementVNode("h1", { class: "text-3xl font-semibold leading-tight text-zinc-800" }, " Add New Software "),
            _createElementVNode("p", { class: "mt-3 text-base text-gray-500" }, " Add new software that you think you are an expert in ")
          ], -1)),
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "Error Icon",
                    class: "w-4 h-4"
                  })
                ], -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("form", {
            onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
            class: "flex flex-col mt-6 w-full"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _cache[11] || (_cache[11] = _createElementVNode("label", {
                for: "softwareName",
                class: "font-medium text-zinc-800 text-left"
              }, "Name", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "softwareName",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                class: "overflow-hidden self-stretch px-3.5 py-2.5 mt-1 w-full bg-white rounded-md border border-gray-300 border-solid shadow-sm",
                placeholder: "Enter software name",
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.name]
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _cache[13] || (_cache[13] = _createElementVNode("label", {
                for: "softwareUrl",
                class: "font-medium text-zinc-800"
              }, "Add URL", -1)),
              _createElementVNode("div", _hoisted_5, [
                _cache[12] || (_cache[12] = _createElementVNode("span", { class: "self-stretch py-2.5 pr-3 pl-3.5 bg-gray-100 rounded-md" }, "https://", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "url",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.url) = $event)),
                  class: "overflow-hidden flex-1 shrink self-stretch py-2.5 pr-3.5 pl-3 my-auto bg-white rounded-none",
                  placeholder: "www.example.com",
                  required: ""
                }, null, 512), [
                  [_vModelText, _ctx.url]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[16] || (_cache[16] = _createElementVNode("label", {
                for: "logoUpload",
                class: "text-sm font-medium leading-none text-zinc-800 text-left"
              }, "Logo (Optional)", -1)),
              (!_ctx.imageSrc)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "flex flex-col items-center px-7 pt-6 pb-7 mt-1 w-full rounded-md border-2 border-gray-300 border-dashed",
                    onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                    onDragenter: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
                    onDragleave: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
                    onDrop: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", { innerHTML: _ctx.ADD_IMAGE_ICON }, null, 8, _hoisted_7),
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex flex-col items-center text-center mt-1 text-sm font-medium leading-none" }, [
                      _createElementVNode("label", {
                        for: "logoUpload",
                        class: "text-violet-600 bg-white rounded-md cursor-pointer px-4 py-1 text-center"
                      }, [
                        _createTextVNode(" Upload a Logo "),
                        _createElementVNode("span", { class: "ml-1 text-gray-600 text-sm" }, "or drag and drop")
                      ]),
                      _createElementVNode("p", { class: "mt-1 text-xs leading-none text-gray-500" }, " PNG or JPG up to 5 MB ")
                    ], -1))
                  ], 32))
                : _createCommentVNode("", true),
              (_ctx.imageSrc)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("img", {
                      src: _ctx.imageSrc,
                      class: "object-contain w-12 aspect-square mr-4",
                      alt: "Selected image preview"
                    }, null, 8, _hoisted_9),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_BaseButton, {
                        type: "button",
                        class: "px-4 py-2 text-xs sm:text-sm font-medium leading-none text-violet-600 bg-transparent",
                        onClick: _ctx.handleChangeClick
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode(" Update ")
                        ])),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_BaseButton, {
                        type: "button",
                        class: "text-black-600 hover:text-red-800 focus:outline-none mr-2",
                        onClick: _ctx.handleCloseClick
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", { innerHTML: _ctx.CLOSE_ICON_SM }, null, 8, _hoisted_11)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("input", {
                ref: "fileInput",
                type: "file",
                id: "logoUpload",
                class: "sr-only",
                accept: "image/png,image/jpeg,image/jpg",
                onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleImageUpload && _ctx.handleImageUpload(...args)))
              }, null, 544)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("button", {
                  type: "submit",
                  class: "overflow-hidden self-stretch px-4 py-2.5 w-full font-semibold text-white bg-violet-600 rounded-md shadow-sm",
                  disabled: _ctx.isDisabled
                }, _toDisplayString(_ctx.addButtonText), 9, _hoisted_14),
                _createElementVNode("button", {
                  type: "button",
                  class: "gap-1.5 self-stretch mt-6 w-full font-medium text-gray-500",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                  disabled: _ctx.isDisabled
                }, " Cancel ", 8, _hoisted_15)
              ])
            ])
          ], 32)
        ])
      ]))
    : _createCommentVNode("", true)
}