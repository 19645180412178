<template>
  <nav
    class="flex flex-wrap gap-5 justify-between px-7 py-3 w-full max-md:px-5"
  >
    <!-- navigation buttons -->
    <div
      class="flex flex-wrap gap-10 text-sm font-medium leading-none text-gray-600"
    >
      <div class="mt-2">
        <div v-html="COMPANY_LOGO"></div>
      </div>
      <div class="flex flex-wrap gap-5 overflow-x-auto whitespace-nowrap">
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 whitespace-nowrap rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <BaseButton
              class="flex gap-2"
              type="button"
              @click="navigateToAnalytics"
            >
              <div
                v-html="DASHBOARD_ICON"
                :class="{ 'text-primary-purple': activeTab == 0 }"
              ></div>
              <span
                class="self-stretch my-auto"
                :class="{ 'text-primary-purple': activeTab == 0 }"
                >Analytics</span
              >
            </BaseButton>
          </div>
        </div>
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <BaseButton
              type="button"
              class="flex gap-2"
              @click="navigateToPrograms"
            >
              <div
                v-html="EXPERTISE_ICON"
                :class="{ 'text-primary-purple': activeTab == 1 }"
              ></div>
              <span
                class="self-stretch my-auto"
                :class="{ 'text-primary-purple': activeTab == 1 }"
                >My Programs</span
              ></BaseButton
            >
          </div>
        </div>
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 whitespace-nowrap rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <BaseButton
              type="button"
              class="flex gap-2"
              @click="navigateToEarning"
            >
              <div
                v-html="EARNINGS_ICON"
                :class="{ 'text-primary-purple': activeTab == 2 }"
              ></div>

              <span
                class="self-stretch my-auto"
                :class="{ 'text-primary-purple': activeTab == 2 }"
                >My Spendings</span
              >
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <!-- available balance -->
    <div class="flex flex-col items-center bg-gray-200 rounded-lg px-2 py-1">
      <NavbarAvailableBalance />
    </div>

    <!-- user details -->
    <div class="flex gap-4 self-start mt-1 relative">
      <div class="flex" @click="toggleDropdown" id="userProfile">
        <div class="flex z-10 gap-3 items-center mr-0 font-medium">
          <div
            v-if="userStore.profilePicture"
            class="relative w-9 h-9 overflow-hidden rounded-full flex items-center justify-center"
          >
            <img
              loading="lazy"
              :src="profilePictureUrl"
              alt="User profile picture"
              class="w-full h-full object-cover"
            />
            <div class="absolute inset-0"></div>
          </div>
          <div v-else class="flex flex-col">
            <div
              class="flex items-center justify-center w-8 h-8 bg-primary-purple text-white rounded-full"
            >
              {{ initials() }}
            </div>
          </div>
          <div class="flex flex-col self-stretch my-auto">
            <div class="text-sm leading-none text-gray-700">
              {{ userStore.firstName }} {{ userStore.lastName }}
            </div>
            <div class="text-xs leading-none text-gray-500">
              {{ userStore.email }}
            </div>
          </div>
        </div>
        <div
          class="self-start text-xs font-black leading-none text-violet-600"
        ></div>
      </div>

      <!-- logout menu -->
      <div
        v-show="showDropdown"
        class="absolute right-0 mt-8 bg-white border border-gray-200 rounded-md shadow-lg p-3 w-36"
      >
        <BaseButton
          type="button"
          :disabled="disableLogout"
          class="w-full text-left flex items-center gap-2 logout-btn"
          @click="logout"
        >
          <div v-html="LOGOUT_ICON" class="text-red-600"></div>
          <span class="text-gray-500 font-sans text-sm font-medium leading-6"
            >Logout
          </span>
        </BaseButton>
      </div>
    </div>

    <div
      v-if="errorMessage"
      role="alert"
      class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      style="max-height: 80px; overflow-y: auto"
    >
      <!-- Icon Container -->
      <div
        class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
      >
        <!-- Custom Image Icon -->
        <img
          src="../../assets/shared/failure.svg"
          alt="Error Icon"
          class="w-4 h-4"
        />
      </div>
      <p>{{ errorMessage.slice(0, 60) }}</p>
    </div>
  </nav>
</template>

<script lang="ts">
import router from "@/router";
import { COMPANY_LOGO, LOGOUT_ICON } from "@/assets/svg/shared/svg-constants";
import {
  EARNINGS_ICON,
  EXPERTISE_ICON,
  DASHBOARD_ICON,
} from "@/assets/svg/dashboard/svg-constants";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import NavbarAvailableBalance from "@/components/dashboard/billing/NavbarAvailableBalance.vue";
import { useUserStore } from "@/stores/user";
import axios from "axios";

export default defineComponent({
  name: "ExpertiseNavbar",
  components: {
    BaseButton,
    NavbarAvailableBalance,
  },
  props: {
    authStore: Object,
    activeTab: Number,
  },
  setup() {
    const authProfileInformation = ref("");
    const showDropdown = ref(false);
    const disableLogout = ref(false);
    const errorMessage = ref("");
    const userStore = useUserStore();

    const navigateToAnalytics = () => {
      router.push({ name: "BillingPage" });
    };

    const navigateToPrograms = () => {
      router.push({ name: "ExpertPrograms" });
    };

    const navigateToEarning = () => {
      // router.push({ name: "ExpertEarnings" });
      console.log("Coming Soon");
    };

    const initials = () => {
      if (userStore.firstName && userStore.lastName) {
        const firstInitial = userStore.firstName.charAt(0).toUpperCase();
        const lastInitial = userStore.lastName.charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
      }
      return "";
    };

    const getLogoutParams = () => {
      return {
        url: process.env.VUE_APP_LOGOUT_ENDPOINT,
        data: {
          user: userStore.token,
        },
        config: {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userStore.token}`,
          },
        },
      };
    };
    const logout = async () => {
      try {
        const logoutParams = getLogoutParams();
        const response = await axios.delete(logoutParams.url, {
          data: logoutParams.data,
          ...logoutParams.config,
        });
        if (response.status === 200) {
          userStore.clearStore();
          window.open(process.env.VUE_APP_SIGN_IN_FORM_URL, "_self");
        } else {
          handleError("Failed to logout.");
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          handleError(
            error.response?.data?.message ||
              error.message ||
              "Failed to logout."
          );
        } else if (error instanceof Error) {
          handleError(error.message);
        } else {
          handleError("An unknown error occurred.");
        }
      }
    };
    const handleError = (error: string) => {
      errorMessage.value = error;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const handleClickOutside = (event: MouseEvent) => {
      const dropdownMenu = document.querySelector("#userProfile");
      const logoutButton = document.querySelector(".logout-btn");

      if (
        showDropdown.value &&
        dropdownMenu &&
        !dropdownMenu.contains(event.target as Node) &&
        (!logoutButton || !logoutButton.contains(event.target as Node))
      ) {
        showDropdown.value = false;
      }
    };

    const profilePictureUrl = computed(() => {
      return `${userStore.profilePicture}?t=${new Date().getTime()}`;
    });

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      COMPANY_LOGO,
      EARNINGS_ICON,
      EXPERTISE_ICON,
      DASHBOARD_ICON,
      LOGOUT_ICON,
      authProfileInformation,
      errorMessage,
      navigateToAnalytics,
      navigateToPrograms,
      navigateToEarning,
      userStore,
      initials,
      logout,
      showDropdown,
      disableLogout,
      toggleDropdown,
      profilePictureUrl,
    };
  },
});
</script>
