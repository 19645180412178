import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap min-h-screen" }
const _hoisted_2 = { class: "flex flex-col w-[80%] items-start pt-4 border-t border-gray-200 gap-4" }
const _hoisted_3 = { class: "flex flex-wrap gap-4" }
const _hoisted_4 = { class: "flex flex-col w-[20%] border-l border-t border-gray-200" }
const _hoisted_5 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HubspotCard = _resolveComponent("HubspotCard")!
  const _component_SalesforceCard = _resolveComponent("SalesforceCard")!
  const _component_DetailsSection = _resolveComponent("DetailsSection")!
  const _component_SyncSalesforceModal = _resolveComponent("SyncSalesforceModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-col text-left gap-2" }, [
          _createElementVNode("span", { class: "text-black text-sm font-medium leading-5" }, " Integration "),
          _createElementVNode("span", { class: "text-gray-500 text-sm font-normal leading-6" }, " Integrate your SlashExperts program with CRM. It only takes 5 minutes and no admin access required. It's easy! ")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_HubspotCard),
          _createVNode(_component_SalesforceCard, {
            salesforceSynced: _ctx.salesforceSynced,
            loading: _ctx.salesforceLoading,
            connectSalesforce: _ctx.connectSalesforce
          }, null, 8, ["salesforceSynced", "loading", "connectSalesforce"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_DetailsSection)
        ])
      ])
    ]),
    _createVNode(_component_SyncSalesforceModal, {
      isVisible: _ctx.isSyncSalesforceModalVisible,
      providerAccountName: _ctx.salesforceName,
      providerAccountEmail: _ctx.salesforceEmail,
      programId: _ctx.userStore.selectedProgram?.id || '',
      onClose: _ctx.closeModal,
      onUpdatedSettings: _ctx.salesforceMappingUpdated
    }, null, 8, ["isVisible", "providerAccountName", "providerAccountEmail", "programId", "onClose", "onUpdatedSettings"])
  ], 64))
}