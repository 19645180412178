<template>
  <div class="flex flex-col ml-4">
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex flex-col gap-4">
        <span class="text-black text-3xl font-semibold leading-9 text-left"
          >Account Information</span
        >
      </div>
      <div class="flex flex-col" v-if="!viewUpdateFields">
        <div class="flex flex-col text-left">
          <BaseButton
            type="button"
            @click="handleUpdate"
            class="flex flex-col justify-center items-center p-3 w-full text-white bg-primary-purple rounded-md text-sm font-medium leading-none text-center"
          >
            Update
          </BaseButton>
        </div>
      </div>
      <div v-else class="flex flex-wrap gap-4 items-center">
        <div v-if="updateCompanyUserLoading" class="flex flex-col">
          <span class="text-primary-purple">Saving...</span>
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            @click="handleCancel"
            class="flex flex-col justify-center items-center p-3 w-full text-black border border-gray-300 rounded-md text-sm font-medium leading-none text-center"
          >
            Cancel
          </BaseButton>
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            @click="handleSave"
            class="flex flex-col justify-center items-center p-3 w-full text-white bg-primary-purple rounded-md text-sm font-medium leading-none text-center"
          >
            Save
          </BaseButton>
        </div>
      </div>
    </div>
    <div class="flex justify-center mb-4">
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
        style="max-height: 80px; overflow-y: auto"
      >
        <!-- Icon Container -->
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <!-- Custom Image Icon -->
          <img
            src="../../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
    <div v-if="!viewUpdateFields" class="flex flex-col">
      <CurrentDetails
        :firstName="companyUserFirstName"
        :lastName="companyUserLastName"
        :email="companyUserEmail"
        :title="companyUserTitle"
        :companyName="companyName"
        :companyWebsite="companyWebsite"
        :profilePicture="companyUserProfilePicture"
      />
    </div>
    <div v-else>
      <UpdateDetails
        :firstName="companyUserFirstName"
        :lastName="companyUserLastName"
        :email="companyUserEmail"
        :title="companyUserTitle"
        :profilePicture="companyUserProfilePicture"
        :companyName="companyName"
        :companyWebsite="companyWebsite"
        @update:firstName="companyUserFirstName = $event"
        @update:lastName="companyUserLastName = $event"
        @update:email="companyUserEmail = $event"
        @update:title="companyUserTitle = $event"
        @update:profilePicture="companyUserProfilePicture = $event"
        @update:companyName="companyName = $event"
        @update:companyWebsite="companyWebsite = $event"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from "@/components/shared/BaseButton.vue";
import CurrentDetails from "./CurrentDetails.vue";
import { defineComponent, onMounted, ref, watch } from "vue";
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { CompanyUser, Company } from "@/types/dashboard-types";
import UpdateDetails from "./UpdateDetails.vue";
import { useUserStore } from "@/stores/user";
const GET_COMPANY_USER = gql`
  query getCompanyUser {
    getCompanyUser {
      id
      firstName
      lastName
      email
      title
      profilePicture
      company {
        id
        name
        website
      }
    }
  }
`;

const UPDATE_COMPANY_USER = gql`
  mutation updateCompanyUser($input: UpdateCompanyUserInput!) {
    updateCompanyUser(input: $input) {
      success
      errors
    }
  }
`;

export default defineComponent({
  name: "AccountContainer",
  components: {
    BaseButton,
    CurrentDetails,
    UpdateDetails,
  },
  setup() {
    // const userStore = useUserStore();
    const companyUserProfilePicture = ref("");
    const companyUserFirstName = ref("");
    const companyUserLastName = ref("");
    const companyUserEmail = ref("");
    const companyUserTitle = ref("");
    const companyName = ref("");
    const companyWebsite = ref("");
    const companyId = ref("");
    const viewUpdateFields = ref(false);
    const errorMessage = ref("");
    const oldState = ref<CompanyUser | null>(null);
    const userStore = useUserStore();

    onMounted(() => {
      fetchCompanyUser();
    });

    const {
      mutate: updateCompanyUser,
      onDone: updateCompanyUserDone,
      onError: updateCompanyUserError,
      loading: updateCompanyUserLoading,
    } = useMutation(UPDATE_COMPANY_USER);

    const handleCompanyUser = (companyUser: CompanyUser) => {
      companyUserProfilePicture.value = companyUser.profilePicture;
      companyUserFirstName.value = companyUser.firstName;
      companyUserLastName.value = companyUser.lastName;
      companyUserEmail.value = companyUser.email;
      companyUserTitle.value = companyUser.title;
      companyId.value = companyUser.company.id;

      userStore.setFirstName(companyUser.firstName);
      userStore.setLastName(companyUser.lastName);
      userStore.setEmail(companyUser.email);
      userStore.setTitle(companyUser.title);
      userStore.setProfilePicture("");
      userStore.setProfilePicture(companyUserProfilePicture.value);

      oldState.value = companyUser;
    };

    const handleCompany = (company: Company) => {
      companyName.value = company.name;
      companyWebsite.value = company.website;

      userStore.setCompanyName(company.name);
      userStore.setCompanyWebsite(company.website);
    };

    const handleUpdate = () => {
      viewUpdateFields.value = true;
    };

    const handleCancel = () => {
      viewUpdateFields.value = false;
      reloadOldState();
    };

    const reloadOldState = () => {
      if (oldState.value) {
        handleCompanyUser(oldState.value);
        handleCompany(oldState.value.company);
      }
    };

    const handleSave = () => {
      const isValid = validateFields();
      if (!isValid) {
        setErrorMessage(errorMessage.value);
        return;
      } else {
        updateCompanyUser({
          input: {
            companyUserParams: {
              firstName: companyUserFirstName.value,
              lastName: companyUserLastName.value,
              email: companyUserEmail.value,
              title: companyUserTitle.value,
              profilePicture: companyUserProfilePicture.value,
            },
            companyParams: {
              id: companyId.value,
              name: companyName.value,
              website: companyWebsite.value,
            },
          },
        });

        updateCompanyUserDone((result) => {
          if (result) {
            viewUpdateFields.value = false;
            fetchCompanyUser();
          }
        });

        updateCompanyUserError((error) => {
          // console.log("updateCompanyUserError ", error);
          // errorMessage.value = error.message;
          setErrorMessage(error.message);
        });
      }
    };

    const validateFields = () => {
      const errors = [];

      // Check each field and push errors if any are invalid
      if (!companyUserFirstName.value) {
        errors.push("First name cannot be empty.");
      }
      if (!companyUserLastName.value) {
        errors.push("Last name cannot be empty.");
      }
      if (!companyUserEmail.value) {
        errors.push("Email cannot be empty.");
      }
      if (!companyUserTitle.value) {
        errors.push("Title cannot be empty.");
      }
      if (!companyName.value) {
        errors.push("Company name cannot be empty.");
      }
      if (!companyWebsite.value) {
        errors.push("Website cannot be empty.");
      }
      if (!companyUserProfilePicture.value) {
        errors.push("Profile picture cannot be empty.");
      }
      if (!/\.[a-zA-Z]{2,}$/.test(companyWebsite.value)) {
        errors.push(
          "Website url should end with a TLD, such as .io, .com, .co, etc"
        );
      }
      if (!companyWebsite.value.startsWith("https://")) {
        errors.push("Website must start with 'https://'.");
      }
      if (!validEmail(companyUserEmail.value)) {
        errors.push("Invalid email format.");
      }

      // Return true if no errors, false otherwise
      if (errors.length === 0) {
        return true;
      } else {
        setErrorMessage(errors.join(". "));
        return false;
      }
    };

    const validEmail = (email: string) => {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      return pattern.test(email);
    };

    const setErrorMessage = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    const fetchCompanyUser = async () => {
      const {
        result: queryResult,
        error: queryError,
        refetch: refetchCompanyUser,
      } = useQuery(GET_COMPANY_USER);

      refetchCompanyUser();

      watch(
        () => queryResult.value,
        (newValue) => {
          if (newValue) {
            // console.log("triggered queryResult ", newValue);
            handleCompanyUser(newValue.getCompanyUser);
            handleCompany(newValue.getCompanyUser.company);
            // console.log("queryResult ", newValue);
          }
        },
        { immediate: true }
      );

      watch(
        () => queryError.value,
        (newValue) => {
          if (newValue) {
            // console.log("queryError ", newValue);
            setErrorMessage(newValue.message);
          }
        },
        { immediate: true }
      );
    };

    return {
      companyUserProfilePicture,
      companyUserFirstName,
      companyUserLastName,
      companyUserEmail,
      companyUserTitle,
      companyName,
      companyWebsite,
      companyId,
      handleUpdate,
      handleCancel,
      viewUpdateFields,
      handleSave,
      updateCompanyUserLoading,
      errorMessage,
    };
  },
});
</script>
