<template>
  <div
    class="flex flex-col gap-2 justify-center items-center min-h-screen w-full p-4"
  >
    <div
      v-if="errorMessage"
      role="alert"
      class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      style="max-height: 80px; overflow-y: auto"
    >
      <!-- Icon Container -->
      <div
        class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
      >
        <!-- Custom Image Icon -->
        <img
          src="../../assets/shared/failure.svg"
          alt="Error Icon"
          class="w-4 h-4"
        />
      </div>
      <p>{{ errorMessage }}</p>
    </div>
    <div
      class="flex flex-col lg:flex-row max-w-max justify-center gap-2 px-5 border border-gray-200 rounded-md"
    >
      <div
        v-if="!meetingCode"
        class="flex flex-col text-left border-b lg:border-b-0 lg:border-r border-gray-200 py-5 px-2"
      >
        <MeetingInformation />
      </div>
      <div class="flex flex-col p-5" v-if="!dateSelected && !meetingCode">
        <MeetingCalendar />
      </div>
      <div v-if="dateSelected && !attendeeDetails && !meetingCode">
        <div class="flex flex-col py-5 w-full">
          <AttendeeInformation />
        </div>
      </div>
      <div
        v-if="dateSelected && attendeeDetails && !meetingCode"
        class="flex flex-col py-5 w-full"
      >
        <MeetingConfirmation :errorMessage="setErrorMessage" />
      </div>
      <div v-if="meetingCode" class="flex flex-col py-5 w-full">
        <BookedMeetingDetails />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import MeetingInformation from "@/components/expert-meeting/MeetingInformation.vue";
import MeetingCalendar from "@/components/expert-meeting/MeetingCalendar.vue";
import AttendeeInformation from "@/components/expert-meeting/AttendeeInformation.vue";
import { useRoute } from "vue-router";
import { useAttendeeStore } from "@/stores/attendee";
import MeetingConfirmation from "@/components/expert-meeting/MeetingConfirmation.vue";
import BookedMeetingDetails from "@/components/expert-meeting/BookedMeetingDetails.vue";

export default defineComponent({
  name: "BookMeetingView",
  components: {
    MeetingInformation,
    MeetingCalendar,
    AttendeeInformation,
    MeetingConfirmation,
    BookedMeetingDetails,
  },
  setup() {
    const route = useRoute();
    const attendeeStore = useAttendeeStore();
    const companyName = ref("Test Company");
    const errorMessage = ref("");

    const attendeeDetails = computed(() => {
      return attendeeStore.bookMeetingName && attendeeStore.bookMeetingEmail;
      // return false;
    });

    const dateSelected = computed(() => {
      // Check for the presence of the query param (example: "userId")
      return route.query.date && route.query.startTime;
    });

    const setErrorMessage = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    const meetingCode = computed(() => {
      console.log("meetingCode: ", route.query.meeting_code);
      return route.query.meeting_code;
    });

    return {
      companyName,
      dateSelected,
      attendeeDetails,
      errorMessage,
      setErrorMessage,
      meetingCode,
    };
  },
});
</script>
