import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '../../../../assets/shared/failure.svg'


const _hoisted_1 = { class: "overflow-x-auto w-full pr-4" }
const _hoisted_2 = { class: "flex justify-center mb-4" }
const _hoisted_3 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "flex flex-wrap justify-between items-center mb-4 gap-2" }
const _hoisted_6 = { class: "flex flex-wrap gap-2" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ExpertsList = _resolveComponent("ExpertsList")!
  const _component_RequestsList = _resolveComponent("RequestsList")!
  const _component_AddTeamMemberModal = _resolveComponent("AddTeamMemberModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Error Icon",
                  class: "w-4 h-4"
                })
              ], -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BaseButton, {
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = 0)),
              class: _normalizeClass(["flex justify-center items-center py-2 px-8 rounded-full text-sm font-medium", {
              'bg-primary-purple text-white': _ctx.activeTab === 0,
              'bg-indigo-100 text-primary-purple': _ctx.activeTab === 1,
            }])
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Expert Invites ")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_BaseButton, {
              type: "button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = 1)),
              class: _normalizeClass(["flex justify-center items-center py-2 px-8 rounded-full text-sm font-medium", {
              'bg-primary-purple text-white': _ctx.activeTab === 1,
              'bg-indigo-100 text-primary-purple': _ctx.activeTab === 0,
            }])
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Requests ")
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "flex justify-center items-center py-2 px-8 rounded-md text-sm font-medium bg-indigo-100",
              style: {"width":"auto"},
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAddMemberModalOpen = true))
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("div", { class: "flex items-center text-primary-purple" }, [
                  _createElementVNode("span", null, "Invite Expert")
                ], -1)
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.activeTab === 0)
          ? (_openBlock(), _createBlock(_component_ExpertsList, {
              key: 0,
              ref: "expertsListRef"
            }, null, 512))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 1)
          ? (_openBlock(), _createBlock(_component_RequestsList, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isAddMemberModalOpen)
      ? (_openBlock(), _createBlock(_component_AddTeamMemberModal, {
          key: 0,
          invitationType: "experts",
          isVisible: _ctx.isAddMemberModalOpen,
          "onUpdate:isVisible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isAddMemberModalOpen = $event)),
          "onUpdate:emailsSent": _ctx.handleEmailsSent
        }, null, 8, ["isVisible", "onUpdate:emailsSent"]))
      : _createCommentVNode("", true)
  ], 64))
}