<template>
  <main class="bg-white min-h-screen flex flex-col">
    <div class="flex flex-grow max-md:flex-col">
      <aside
        class="flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div class="flex flex-col mt-14 flex-grow">
          <div class="flex flex-col ml-10 text-left">
            <!-- First Image -->
            <span class="text-white text-3xl font-semibold leading-9">
              Create An <br />
              Expert Program
            </span>
            <span
              class="text-white text-base font-normal leading-6 font-sans mt-3"
            >
              Start setting up an expert program</span
            >
          </div>

          <StepsList :currentStep="1" />
        </div>
      </aside>
      <section
        class="flex flex-col w-4/5 justify-start mt-10 pt-6 pb-1 max-md:w-full"
      >
        <!-- Error div -->
        <div class="flex justify-center mb-4">
          <div
            v-if="errorMessage"
            role="alert"
            class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
            style="max-height: 80px; overflow-y: auto"
          >
            <!-- Icon Container -->
            <div
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
            >
              <!-- Custom Image Icon -->
              <img
                src="../../assets/shared/failure.svg"
                alt="Error Icon"
                class="w-4 h-4"
              />
            </div>
            <p>{{ errorMessage }}</p>
          </div>
        </div>

        <div class="flex flex-col justify-center max-md:mt-4 max-md:w-full">
          <div class="flex flex-col mx-auto w-3/5">
            <h1
              class="text-3xl font-semibold leading-9 text-left text-zinc-800"
            >
              Billing
            </h1>
            <div
              class="flex flex-col z-20 gap-5 justify-between mt-2 text-left"
            >
              <!-- Account Balance Amount Section -->
              <div class="flex flex-col flex-grow basis-1/2">
                <BaseLabel
                  forAttr="accountBalance"
                  text="Account Balance"
                  class="text-sm font-medium leading-5 text-zinc-800 text-left"
                />
                <span class="text-xs my-1">
                  Enter the amount to add to your total balance right now
                </span>

                <div>
                  <CurrencyInput
                    v-model="accountBalance"
                    leadingText="$"
                    trailingText="USD"
                    placeholder="100"
                  />
                </div>
              </div>

              <!-- Auto top-up section -->
              <div class="flex flex-col border b-1 p-6">
                <div class="flex flex-row justify-between items-center">
                  <h2 class="font-semibold leading-9 text-left text-zinc-800">
                    Auto Top-up
                  </h2>

                  <button
                    @click="toggleAutoTopup"
                    :class="[
                      isAutoTopupEnabled ? 'bg-primary-purple' : 'bg-gray-200',
                      'flex items-center h-6 w-11 rounded-full p-1 transition-colors ease-in-out duration-300',
                    ]"
                  >
                    <span
                      :class="[
                        isAutoTopupEnabled ? 'translate-x-5' : 'translate-x-0',
                        'block w-4 h-4 transform bg-white rounded-full transition-transform ease-in-out duration-300',
                      ]"
                    ></span>
                  </button>
                </div>

                <div class="flex flex-col my-3">
                  <span class="text-sm leading-tight"
                    >Enter the amount you want to automatically top up your
                    account.</span
                  >
                </div>

                <div
                  v-if="isAutoTopupEnabled"
                  class="flex flex-wrap items-center my-3 gap-4 justify-start overflow-x-hidden"
                >
                  <div>
                    <span class="text-sm mr-2 font-bold">
                      If amount goes below</span
                    >
                    <div class="flex-1 w-1/2 sm:w-2/3 md:w-full">
                      <CurrencyInput
                        v-model="topUpLimit"
                        leadingText="$"
                        trailingText="USD"
                        placeholder="100"
                      />
                    </div>
                  </div>
                  <div>
                    <span class="text-sm mx-2 font-bold"> Auto top-up for</span>
                    <div class="flex-1 w-1/2 sm:w-2/3 md:w-full">
                      <CurrencyInput
                        v-model="topUpAmount"
                        leadingText="$"
                        trailingText="USD"
                        placeholder="100"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- billing method tab control -->
              <div class="flex flex-col font-bold text-sm">
                <span>Add Billing Method</span>
                <div class="flex flex-row p-3 bg-gray-200 rounded-md">
                  <div class="flex flex-row w-1/2 rounded-md">
                    <div
                      class="flex flex-row p-3 w-full justify-center items-center rounded-md"
                      :class="{
                        'bg-primary-purple text-white': isPaymentMethodCard,
                        'text-secondary-gray': !isPaymentMethodCard,
                      }"
                      @click="setPaymentMethodToCard"
                    >
                      <div
                        v-html="CARD_ICON"
                        class="mr-2"
                        :class="{
                          'text-white': isPaymentMethodCard,
                          'text-secondary-gray': !isPaymentMethodCard,
                        }"
                      ></div>
                      Card
                    </div>
                  </div>
                  <div class="flex flex-row w-1/2">
                    <div
                      class="flex flex-row p-3 w-full justify-center items-center rounded-md"
                      :class="{
                        'bg-primary-purple text-white': !isPaymentMethodCard,
                        'text-secondary-gray': isPaymentMethodCard,
                      }"
                      @click="setPaymentMethodToInvoice"
                    >
                      <div
                        v-html="DOCUMENT_DOLLAR_ICON"
                        class="mr-2"
                        :class="{
                          'text-white': !isPaymentMethodCard,
                          'text-secondary-gray': isPaymentMethodCard,
                        }"
                      ></div>
                      Invoice
                    </div>
                  </div>
                </div>
              </div>

              <!-- billing method section -->
              <div v-if="isPaymentMethodCard">
                <StripeCardFields
                  @paymentMethodId="handlePaymentMethodId"
                  @error="handleError"
                />
              </div>
              <div v-show="!isPaymentMethodCard">
                <BaseLabel
                  forAttr="invoiceEmail"
                  text="Invoice Recipient Email"
                  class="text-sm font-medium leading-5 text-zinc-800 text-left"
                />
                <BaseInput
                  type="email"
                  id="invoiceEmail"
                  v-model="invoiceEmail"
                  placeholder="you@example.com"
                  required
                  className="px-3.5 py-2.5 mt-1 text-gray-500 bg-white rounded-md border border-solid shadow-sm w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <footer class="flex flex-wrap justify-center mt-auto items-center">
          <FooterSection :isDisabled="isDisabled" @next="goNext" />
        </footer>
      </section>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import FooterSection from "@/components/expert-program/FooterSection.vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import StripeCardFields from "@/components/expert-program/account-balance/StripeCardFields.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import router from "@/router";
import StepsList from "@/components/expert-program/StepsList.vue";
import {
  CARD_ICON,
  DOCUMENT_DOLLAR_ICON,
} from "@/assets/svg/shared/svg-constants";

const CREATE_ACCOUNT_BALANCE = gql`
  mutation createAccountBalance($input: CreateAccountBalanceInput!) {
    createAccountBalance(input: $input) {
      errors
      message
      success
    }
  }
`;

export default defineComponent({
  name: "AddAccountBalance",
  components: {
    BaseInput,
    BaseLabel,
    CurrencyInput,
    FooterSection,
    StripeCardFields,
    StepsList,
  },
  setup() {
    const isPaymentMethodCard = ref(true);
    const topUpLimit = ref(0);
    const topUpAmount = ref(0);
    const isAutoTopupEnabled = ref(false);
    const accountBalance = ref(0);
    const invoiceEmail = ref("");
    const errorMessage = ref<string | null>(null);
    const isDisabled = ref(false);

    const {
      mutate: createAccountBalance,
      onDone: createAccountBalanceDone,
      onError: createAccountBalanceError,
    } = useMutation(CREATE_ACCOUNT_BALANCE);

    const goNext = () => {
      isDisabled.value = true;
      errorMessage.value = "";
      const valid = validate();
      if (valid === true) {
        if (isPaymentMethodCard.value) {
          generatePaymentMethodId();
        } else {
          callAccountBalanceMutation("");
        }
      } else {
        scrollToTop();
        isDisabled.value = false;
      }
    };

    const generatePaymentMethodId = async () => {
      document.dispatchEvent(new Event("generatePaymentMethodId"));
    };

    const handlePaymentMethodId = (paymentMethodId: string) => {
      callAccountBalanceMutation(paymentMethodId);
    };

    const handleError = (error: string) => {
      errorMessage.value = error;
      isDisabled.value = false;
      scrollToTop();
    };

    const validate = () => {
      if (accountBalance.value <= 0) {
        errorMessage.value = "Account Balance must be greater than 0";
        return false;
      }

      if (isAutoTopupEnabled.value) {
        if (topUpLimit.value <= 0 || topUpAmount.value <= 0) {
          errorMessage.value =
            "Auto topup limit and amount must be greater than 0";
          return false;
        }
      }

      if (!isPaymentMethodCard.value) {
        const emailField =
          document.querySelector<HTMLInputElement>("#invoiceEmail");
        if (emailField) {
          if (!emailField.checkValidity()) {
            emailField.reportValidity();
            return false;
          } else {
            return true;
          }
        }
      }

      return true;
    };

    const callAccountBalanceMutation = async (paymentMethodId: string) => {
      createAccountBalance({
        input: {
          accountBalanceParams: {
            availableBalance: accountBalance.value,
            currency: "usd",
            isDefault: true,
            invoiceRecipient: invoiceRecipient(),
            autoTopup: isAutoTopupEnabled.value,
            autoRechargeAmount: topUpAmount.value,
            lowBalanceAmount: topUpLimit.value,
            paymentMethod: {
              source: paymentMethodSource(),
              isActive: true,
              paymentMethodId: paymentMethodId,
            },
          },
        },
      });

      createAccountBalanceDone(async (response) => {
        if (response) {
          isDisabled.value = false;
          const data = response.data.createAccountBalance;
          if (data.success) {
            router.push({ name: "AddDirectory" });
          } else {
            errorMessage.value = data.errors.join(".");
          }
        }
      });

      createAccountBalanceError((error: ApolloError) => {
        errorMessage.value = error.message;
        isDisabled.value = false;
        scrollToTop();
      });
    };

    const paymentMethodSource = (): string => {
      if (isPaymentMethodCard.value) {
        return "credit_card";
      } else {
        return "invoice";
      }
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const invoiceRecipient = () => {
      if (!isPaymentMethodCard.value) {
        return invoiceEmail.value;
      }
    };

    const toggleAutoTopup = () => {
      isAutoTopupEnabled.value = !isAutoTopupEnabled.value;
      if (!isAutoTopupEnabled.value) {
        topUpAmount.value = 0;
        topUpLimit.value = 0;
      }
    };

    const setPaymentMethodToCard = () => {
      isPaymentMethodCard.value = true;
    };

    const setPaymentMethodToInvoice = () => {
      isPaymentMethodCard.value = false;
    };

    return {
      accountBalance,
      isDisabled,
      toggleAutoTopup,
      isAutoTopupEnabled,
      topUpLimit,
      topUpAmount,
      isPaymentMethodCard,
      handleError,
      handlePaymentMethodId,
      setPaymentMethodToCard,
      setPaymentMethodToInvoice,
      goNext,
      invoiceEmail,
      errorMessage,
      CARD_ICON,
      DOCUMENT_DOLLAR_ICON,
    };
  },
});
</script>
