<template>
  <div
    class="flex-1 p-4 border border-solid border-gray-300 bg-white rounded-lg"
  >
    <div class="flex flex-row justify-between items-center gap-4">
      <!-- Text -->
      <div class="flex flex-col gap-3">
        <span
          class="text-gray-800 font-sans text-lg font-semibold leading-6 tracking-tight"
          >Create New Lead or New Contact</span
        >
        <span class="text-gray-600 font-sans text-sm font-medium leading-5">
          If an Attendee doesn't exist in Salesforce based on their email
          address yet but there's a matched Company with the same domain then
          create a Contact. If there's no Account yet, create a Lead.
        </span>
      </div>

      <!-- Toggle -->
      <div class="flex flex-col">
        <div class="flex flex-col justify-center">
          <button
            class="flex items-center w-12 h-6 rounded-xl transition-colors duration-300"
            :class="{
              'bg-violet-600': createNewLeadOrContact,
              'bg-gray-200': !createNewLeadOrContact,
            }"
            @click="toggleCreateNewLeadOrContact"
            aria-label="Toggle button"
          >
            <span
              class="flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform"
              :class="{
                'translate-x-6': createNewLeadOrContact,
                'translate-x-0.5': !createNewLeadOrContact,
              }"
            ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CreateNewLeadOrContactToggle",
  props: {
    createNewLeadOrContact: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:createNewLeadOrContact"],
  setup(props, { emit }) {
    const toggleCreateNewLeadOrContact = () => {
      emit("update:createNewLeadOrContact", !props.createNewLeadOrContact);
    };

    return {
      toggleCreateNewLeadOrContact,
    };
  },
});
</script>
