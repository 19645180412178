<template>
  <div v-if="isVisible" class="flex flex-col px-3 mt-4">
    <div
      class="inline-flex gap-2 rounded-lg bg-slate-800 max-w-max px-2 relative"
      :style="{
        fontFamily: fontFamily,
      }"
    >
      <div
        class="absolute top-2 right-2 w-6 h-6 bg-white rounded-full text-black flex items-center justify-center text-xl cursor-pointer"
        @click="handleClose"
      >
        &times;
      </div>
      <div class="flex flex-col w-full overflow-hidden">
        <div class="flex flex-row w-full gap-2">
          <!-- Left Part with Green Background -->
          <div class="w-2/3 flex justify-center items-center">
            <div class="flex flex-col gap-6 pl-1 pr-4 py-4">
              <div class="flex flex-col text-white gap-1">
                <span
                  class="text-xl font-semibold text-left"
                  :style="{
                    fontSize: fontSize + 'px',
                    fontWeight: fontBoldness,
                    color: textColor,
                  }"
                >
                  {{ headingText }}
                </span>
                <span
                  class="text-base font-normal text-left"
                  :style="{
                    fontSize: fontSize + 'px',
                    fontWeight: fontBoldness,
                    color: textColor,
                  }"
                >
                  {{ bodyText }}
                </span>
              </div>
              <BaseButton
                type="button"
                @click="goToExpertsDirectory"
                class="flex flex-col justify-center px-5 py-1 max-w-max text-sm font-medium leading-none text-indigo-600 bg-gray-50 rounded-md"
                :style="{
                  color: buttonTextColor,
                  fontSize: fontSize + 'px',
                  fontWeight: fontBoldness,
                  backgroundColor: buttonBackgroundColor,
                }"
              >
                {{ buttonText }}
              </BaseButton>
            </div>
          </div>
          <!-- Right Part with Red Background -->
          <div
            class="w-1/3 flex justify-center items-start mr-2"
            :style="{ backgroundColor: bannerBackgroundColor }"
          >
            <img
              src="../../../assets/shared/widget-banner.svg"
              alt=""
              class="w-full p-1 mt-5 shrink-0"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from "../../shared/BaseButton.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "PopupModal",
  components: {
    BaseButton,
  },
  props: {
    fontSize: {
      type: String,
      default: "",
    },
    fontBoldness: {
      type: String,
      default: "",
    },
    fontFamily: {
      type: String,
      default: "",
    },
    headingText: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    bodyText: {
      type: String,
      default: "",
    },
    buttonTextColor: {
      type: String,
      default: "",
    },
    buttonBackgroundColor: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    bannerBackgroundColor: {
      type: String,
      default: "",
    },
    expertsDirectoryUrl: {
      type: String,
      default: "",
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isVisible = ref(true);

    const goToExpertsDirectory = () => {
      if (props.expertsDirectoryUrl) {
        window.open(props.expertsDirectoryUrl, "_blank"); // Opens in a new tab or window
      } else {
        console.warn("No URL provided");
      }
    };

    const handleClose = () => {
      if (!props.disableCloseButton) {
        isVisible.value = false;
      }
    };

    return {
      goToExpertsDirectory,
      handleClose,
      isVisible,
    };
  },
});
</script>
