<template>
  <div class="flex flex-col">
    <div class="flex flex-wrap justify-between p-4">
      <div class="flex flex-col text-left">
        <span class="text-gray-900 font-inter text-base font-medium leading-6"
          >Directory</span
        >
        <span class="text-gray-500 font-inter text-sm font-normal leading-8"
          >You can update the directory template</span
        >
      </div>
      <div class="flex flex-col" v-if="!showEditControls">
        <BaseButton
          type="button"
          class="gap-2 text-primary-purple font-inter text-base font-medium leading-6"
          @click="handleEditDirectory"
        >
          <div class="flex flex-wrap gap-2">
            <div v-html="EDIT_ICON"></div>
            Edit
          </div></BaseButton
        >
      </div>
      <div v-else class="flex flex-col">
        <div class="flex flex-wrap gap-2 items-center">
          <span v-if="updateDirectoryLoading" class="text-primary-purple"
            >Updating...</span
          >
          <BaseButton
            type="button"
            class="text-black border p-2 rounded-lg border-gray-300 font-inter text-base font-medium leading-6"
            @click="hideEditControls"
          >
            Cancel
          </BaseButton>
          <BaseButton
            type="button"
            class="bg-primary-purple text-white p-2 rounded-lg font-inter text-base font-medium leading-6"
            @click="handleUpdateDirectory"
          >
            Update
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-center mb-4">
    <div
      v-if="errorMessage"
      role="alert"
      class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      style="max-height: 80px; overflow-y: auto"
    >
      <!-- Icon Container -->
      <div
        class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
      >
        <!-- Custom Image Icon -->
        <img
          src="../../../../assets/shared/failure.svg"
          alt="Error Icon"
          class="w-4 h-4"
        />
      </div>
      <p>{{ errorMessage }}</p>
    </div>
  </div>
  <div class="flex gap-5 max-md:flex-col">
    <!-- directory preview in browser -->
    <section
      class="flex flex-col max-md:ml-0 max-md:w-full"
      :class="{ 'w-full': !showEditControls, 'w-[63%]': showEditControls }"
    >
      <div
        id="unevenGridContainer"
        class="flex flex-col w-full mx-auto w-full bg-white max-md:mt-8 max-md:max-w-full"
      >
        <div
          id="directoryPreview"
          class="flex flex-wrap items-center pt-2 pr-2 pl-3.5 text-xs whitespace-nowrap text-zinc-700 max-md:pr-5"
        >
          <!-- browser first row -->
          <div class="flex flex-col bg-gray-200 w-full rounded-t-lg px-2">
            <div class="flex flex-row justify-start gap-2 ml-2 pt-2 pt-3">
              <img
                loading="lazy"
                src="../../../../assets/expert-program/directory/traffic-lights.svg"
                class="object-fit shrink-0 self-stretch my-auto"
                alt=""
              />
              <!-- tab -->
              <div
                class="flex flex-row bg-white rounded-t-2xl w-full justify-between max-w-xs p-1"
              >
                <div class="flex flex-row justify-start items-center">
                  <div class="ml-2">
                    <img
                      loading="lazy"
                      :src="logoFromWebsite(userStore.companyWebsite || '')"
                      class="w-8 h-8 mr-2 rounded-full"
                    />
                  </div>
                  <div>
                    <span>{{ userStore.companyName }}</span>
                  </div>
                </div>
                <div class="flex flex-row justify-end items-center mr-3">
                  <div v-html="CLOSE_ICON_SM"></div>
                </div>
              </div>

              <div class="flex flex-row items-center ml-2">
                <div v-html="PLUS_ICON" class="text-gray-600"></div>
              </div>
            </div>
          </div>

          <!-- browser second row -->
          <div class="flex flex-col w-full px-2">
            <div
              class="flex flex-row justify-start items-center gap-2 ml-2 py-2"
            >
              <img
                loading="lazy"
                src="../../../../assets/expert-program/directory/browser-actions.svg"
                class="object-fit shrink-0 self-stretch my-auto"
                alt=""
              />

              <div
                class="flex flex-row bg-gray-100 w-full p-1 rounded-full items-center whitespace-nowrap overflow-ellipsis overflow-hidden"
              >
                <div v-html="LOCK_ICON" class="text-gray-600 ml-2"></div>
                <div class="ml-2 overflow-hidden">
                  <span>{{ userStore.companyWebsite }}/experts</span>
                </div>
              </div>

              <div>
                <div
                  v-html="BROWSER_MENU_ICON"
                  class="text-gray-600 ml-2"
                ></div>
              </div>
            </div>
          </div>

          <div
            v-if="isGridUneven"
            class="flex gap-2 bg-gray-100 p-6 justify-center w-full max-md:max-w-full"
          >
            <div v-for="n in columns" :key="n" class="space-y-3">
              <!-- Loop over the profiles array and place them in the respective column div based on index -->
              <ProfileCard
                v-for="profile in profiles.filter(
                  (_, index) => index % columns === n - 1
                )"
                :key="profile.id"
                :profile="profile"
                :isCentered="profileCenterAligned"
                :isGridUneven="isGridUneven"
                :profileImageClass="profileImageClass"
                :profileImageAndNameLayoutClass="profileImageAndNameLayoutClass"
                :fontFamily="fontFamily"
                :boldness="boldness"
                :textColor="textColor"
                :backgroundColor="backgroundColor"
                :fontSize="fontSize"
                class="flex-shrink-0"
              />
            </div>
          </div>
          <div v-else>
            <div
              class="flex flex-wrap bg-gray-100 p-6 items-start w-full max-md:max-w-full"
              :class="profileCardsGap"
            >
              <ProfileCard
                v-for="profile in profiles"
                :key="profile.id"
                :profile="profile"
                :isCentered="profileCenterAligned"
                :profileImageClass="profileImageClass"
                :isGridUneven="isGridUneven"
                :profileImageAndNameLayoutClass="profileImageAndNameLayoutClass"
                :fontFamily="fontFamily"
                :boldness="boldness"
                :textColor="textColor"
                :backgroundColor="backgroundColor"
                :fontSize="fontSize"
                class="flex-shrink-0"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- directory style + layout controls -->
    <section
      v-if="showEditControls"
      class="flex flex-col ml-5 w-[37%] max-md:ml-0 max-md:w-full"
    >
      <div class="flex flex-col max-md:mt-8">
        <div class="flex-flex-col">
          <TemplateSelector
            :initialSelected="selectedTemplate"
            @templateSelected="applyCardTemplate"
          />
        </div>
        <div class="flex flex-col">
          <CardStyling
            :fontFamily="fontFamily"
            :fontSize="fontSize"
            :buttonTextColor="textColor"
            :boldness="boldness"
            :buttonBackgroundColor="backgroundColor"
            @fontFamilyChanged="handleFontChange"
            @boldnessChanged="handleBoldnessChange"
            @buttonTextColorChanged="handleTextColorChange"
            @buttonBackgroundColorChanged="handleBackgroundColorChange"
            @fontSizeChanged="handleFontSizeChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { gql } from "@apollo/client/core";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import CardStyling from "@/components/expert-program/directory/CardStyling.vue";
import TemplateSelector from "@/components/expert-program/directory/TemplateSelector.vue";
import { profileCardCosmetics } from "@/types/dashboard-types";
import { directoryProfiles } from "@/data/expert-program/directory-preview-profiles";
import { Profile } from "@/types/dashboard-types";
import { EDIT_ICON } from "@/assets/svg/shared/svg-constants";
import ProfileCard from "@/components/expert-program/directory/ProfileCard.vue";
// import UpdateDirectoryModal from "@/components/dashboard/programs/directory-and-widget/UpdateDirectoryModal.vue";
import { useUserStore } from "@/stores/user";
import BaseButton from "@/components/shared/BaseButton.vue";
// import { DirectorySettings } from "@/types/dashboard-types";
import {
  CLOSE_ICON_SM,
  PLUS_ICON,
  LOCK_ICON,
  BROWSER_MENU_ICON,
} from "@/assets/svg/shared/svg-constants";

const GET_PROGRAM_DIRECTORY = gql`
  query getProgramDirectoryDetail($programId: ID!) {
    getProgramDirectoryDetail(programId: $programId) {
      id
      style
      directoryCosmetic {
        fontFamily
        bold
        textColor
        backgroundColor
        fontSize
      }
    }
  }
`;

const UPDATE_DIRECTORY = gql`
  mutation updateDirectory($input: UpdateDirectoryInput!) {
    updateDirectory(input: $input) {
      directory {
        id
        companyId
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "DirectoryAndWidget",
  components: {
    ProfileCard,
    BaseButton,
    // UpdateDirectoryModal,
    CardStyling,
    TemplateSelector,
  },
  emits: ["hideWidgets"],
  setup(_, { emit }) {
    const userStore = useUserStore();
    const fontFamily = ref<string>("");
    const boldness = ref<string>("");
    const textColor = ref<string>("");
    const backgroundColor = ref<string>("");
    const fontSize = ref<string>("");
    const profileImageClass = ref<string>("");
    const profileCardsGap = ref<string>("");
    const profileImageAndNameLayoutClass = ref<string>("");
    const profileCenterAligned = ref(false);
    const columns = ref(5);
    const isGridUneven = ref(false);
    const selectedTemplate = ref("");
    const profiles = ref<Profile[]>(directoryProfiles);
    const directoryId = ref("");
    const showEditControls = ref(false);
    const errorMessage = ref("");
    const directoryUpdateModalVisible = ref(false);

    const {
      result: directoryResult,
      error: directoryError,
      refetch: refetchDirectory,
    } = useQuery(GET_PROGRAM_DIRECTORY, {
      programId: userStore.selectedProgram?.id,
    });

    const {
      mutate: updateDirectory,
      onDone: updateDirectoryDone,
      onError: updateDirectoryError,
      loading: updateDirectoryLoading,
    } = useMutation(UPDATE_DIRECTORY);

    const applyCardStyles = (profileCosmetic: profileCardCosmetics) => {
      fontFamily.value = profileCosmetic.fontFamily;
      boldness.value = profileCosmetic.bold;
      textColor.value = profileCosmetic.textColor;
      backgroundColor.value = profileCosmetic.backgroundColor;
      fontSize.value = profileCosmetic.fontSize;
    };

    const handleResize = () => {
      columns.value = getColumnCount();
    };

    const applyCardTemplate = (template: string) => {
      handleResize();
      selectedTemplate.value = template;
      updateProfileImageClass(template);
      updateProfileCardsGap(template);
      updateProfileImageAndNameLayoutClass(template);
      updateProfileCenterAlignment(template);
      updateGridLayout(template);
    };

    const updateProfileImageClass = (template: string) => {
      if (template === "template-1" || template === "template-4") {
        profileImageClass.value = "rounded-full";
      } else {
        profileImageClass.value = "";
      }
    };

    const updateProfileCardsGap = (template: string) => {
      if (template === "template-3") {
        profileCardsGap.value = "gap-0.5";
      } else {
        profileCardsGap.value = "gap-3";
      }
    };

    const updateProfileImageAndNameLayoutClass = (template: string) => {
      if (template === "template-2" || template === "template-3") {
        profileImageAndNameLayoutClass.value = "flex-row";
      } else {
        profileImageAndNameLayoutClass.value = "flex-col";
      }
    };

    const updateProfileCenterAlignment = (template: string) => {
      if (template === "template-5") {
        profileCenterAligned.value = true;
      } else {
        profileCenterAligned.value = false;
      }
    };

    const updateGridLayout = (template: string) => {
      if (template === "template-4") {
        isGridUneven.value = true;
      } else {
        isGridUneven.value = false;
      }
    };

    const breakpoints = {
      sm: 640, // Small screens
      md: 768, // Medium screens
      lg: 1024, // Large screens
      xl: 1280, // Extra-large screens
      // "2xl": 1536, // 2X Large screens
    };

    const getColumnCount = () => {
      const gridContainer = document.querySelector(
        "#unevenGridContainer"
      ) as HTMLElement;
      const width = gridContainer?.clientWidth;

      if (width < breakpoints.sm) {
        return 1;
      } else if (width < breakpoints.md) {
        return 2;
      } else if (width < breakpoints.lg) {
        return 3;
      } else if (width < breakpoints.xl) {
        return 4;
      } else {
        return 5;
      }
    };

    const logoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";

      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");

        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    const handleEditDirectory = () => {
      showEditControls.value = true;
      emit("hideWidgets", true);
    };

    const handleUpdateDirectory = () => {
      updateDirectory({
        input: {
          directoryParams: {
            directoryId: directoryId.value,
            programId: userStore.selectedProgram?.id,
            style: selectedTemplate.value,
            fontFamily: fontFamily.value,
            bold: boldness.value,
            textColor: textColor.value,
            backgroundColor: backgroundColor.value,
            fontSize: fontSize.value,
            textAlign: "left",
          },
        },
      });

      updateDirectoryDone((response) => {
        if (response) {
          console.log("Directory updated successfully ", response);
          if (response.data.updateDirectory.errors.length > 0) {
            handleErrorMessage(response.data.updateDirectory.errors.join("."));
          } else {
            hideEditControls();
            refetchDirectory();
          }
        }
      });

      updateDirectoryError((error) => {
        if (error) {
          console.log("Error updating directory ", error);
          handleErrorMessage(error.message);
        }
      });
    };

    const hideEditControls = () => {
      showEditControls.value = false;
      emit("hideWidgets", false);
    };

    const handleErrorMessage = (error: string) => {
      errorMessage.value = error;
      setTimeout(() => {
        errorMessage.value = "";
      }, 2200);
    };

    const handleFontChange = (newFontFamily: string) => {
      fontFamily.value = newFontFamily;
    };

    const handleBoldnessChange = (newBoldness: string) => {
      boldness.value = newBoldness;
    };

    const handleTextColorChange = (newTextColor: string) => {
      textColor.value = newTextColor;
    };

    const handleBackgroundColorChange = (newBackgroundColor: string) => {
      backgroundColor.value = newBackgroundColor;
    };

    const handleFontSizeChange = (newFontSize: string) => {
      fontSize.value = newFontSize;
    };

    const handleDirectoryUpdated = () => {
      console.log("directory updated in parent");
      refetchDirectory();
    };

    handleResize();

    onMounted(() => {
      window.addEventListener("resize", () => {
        handleResize();
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    watch(
      directoryResult,
      (newValue) => {
        if (newValue) {
          if (newValue.getProgramDirectoryDetail) {
            directoryId.value = newValue.getProgramDirectoryDetail.id;
            applyCardTemplate(newValue.getProgramDirectoryDetail.style);
            applyCardStyles(
              newValue.getProgramDirectoryDetail.directoryCosmetic
            );
          }
        }
      },
      { immediate: true }
    );

    watch(
      directoryError,
      (newValue) => {
        if (newValue) {
          console.log(newValue);
        }
      },
      { immediate: true }
    );

    return {
      handleDirectoryUpdated,
      updateDirectoryLoading,
      showEditControls,
      fontFamily,
      boldness,
      textColor,
      backgroundColor,
      fontSize,
      profileImageClass,
      profileCardsGap,
      profileImageAndNameLayoutClass,
      profileCenterAligned,
      columns,
      isGridUneven,
      profiles,
      hideEditControls,
      CLOSE_ICON_SM,
      PLUS_ICON,
      LOCK_ICON,
      BROWSER_MENU_ICON,
      EDIT_ICON,
      userStore,
      logoFromWebsite,
      handleEditDirectory,
      directoryUpdateModalVisible,
      applyCardTemplate,
      handleFontChange,
      handleBoldnessChange,
      handleTextColorChange,
      handleBackgroundColorChange,
      handleFontSizeChange,
      selectedTemplate,
      handleUpdateDirectory,
      errorMessage,
      // handleEditDirectory,
    };
  },
});
</script>
