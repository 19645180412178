<template>
  <div v-if="isVisible" class="flex flex-col px-3">
    <div
      class="flex flex-wrap p-2 gap-2 justify-between rounded-lg items-center relative"
      :style="{
        fontFamily: fontFamily,
        fontWeight: fontBoldness,
        fontSize: fontSize + 'px',
        backgroundColor: headerBackgroundColor,
      }"
    >
      <div
        class="absolute top-0 right-0 w-6 h-6 bg-white rounded-full text-black flex items-center justify-center text-xl cursor-pointer"
        @click="handleClose"
      >
        &times;
      </div>

      <div class="flex flex-wrap items-center gap-2">
        <div
          class="text-purple-50 text-left"
          :style="{ fontFamily: fontFamily, color: headerTextColor }"
        >
          {{ topheaderText }}
        </div>
        <img
          src="../../../assets/shared/avatar-group.svg"
          alt="Error Icon"
          class="h-full"
          loading="lazy"
        />
      </div>

      <div>
        <BaseButton
          type="button"
          class="flex flex-col justify-center items-start p-2 px-5 w-full text-sm font-medium leading-none text-indigo-600 bg-gray-50 rounded-lg"
          @click="goToExpertsDirectory"
          :style="{
            color: buttonTextColor,
            fontSize: fontSize + 'px',
            fontWeight: fontBoldness,
            backgroundColor: buttonBackgrounColor,
          }"
          >{{ buttonText }}</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from "../../shared/BaseButton.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "TopHeaderBar",
  components: {
    BaseButton,
  },
  props: {
    topheaderText: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonTextColor: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "",
    },
    fontBoldness: {
      type: String,
      default: "",
    },
    fontFamily: {
      type: String,
      default: "",
    },
    headerBackgroundColor: {
      type: String,
      default: "",
    },
    headerTextColor: {
      type: String,
      default: "",
    },
    buttonBackgrounColor: {
      type: String,
      default: "",
    },
    expertsDirectoryUrl: {
      type: String,
      default: "",
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isVisible = ref(true);

    const goToExpertsDirectory = () => {
      if (props.expertsDirectoryUrl) {
        window.open(props.expertsDirectoryUrl, "_blank"); // Opens in a new tab or window
      } else {
        console.warn("No URL provided");
      }
    };

    const handleClose = () => {
      if (!props.disableCloseButton) {
        isVisible.value = false;
      }
    };

    return {
      goToExpertsDirectory,
      handleClose,
      isVisible,
    };
  },
});
</script>
