import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, [
      _createElementVNode("span", { class: "text-white text-3xl font-semibold leading-9" }, " Signing you in... ")
    ], -1)
  ])))
}