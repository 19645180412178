<template>
  <div class="mt-3 text-sm text-left">
    <!-- Convert more website visitors -->
    <div
      class="p-4 border border-gray-300 rounded-t-md"
      :class="{
        'bg-primary-purple bg-opacity-10': localConvertWebsiteVisitors,
      }"
    >
      <CheckBox
        v-model="localConvertWebsiteVisitors"
        id="convertVisitors"
        base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
        checked-classes="bg-violet-600 border-violet-600"
        unchecked-classes="bg-white border-violet-600"
        label-classes="text-gray-500 ml-2"
        @change="
          emitChange('convertWebsiteVisitors', localConvertWebsiteVisitors)
        "
      >
        Convert more website visitors
      </CheckBox>
    </div>

    <!-- Generate new intent signals -->
    <div
      class="p-4 border-b border-l border-r border-gray-300"
      :class="{ 'bg-primary-purple bg-opacity-10': localGenerateIntentSignals }"
    >
      <CheckBox
        v-model="localGenerateIntentSignals"
        id="generateIntentSignals"
        base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
        checked-classes="bg-violet-600 border-violet-600"
        unchecked-classes="bg-white border-violet-600"
        label-classes="text-gray-500 ml-2"
        @change="
          emitChange('generateIntentSignals', localGenerateIntentSignals)
        "
      >
        Generate new intent signals
      </CheckBox>
    </div>

    <div
      class="p-4 border-b border-l border-r border-gray-300"
      :class="{
        'bg-primary-purple bg-opacity-10': localEnableSales,
      }"
    >
      <CheckBox
        v-model="localEnableSales"
        id="enableSales"
        base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
        checked-classes="bg-violet-600 border-violet-600"
        unchecked-classes="bg-white border-violet-600"
        label-classes="text-gray-500 ml-2"
        @change="emitChange('enableSales', localEnableSales)"
      >
        Enable sales to accelerate pipeline
      </CheckBox>
    </div>

    <div
      class="p-4 border-b border-l border-r border-gray-300"
      :class="{
        'bg-primary-purple bg-opacity-10': localDriveConversion,
      }"
    >
      <CheckBox
        v-model="localDriveConversion"
        id="driveConversion"
        base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
        checked-classes="bg-violet-600 border-violet-600"
        unchecked-classes="bg-white border-violet-600"
        label-classes="text-gray-500 ml-2"
        @change="emitChange('driveConversion', localDriveConversion)"
      >
        Use as CTA in ads to drive conversion
      </CheckBox>
    </div>

    <div
      class="p-4 border-b border-l border-r border-gray-300"
      :class="{
        'bg-primary-purple bg-opacity-10': localLateStageCustomerReferences,
      }"
    >
      <CheckBox
        v-model="localLateStageCustomerReferences"
        id="lateStageCustomerReferences"
        base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
        checked-classes="bg-violet-600 border-violet-600"
        unchecked-classes="bg-white border-violet-600"
        label-classes="text-gray-500 ml-2"
        @change="
          emitChange(
            'lateStageCustomerReferences',
            localLateStageCustomerReferences
          )
        "
      >
        Use for late stage customer backchannel/references
      </CheckBox>
    </div>

    <div
      class="p-4 border-b border-l border-r border-gray-300"
      :class="{
        'bg-primary-purple bg-opacity-10': localSdrSequenceStep,
      }"
    >
      <CheckBox
        v-model="localSdrSequenceStep"
        id="sdrSequenceStep"
        base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
        checked-classes="bg-violet-600 border-violet-600"
        unchecked-classes="bg-white border-violet-600"
        label-classes="text-gray-500 ml-2"
        @change="emitChange('sdrSequenceStep', localSdrSequenceStep)"
      >
        New SDR sequence step
      </CheckBox>
    </div>

    <!-- Engage new customer champions -->
    <div
      class="p-4 border-b border-l border-r border-gray-300 rounded-b-md"
      :class="{ 'bg-primary-purple bg-opacity-10': localEngageNewCustomer }"
    >
      <CheckBox
        v-model="localEngageNewCustomer"
        id="engageNewCustomer"
        base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
        checked-classes="bg-violet-600 border-violet-600"
        unchecked-classes="bg-white border-violet-600"
        label-classes="text-gray-500 ml-2"
        @change="emitChange('engageNewCustomer', localEngageNewCustomer)"
      >
        Engage new user/customer champions
      </CheckBox>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import CheckBox from "@/components/shared/CheckBox.vue";

type PropName =
  | "convertWebsiteVisitors"
  | "generateIntentSignals"
  | "enableSales"
  | "engageNewCustomer"
  | "driveConversion"
  | "lateStageCustomerReferences"
  | "sdrSequenceStep";

export default defineComponent({
  name: "GoalsCheckboxList",
  components: {
    CheckBox,
  },
  props: {
    convertWebsiteVisitors: {
      type: Boolean,
      required: true,
    },
    generateIntentSignals: {
      type: Boolean,
      required: true,
    },
    enableSales: {
      type: Boolean,
      required: true,
    },
    engageNewCustomer: {
      type: Boolean,
      required: true,
    },
    driveConversion: {
      type: Boolean,
      rqeuired: true,
    },
    lateStageCustomerReferences: {
      type: Boolean,
      rqeuired: true,
    },
    sdrSequenceStep: {
      type: Boolean,
      rqeuired: true,
    },
  },
  emits: [
    "update:convertWebsiteVisitors",
    "update:generateIntentSignals",
    "update:enableSales",
    "update:engageNewCustomer",
    "update:driveConversion",
    "update:lateStageCustomerReferences",
    "update:sdrSequenceStep",
  ],

  setup(props, { emit }) {
    // Local state
    const localConvertWebsiteVisitors = ref(props.convertWebsiteVisitors);
    const localGenerateIntentSignals = ref(props.generateIntentSignals);
    const localEnableSales = ref(props.enableSales);
    const localEngageNewCustomer = ref(props.engageNewCustomer);
    const localDriveConversion = ref(props.driveConversion);
    const localSdrSequenceStep = ref(props.sdrSequenceStep);
    const localLateStageCustomerReferences = ref(
      props.lateStageCustomerReferences
    );

    // Watch the props and sync local state if the parent updates the props
    watch(
      () => props.convertWebsiteVisitors,
      (newVal) => {
        localConvertWebsiteVisitors.value = newVal;
      }
    );
    watch(
      () => props.generateIntentSignals,
      (newVal) => {
        localGenerateIntentSignals.value = newVal;
      }
    );
    watch(
      () => props.enableSales,
      (newVal) => {
        localEnableSales.value = newVal;
      }
    );
    watch(
      () => props.engageNewCustomer,
      (newVal) => {
        localEngageNewCustomer.value = newVal;
      }
    );
    watch(
      () => props.driveConversion,
      (newVal) => {
        localDriveConversion.value = newVal;
      }
    );
    watch(
      () => props.lateStageCustomerReferences,
      (newVal) => {
        localLateStageCustomerReferences.value = newVal;
      }
    );

    // Emit change event to the parent
    const emitChange = (propName: PropName, newValue: boolean) => {
      emit(`update:${propName}`, newValue);
    };

    return {
      localConvertWebsiteVisitors,
      localGenerateIntentSignals,
      localEnableSales,
      localEngageNewCustomer,
      localDriveConversion,
      localLateStageCustomerReferences,
      localSdrSequenceStep,
      emitChange,
    };
  },
});
</script>
