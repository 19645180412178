import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen bg-gray-100" }
const _hoisted_2 = { class: "flex flex-col w-full border-b" }
const _hoisted_3 = { class: "flex flex-col sm:flex-row w-full pt-12 pr-6" }
const _hoisted_4 = { class: "flex flex-col w-1/5 text-left pt-2 px-6 gap-6 sm:h-screen rounded-md border border-gray-200 max-md:ml-0 max-md:w-full" }
const _hoisted_5 = { class: "flex flex-col gap-3" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "flex flex-col w-full sm:w-4/5 justify-start pr-6 pt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_AccountContainer = _resolveComponent("AccountContainer")!
  const _component_BillingContainer = _resolveComponent("BillingContainer")!
  const _component_TeamManagementContainer = _resolveComponent("TeamManagementContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_Navbar, { activeTab: 0 })
    ]),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("aside", _hoisted_4, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col" }, [
          _createElementVNode("span", { class: "text-black text-lg font-medium leading-6" }, "Settings")
        ], -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex flex-row items-center px-2 py-1 rounded-md", {
              'bg-violet-100 text-indigo-700': _ctx.activeTab === 0,
            }])
          }, [
            _createElementVNode("div", {
              innerHTML: _ctx.PERSON_ICON,
              class: _normalizeClass(["mr-2 text-gray-500", {
                'text-primary-purple': _ctx.activeTab === 0,
                'text-gray-500': _ctx.activeTab !== 0,
              }])
            }, null, 10, _hoisted_6),
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "text-gray-500 text-base font-medium leading-6 text-left w-full",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = 0))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({ 'text-indigo-700': _ctx.activeTab === 0 })
                }, "Account", 2)
              ]),
              _: 1
            })
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["flex flex-row items-center px-2 py-1 rounded-md", {
              'bg-violet-100 text-indigo-700': _ctx.activeTab === 1,
            }])
          }, [
            _createElementVNode("div", {
              innerHTML: _ctx.MONEY_ICON,
              class: _normalizeClass(["mr-2 text-gray-500", {
                'text-primary-purple': _ctx.activeTab === 1,
                'text-gray-500': _ctx.activeTab !== 1,
              }])
            }, null, 10, _hoisted_7),
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "text-gray-500 text-base font-medium leading-6 text-left w-full",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = 1))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({ 'text-indigo-700': _ctx.activeTab === 1 })
                }, "Billing", 2)
              ]),
              _: 1
            })
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["flex flex-row items-center px-2 py-1 rounded-md", {
              'bg-violet-100 text-indigo-700': _ctx.activeTab === 2,
            }])
          }, [
            _createElementVNode("div", {
              innerHTML: _ctx.PEOPLE_ICON_2,
              class: _normalizeClass(["mr-2 text-gray-500", {
                'text-primary-purple': _ctx.activeTab === 2,
                'text-gray-500': _ctx.activeTab !== 2,
              }])
            }, null, 10, _hoisted_8),
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "text-gray-500 text-base font-medium leading-6 text-left w-full",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeTab = 2))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({ 'text-indigo-700': _ctx.activeTab === 2 })
                }, "Team Management", 2)
              ]),
              _: 1
            })
          ], 2)
        ])
      ]),
      _createElementVNode("section", _hoisted_9, [
        (_ctx.activeTab === 0)
          ? (_openBlock(), _createBlock(_component_AccountContainer, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 1)
          ? (_openBlock(), _createBlock(_component_BillingContainer, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 2)
          ? (_openBlock(), _createBlock(_component_TeamManagementContainer, { key: 2 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}