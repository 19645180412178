<template>
  <main class="bg-white min-h-screen flex flex-col">
    <div class="flex flex-grow max-md:flex-col">
      <aside
        class="flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div class="flex flex-col mt-14 flex-grow">
          <div class="flex flex-col ml-10 text-left">
            <span class="text-white text-3xl font-semibold leading-9">
              Create An <br />
              Expert Program
            </span>
            <span
              class="text-white text-base font-normal leading-6 font-sans mt-3"
            >
              Start setting up an expert program</span
            >
          </div>

          <!-- Third Image, or Footer Image -->
          <StepsList :currentStep="3" />
        </div>
      </aside>
      <section
        class="flex flex-col justify-start w-4/5 max-md:ml-0 max-md:w-full"
      >
        <div class="flex flex-col mt-20 w-full max-md:mt-10 max-md:max-w-full">
          <!-- directroy + code step list -->
          <div class="flex flex-col ml-16 max-w-full">
            <h2
              class="self-start text-3xl font-semibold leading-tight text-zinc-800"
            >
              Set-up Widget
            </h2>
            <CurrentStep
              :currentStep="currentStep"
              firstStepText="Setup Widget"
            />
          </div>

          <!-- Error div -->
          <div class="flex justify-center mb-4">
            <div
              v-if="errorMessage"
              role="alert"
              class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
              style="max-height: 80px; overflow-y: auto"
            >
              <div
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <img
                  src="../../assets/shared/failure.svg"
                  alt="Error Icon"
                  class="w-4 h-4"
                />
              </div>
              <p>{{ errorMessage }}</p>
            </div>
          </div>

          <!-- set up directory view -->
          <div
            v-if="!userStore.createExpertProgram?.widgetCreated"
            class="self-center mt-8 w-full px-10"
          >
            <div class="flex gap-5 max-md:flex-col">
              <!-- directory preview in browser -->
              <section class="flex flex-col w-[63%] max-md:ml-0 max-md:w-full">
                <div
                  id="previewContainer"
                  class="flex flex-col w-full mx-auto w-full bg-gray-200 rounded-lg max-md:mt-8 max-md:max-w-full pb-4 overflow-hidden"
                >
                  <div v-if="topHeaderBarVisible" class="flex flex-col mt-3">
                    <TopHeaderBar
                      :topheaderText="topheaderText"
                      :buttonText="buttonText"
                      :buttonTextColor="buttonTextColor"
                      :fontSize="fontSize"
                      :fontFamily="fontFamily"
                      :fontBoldness="boldness"
                      :headerBackgroundColor="headerBackgroundColor"
                      :headerTextColor="headerTextColor"
                      :buttonBackgrounColor="headerButtonBackgroundColor"
                      :expertsDirectoryUrl="topHeaderExpertsDirectoryUrl"
                      :disableCloseButton="true"
                    />
                  </div>
                  <div
                    v-if="popupModalVisible"
                    class="flex flex-col"
                    :class="{
                      'items-end': !popupModalLeftOrientation,
                      'items-start': popupModalLeftOrientation,
                    }"
                    :style="{ marginTop: '40%' }"
                  >
                    <PopupModal
                      :fontFamily="popupModalfontFamily"
                      :fontSize="popupModalfontSize"
                      :fontBoldness="popupModalBoldness"
                      :expertsDirectoryUrl="popupModalExpertsDirectoryUrl"
                      :disableCloseButton="true"
                      :buttonTextColor="popupModalButtonTextColor"
                      :headingText="popupModalHeadingText"
                      :buttonText="popupModalButtonText"
                      :bodyText="popupModalBodyText"
                      :buttonBackgroundColor="popupModalButtonBackgroundColor"
                      :textColor="popupModalTextColor"
                      :bannerBackgroundColor="popupModalBannerBackgroundColor"
                    />
                  </div>
                </div>
              </section>

              <!-- widget style + layout controls -->
              <section
                class="flex flex-col ml-5 w-[37%] max-md:ml-0 max-md:w-full"
              >
                <div class="flex flex-col max-md:mt-8">
                  <div class="flex-flex-col">
                    <TopHeaderBarDesign
                      :isVisible="topHeaderBarVisible"
                      :fontFamily="fontFamily"
                      :fontSize="fontSize"
                      :buttonTextColor="buttonTextColor"
                      :boldness="boldness"
                      :topHeaderBackgroundColor="headerBackgroundColor"
                      :topHeaderbuttonBackgroundColor="
                        headerButtonBackgroundColor
                      "
                      :topHeaderTextColor="headerTextColor"
                      :headerText="topheaderText"
                      :buttonText="buttonText"
                      :topHeaderExpertsDirectoryUrl="
                        topHeaderExpertsDirectoryUrl
                      "
                      @expertsDirectoryUrlChanged="
                        handleTopHeaderExpertsDirectoryUrlChange
                      "
                      @toggleVisibility="handleTopHeaderBarVisibilityChange"
                      @buttonTextChanged="handleButtonTextChange"
                      @fontFamilyChanged="handleFontChange"
                      @boldnessChanged="handleBoldnessChange"
                      @buttonTextColorChanged="handleButtonTextColorChange"
                      @headerBackgroundColorChanged="
                        handleHeaderBackgroundColorChange
                      "
                      @buttonBackgroundColorChanged="
                        handleButtonBackgroundColorChange
                      "
                      @headerTextColorChanged="handleHeaderTextColorChange"
                      @fontSizeChanged="handleFontSizeChange"
                      @headerTextChanged="handleHeaderTextChange"
                    />

                    <PopupModalDesign
                      :isVisible="popupModalVisible"
                      :fontFamily="popupModalfontFamily"
                      :fontSize="popupModalfontSize"
                      :boldness="popupModalBoldness"
                      :popupButtonTextColor="popupModalButtonTextColor"
                      :popupHeadingText="popupModalHeadingText"
                      :popupButtonText="popupModalButtonText"
                      :popupBodyText="popupModalBodyText"
                      :popupExpertsDirectoryUrl="popupModalExpertsDirectoryUrl"
                      :popupLeftOrientation="popupModalLeftOrientation"
                      :popupButtonBackgroundColor="
                        popupModalButtonBackgroundColor
                      "
                      :popupTextColor="popupModalTextColor"
                      :popupBannerBackgroundColor="
                        popupModalBannerBackgroundColor
                      "
                      @toggleVisibility="handlePopupModalVisibilityChange"
                      @fontFamilyChanged="handlePopupModalFontChange"
                      @boldnessChanged="handlePopupModalBoldnessChange"
                      @fontSizeChanged="handlePopupModalFontSizeChange"
                      @leftOrientationChanged="
                        handlePopupModalLeftOrientationChange
                      "
                      @buttonTextColorChanged="
                        handlePopupModalButtonTextColorChange
                      "
                      @buttonTextChanged="handlePopupModalButtonTextChange"
                      @headingTextChanged="handlePopupModalHeadingTextChange"
                      @bodyTextChanged="handlePopupModalBodyTextChange"
                      @expertsDirectoryUrlChanged="
                        handlePopupModalExpertsDirectoryUrlChange
                      "
                      @buttonBackgroundColorChanged="
                        handlePopupModalButtonBackgroundColorChange
                      "
                      @textColorChanged="handlePopupModalTextColorChanged"
                      @bannerBackgroundColorChanged="
                        handlePopupModalBannerBackgroundColorChange
                      "
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>

          <!-- get code view -->
          <div v-else><GetWidgetCode /></div>
        </div>

        <!-- footer section -->
        <footer class="flex flex-wrap justify-center mt-auto items-center">
          <FooterSection :isDisabled="isDisabled" @next="goNext" />
        </footer>
      </section>
    </div>
  </main>
</template>

<script lang="ts">
import StepsList from "@/components/expert-program/StepsList.vue";
import FooterSection from "@/components/expert-program/FooterSection.vue";
import TopHeaderBarDesign from "@/components/expert-program/widget/TopHeaderBarDesign.vue";
import TopHeaderBar from "@/components/expert-program/widget/TopHeaderBar.vue";
import PopupModal from "@/components/expert-program/widget/PopupModal.vue";
import PopupModalDesign from "@/components/expert-program/widget/PopupModalDesign.vue";
import router from "@/router";
import { computed, defineComponent, ref } from "vue";
import CurrentStep from "@/components/expert-program/CurrentStep.vue";
import GetWidgetCode from "@/components/expert-program/widget/GetWidgetCode.vue";
import {
  CLOSE_ICON_SM,
  PLUS_ICON,
  LOCK_ICON,
  BROWSER_MENU_ICON,
  ARROW_UP,
} from "@/assets/svg/shared/svg-constants";
import { googleFontFamiliesLink } from "@/data/expert-program/font-families";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";

interface WidgetConfig {
  companyId?: string;
  programId?: string;

  headerBar?: boolean;
  headerText?: string;
  buttonText?: string;

  buttonTextColor?: string;
  buttonColor?: string;

  popUp?: boolean;
  headingText?: string;
  bodyText?: string;

  style?: string;
  fontFamily?: string;
  fontSize?: string;
  textColor?: string;
  backgroundColor?: string;
  bold?: string;

  popUpOrientationLeft?: boolean;
  expertsDirectoryUrl?: string;
}

const UPDATE_WIDGET = gql`
  mutation updateWidget($input: UpdateWidgetInput!) {
    updateWidget(input: $input) {
      widgetIds
      success
      errors
    }
  }
`;

export default defineComponent({
  name: "AddWidget",
  components: {
    StepsList,
    FooterSection,
    TopHeaderBarDesign,
    GetWidgetCode,
    CurrentStep,
    PopupModal,
    PopupModalDesign,
    // BaseButton,
    TopHeaderBar,
  },
  mounted() {
    const link = document.createElement("link");
    link.href = googleFontFamiliesLink;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  },
  setup() {
    const userStore = useUserStore();
    const isDisabled = ref(false);
    const errorMessage = ref("");
    // const company = {
    //   id: "66fea575199d5db008ee0bc5",
    //   name: "Sendoso",
    //   website: "https://sendoso.com",
    // };
    // const program = { id: "66fea4fe3ec9d2b8cd20063f" };
    // const widgetCreated = ref(false);
    const widgetIds = ref<string[]>([]);
    const fontFamily = ref("Oswald");
    const boldness = ref("400");
    const buttonTextColor = ref("#121111");
    const headerBackgroundColor = ref("#121111");
    const headerButtonBackgroundColor = ref("#FFFFFF");
    const fontSize = ref("14");
    // const currentStep = ref(1);
    const topHeaderBarVisible = ref(true);
    const topHeaderExpertsDirectoryUrl = ref(
      `https://${userStore.companyWebsite}/experts`
    );
    const topheaderText = ref(
      "Hey there, want to learn more about our product?"
    );
    const buttonText = ref("Click here");
    const headerTextColor = ref("#FFFFFF");

    const popupModalVisible = ref(true);
    const popupModalfontFamily = ref("Roboto");
    const popupModalfontSize = ref("14");
    const popupModalButtonTextColor = ref("#6a48f3");
    const popupModalBoldness = ref("700");
    const popupModalButtonBackgroundColor = ref("#FFFFFF");
    const popupModalTextColor = ref("#FFFFFF");
    const popupModalBannerBackgroundColor = ref("#6a48f3");
    const popupModalButtonText = ref("Button");
    const popupModalHeadingText = ref("Heading");
    const popupModalBodyText = ref("Lorem ipsum dolor sit amet");
    const popupModalExpertsDirectoryUrl = ref(
      `https://${userStore.companyWebsite}/experts`
    );
    const popupModalLeftOrientation = ref(true);

    const {
      mutate: updateWidget,
      onDone: updateWidgetDone,
      onError: updateWidgetError,
    } = useMutation(UPDATE_WIDGET);

    const goNext = () => {
      errorMessage.value = "";
      if (userStore.createExpertProgram?.widgetCreated) {
        router.push({ name: "AddIntegration" });
      } else if (topHeaderBarVisible.value || popupModalVisible.value) {
        isDisabled.value = true;
        callUpdateWidgetMutation();
      } else {
        router.push({ name: "AddIntegration" });
      }
    };

    const callUpdateWidgetMutation = () => {
      let widgetParams: WidgetConfig[] = [];

      if (topHeaderBarVisible.value) {
        prepareTopHeaderBarPayload(widgetParams);
      }

      if (popupModalVisible.value) {
        preparePopupModalPayload(widgetParams);
      }

      updateWidget({
        input: {
          widgetParams,
        },
      });

      updateWidgetDone((response) => {
        if (response) {
          isDisabled.value = false;
          const data = response.data.updateWidget;
          if (data.errors.length > 0) {
            errorMessage.value = data.errors.join(", ");
          } else {
            userStore.updateWidgetCreated(true);
            console.log("Widget created ", data);
            // widgetIds.value = data.widgetIds;
            data.widgetIds.forEach((widgetId: string) => {
              userStore.updateCreatedWidgetIds(widgetId);
            });
          }
        }
      });

      updateWidgetError((error) => {
        isDisabled.value = false;
        console.log("Error updating widget ", error);
        errorMessage.value = error.message;
        scrollToTop();
      });
    };

    const prepareTopHeaderBarPayload = (widgetParams: WidgetConfig[]) => {
      widgetParams.push({
        companyId: userStore.createExpertProgram?.companyId,
        programId: userStore.createExpertProgram?.id,
        headerBar: true,
        headerText: topheaderText.value,
        buttonText: buttonText.value,
        buttonTextColor: buttonTextColor.value,
        buttonColor: headerButtonBackgroundColor.value,
        style: "top header bar",
        fontFamily: fontFamily.value,
        fontSize: fontSize.value,
        textColor: headerTextColor.value,
        backgroundColor: headerBackgroundColor.value,
        bold: boldness.value,
        expertsDirectoryUrl: topHeaderExpertsDirectoryUrl.value,
      });
    };

    const preparePopupModalPayload = (widgetParams: WidgetConfig[]) => {
      widgetParams.push({
        companyId: userStore.createExpertProgram?.companyId,
        programId: userStore.createExpertProgram?.id,
        popUp: true,

        headingText: popupModalHeadingText.value,
        bodyText: popupModalBodyText.value,
        style: "popup modal",
        fontFamily: popupModalfontFamily.value,
        fontSize: popupModalfontSize.value,
        textColor: popupModalTextColor.value,
        buttonTextColor: popupModalButtonTextColor.value,
        buttonColor: popupModalButtonBackgroundColor.value,
        backgroundColor: popupModalBannerBackgroundColor.value,
        buttonText: popupModalButtonText.value,
        bold: popupModalBoldness.value,
        expertsDirectoryUrl: popupModalExpertsDirectoryUrl.value,
        popUpOrientationLeft: popupModalLeftOrientation.value,
      });
    };

    const handleHeaderBackgroundColorChange = (color: string) => {
      headerBackgroundColor.value = color;
      console.log("Background color changed", headerBackgroundColor.value);
    };

    const handleFontChange = (font: string) => {
      fontFamily.value = font;
    };

    const handleBoldnessChange = (weight: string) => {
      boldness.value = weight;
    };

    const handleButtonTextColorChange = (color: string) => {
      buttonTextColor.value = color;
    };

    const handleButtonBackgroundColorChange = (color: string) => {
      headerButtonBackgroundColor.value = color;
    };

    const handleFontSizeChange = (size: string) => {
      fontSize.value = size;
    };

    const handleHeaderTextChange = (text: string) => {
      topheaderText.value = text;
    };

    const handleButtonTextChange = (text: string) => {
      buttonText.value = text;
    };

    const handleHeaderTextColorChange = (color: string) => {
      headerTextColor.value = color;
    };

    const handleTopHeaderBarVisibilityChange = () => {
      topHeaderBarVisible.value = !topHeaderBarVisible.value;
    };

    const handleTopHeaderExpertsDirectoryUrlChange = (url: string) => {
      topHeaderExpertsDirectoryUrl.value = url;
    };

    const handlePopupModalVisibilityChange = () => {
      popupModalVisible.value = !popupModalVisible.value;
    };

    const handlePopupModalButtonTextChange = (text: string) => {
      popupModalButtonText.value = text;
    };

    const handlePopupModalFontChange = (font: string) => {
      popupModalfontFamily.value = font;
    };

    const handlePopupModalBoldnessChange = (weight: string) => {
      popupModalBoldness.value = weight;
    };

    const handlePopupModalButtonTextColorChange = (color: string) => {
      popupModalButtonTextColor.value = color;
    };

    const handlePopupModalTextColorChange = (color: string) => {
      popupModalTextColor.value = color;
    };

    const handlePopupModalFontSizeChange = (size: string) => {
      popupModalfontSize.value = size;
    };

    const handlePopupModalHeadingTextChange = (text: string) => {
      popupModalHeadingText.value = text;
    };

    const handlePopupModalBodyTextChange = (text: string) => {
      popupModalBodyText.value = text;
    };

    const handlePopupModalButtonBackgroundColorChange = (color: string) => {
      popupModalButtonBackgroundColor.value = color;
    };

    const handlePopupModalBannerBackgroundColorChange = (color: string) => {
      popupModalBannerBackgroundColor.value = color;
    };

    const handlePopupModalTextColorChanged = (color: string) => {
      popupModalTextColor.value = color;
    };

    const handlePopupModalExpertsDirectoryUrlChange = (url: string) => {
      popupModalExpertsDirectoryUrl.value = url;
    };

    const handlePopupModalLeftOrientationChange = (orientation: boolean) => {
      popupModalLeftOrientation.value = orientation;
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const currentStep = computed(() =>
      userStore.createExpertProgram?.widgetCreated ? 2 : 1
    );

    return {
      isDisabled,
      widgetIds,
      goNext,
      buttonText,
      CLOSE_ICON_SM,
      PLUS_ICON,
      LOCK_ICON,
      BROWSER_MENU_ICON,
      ARROW_UP,
      // widgetCreated,
      handleFontChange,
      handleBoldnessChange,
      handleButtonTextColorChange,
      handleHeaderBackgroundColorChange,
      handleButtonBackgroundColorChange,
      handleFontSizeChange,
      fontFamily,
      boldness,
      buttonTextColor,
      headerBackgroundColor,
      headerButtonBackgroundColor,
      topHeaderExpertsDirectoryUrl,
      fontSize,
      errorMessage,
      currentStep,
      userStore,
      topHeaderBarVisible,
      handleTopHeaderBarVisibilityChange,
      topheaderText,
      handleHeaderTextChange,
      handleButtonTextChange,
      handleHeaderTextColorChange,
      handleTopHeaderExpertsDirectoryUrlChange,
      headerTextColor,
      popupModalVisible,
      popupModalfontFamily,
      popupModalfontSize,
      popupModalButtonTextColor,
      popupModalBoldness,
      popupModalTextColor,
      popupModalButtonText,
      popupModalHeadingText,
      popupModalBodyText,
      popupModalBannerBackgroundColor,
      popupModalButtonBackgroundColor,
      popupModalExpertsDirectoryUrl,
      popupModalLeftOrientation,
      handlePopupModalVisibilityChange,
      handlePopupModalButtonTextChange,
      handlePopupModalFontChange,
      handlePopupModalBoldnessChange,
      handlePopupModalButtonTextColorChange,
      handlePopupModalTextColorChange,
      handlePopupModalFontSizeChange,
      handlePopupModalHeadingTextChange,
      handlePopupModalBodyTextChange,
      handlePopupModalButtonBackgroundColorChange,
      handlePopupModalTextColorChanged,
      handlePopupModalBannerBackgroundColorChange,
      handlePopupModalExpertsDirectoryUrlChange,
      handlePopupModalLeftOrientationChange,
    };
  },
});
</script>
