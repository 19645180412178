<template>
  <div class="overflow-x-auto w-full">
    <div class="flex justify-center mb-4">
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
        style="max-height: 80px; overflow-y: auto"
      >
        <!-- Icon Container -->
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <!-- Custom Image Icon -->
          <img
            src="../../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
    <table class="min-w-full border border-gray-300">
      <thead class="bg-gray-150 justify-between">
        <tr>
          <th
            class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
          >
            NAME
          </th>
          <th
            class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
          >
            STATUS
          </th>
          <th
            class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
          >
            ACTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in companyUsersRows"
          :key="row.id"
          class="hover:bg-gray-50 text-left"
          :class="{
            'opacity-50': row.active === false,
          }"
        >
          <td
            class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
          >
            <div class="flex flex-col">
              <span class="font-bold"
                >{{ row.firstName }} {{ row.lastName }}</span
              >
              <span> {{ row.email }} </span>
            </div>
          </td>
          <!-- <td
            class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
          >
            Accepted
          </td> -->
          <td class="px-4 py-2 font-medium text-xs leading-4 border-b">
            <span
              class="px-2 rounded-lg"
              :class="{
                'text-green-800 bg-green-100': row.active === true,
                'text-red-800 bg-red-100': row.active === false,
              }"
              >{{ row.active ? "Accepted" : "Disabled" }}</span
            >
          </td>
          <td class="px-4 py-2 border-b">
            <BaseButton
              @click="handleDeleteClick(index, 'companyUser')"
              :disabled="row.active === false"
              class="px-4 py-1 text-red-600 text-sm font-medium leading-5 bg-red-50 border border-red-600 rounded-md"
            >
              <span v-html="DELETE_ICON" class="text-red-600"></span>
            </BaseButton>
          </td>
        </tr>
        <tr
          v-for="(row, index) in invitationsRows"
          :key="row.id"
          class="hover:bg-gray-50 text-left"
          :class="{
            'opacity-50': row.status === 'expired',
          }"
        >
          <td
            class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
          >
            {{ row.userEmail }}
          </td>
          <!-- <td
            class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
          >
            {{ row.dueDate }}
          </td> -->
          <td class="px-4 py-2 font-medium text-xs leading-4 border-b">
            <span class="px-2 rounded-lg text-gray-800 bg-gray-200">{{
              capitalizeFirstLetter(row.status)
            }}</span>
          </td>
          <td class="px-4 py-2 border-b">
            <BaseButton
              @click="handleDeleteClick(index, 'invitation')"
              :disabled="isLoading || row.status === 'expired'"
              class="px-4 py-1 text-red-600 text-sm font-medium leading-5 bg-red-50 border border-red-600 rounded-md"
            >
              <span v-html="DELETE_ICON" class="text-red-600"></span>
            </BaseButton>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import BaseButton from "@/components/shared/BaseButton.vue";
import { computed, defineComponent, ref, watch } from "vue";
import { DELETE_ICON } from "@/assets/svg/shared/svg-constants";

interface companyUsersEdge {
  node: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    active: boolean;
  };
}

interface companyUsers {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  active: boolean;
}

interface invitationsEdge {
  node: {
    id: string;
    status: string;
    userEmail: string;
  };
}

interface invitations {
  id: string;
  status: string;
  userEmail: string;
}

const GET_TEAM_MEMBERS = gql`
  query getCompanyTeamManagement {
    getCompanyTeamManagement {
      companyUsers {
        edges {
          node {
            id
            email
            firstName
            lastName
            active
          }
        }
      }
      invitations {
        edges {
          node {
            id
            status
            userEmail
          }
        }
      }
    }
  }
`;

const DELETE_TEAM_MEMBER = gql`
  mutation deleteCompanyUser($input: DeleteCompanyUserInput!) {
    deleteCompanyUser(input: $input) {
      success
    }
  }
`;

const EXPIRE_INVITATION = gql`
  mutation expireInvitation($input: ExpireInvitationInput!) {
    expireInvitation(input: $input) {
      success
    }
  }
`;

export default defineComponent({
  name: "TeamMembersList",
  components: {
    BaseButton,
  },
  props: {
    newInvitesSent: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const companyUsersRows = ref<companyUsers[]>([]);
    const invitationsRows = ref<invitations[]>([]);
    // const disableDeleteButton = ref<boolean>(false);
    const errorMessage = ref<string>("");

    const {
      result: queryTeamMembersResult,
      error: queryTeamMembersError,
      refetch: refetchTeamMembers,
    } = useQuery(GET_TEAM_MEMBERS);

    const {
      mutate: deleteCompanyUser,
      onDone: deleteCompanyUserDone,
      onError: deleteCompanyUserError,
      loading: isDeleteCompanyUserLoading,
    } = useMutation(DELETE_TEAM_MEMBER);

    const {
      mutate: expireInvitation,
      onDone: expireInvitationDone,
      onError: expireInvitationError,
      loading: isExpireInvitationLoading,
    } = useMutation(EXPIRE_INVITATION);

    refetchTeamMembers();

    watch(
      () => queryTeamMembersResult.value,
      (newValue) => {
        if (newValue) {
          companyUsersRows.value =
            newValue.getCompanyTeamManagement.companyUsers.edges.map(
              (edge: companyUsersEdge) => ({
                id: edge.node.id,
                email: edge.node.email,
                firstName: edge.node.firstName,
                lastName: edge.node.lastName,
                active: edge.node.active,
              })
            );

          invitationsRows.value = [];
          newValue.getCompanyTeamManagement.invitations.edges.forEach(
            (edge: invitationsEdge) => {
              if (edge.node.status === "accepted") {
                return;
              }

              invitationsRows.value.push({
                id: edge.node.id,
                status: edge.node.status,
                userEmail: edge.node.userEmail,
              });
            }
          );

          console.log("invitationsRows", invitationsRows.value);

          emit(
            "teamMembersCount",
            companyUsersRows.value.length + invitationsRows.value.length
          );
        }
      },
      { immediate: true }
    );

    watch(
      () => queryTeamMembersError.value,
      (newError) => {
        if (newError) {
          setErrorMessage(newError.message);
        }
      },
      { immediate: true }
    );

    watch(
      () => props.newInvitesSent,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          refetchTeamMembers();
        }
      },
      { immediate: true }
    );

    const capitalizeFirstLetter = (str: string) => {
      return str[0].toUpperCase() + str.slice(1).toLowerCase();
    };

    const handleDeleteClick = (index: number, type: string) => {
      if (type === "companyUser") {
        handleDeleteCompanyUser(index);
      } else {
        handleExpireInvitation(index);
      }
    };

    const handleDeleteCompanyUser = (index: number) => {
      deleteCompanyUser({
        input: { companyUserId: companyUsersRows.value[index].id },
      });

      deleteCompanyUserDone((response) => {
        if (response.data.deleteCompanyUser.success) {
          companyUsersRows.value[index].active = false;
        }
      });

      deleteCompanyUserError((error) => {
        setErrorMessage(error.message);
      });
    };

    const handleExpireInvitation = (index: number) => {
      console.log("expire invitation triggered");
      expireInvitation({ input: { id: invitationsRows.value[index].id } });

      expireInvitationDone((response) => {
        if (response.data.expireInvitation.success) {
          invitationsRows.value[index].status = "expired";
        }
      });

      expireInvitationError((error) => {
        setErrorMessage(error.message);
      });
    };

    const setErrorMessage = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 2500);
    };

    const isLoading = computed(() => {
      return (
        isDeleteCompanyUserLoading.value || isExpireInvitationLoading.value
      );
    });

    return {
      companyUsersRows,
      invitationsRows,
      handleDeleteClick,
      capitalizeFirstLetter,
      errorMessage,
      DELETE_ICON,
      isLoading,
    };
  },
});
</script>
