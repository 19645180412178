<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <div
      v-if="false"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div v-else class="flex flex-grow max-md:flex-col">
      <aside
        class="flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div class="flex flex-col mt-14 flex-grow">
          <div class="flex flex-col ml-10 text-left">
            <span class="text-white text-3xl font-semibold leading-9">
              Create An <br />
              Expert Program
            </span>
            <span
              class="text-white text-base font-normal leading-6 font-sans mt-3"
            >
              Start setting up an expert program</span
            >
          </div>

          <!-- Third Image, or Footer Image -->
          <StepsList :currentStep="5" />
        </div>
      </aside>
      <main class="flex flex-col w-full md:w-4/5 justify-start pt-12">
        <div
          class="flex flex-col items-start w-full gap-3 px-6 sm:px-24 text-left"
        >
          <div class="flex flex-col mb-1">
            <span
              class="text-3xl leading-9 font-semibold text-neutral-800 font-inter"
              >Invite Experts</span
            >
            <span class="text-sm leading-5 font-medium text-neutral-800"
              >Almost done! Invite experts to the program</span
            >
          </div>

          <!-- Error div -->
          <div class="flex justify-center mb-4">
            <div
              v-if="errorMessage"
              role="alert"
              class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
              style="max-height: 80px; overflow-y: auto"
            >
              <!-- Icon Container -->
              <div
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <!-- Custom Image Icon -->
                <img
                  src="../../assets/shared/failure.svg"
                  alt="Error Icon"
                  class="w-4 h-4"
                />
              </div>
              <p>{{ errorMessage }}</p>
            </div>
          </div>

          <div class="flex flex-col">
            <span class="text-sm leading-5 font-medium text-neutral-800"
              >Invite Email</span
            >
          </div>
          <!-- email input field -->
          <div class="flex flex-col bg-white rounded-lg p-0.5 w-full">
            <BaseInput
              id="email"
              v-model="email"
              type="email"
              @keyup.enter="addEmail()"
              @input="handleEmailInput()"
              placeholder="E.g: you@example.com"
              :className="{
                'flex-grow px-3.5 py-2.5 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
                'border-red-500': emailWarning,
                'border-gray-300': !emailWarning,
              }"
            />
            <span
              v-if="emailWarning"
              class="text-xs mt-1 text-gray-400 text-red-500"
            >
              {{ emailWarningMessage }}
            </span>
          </div>

          <!-- email cards and note and send invite button -->
          <InviteEmails
            :emailsList="emailsList"
            :sendInviteLoading="sendInviteLoading"
            :sentSuccessfully="sentSuccessfully"
            :inviteSentMessage="inviteSentMessage"
            @remove-email="removeEmail"
            @send-invite="callSendInvite"
          />

          <!-- invite via link -->
          <div class="flex flex-col">
            <div class="flex flex-wrap justify-center items-center gap-2">
              <span class="text-sm leading-5 font-medium text-neutral-800"
                >Or Invite via Link</span
              >
              <BaseButton
                type="button"
                class="flex justify-center items-center py-2 px-8 rounded-md text-sm font-medium text-white"
                style="width: auto"
                :class="{
                  'bg-indigo-100': inviteLink !== '',
                  'bg-gray-300': inviteLink === '',
                }"
                :disabled="inviteLink === ''"
                @click="copyToClipboard()"
              >
                <div
                  class="flex items-center"
                  :class="{
                    'text-primary-purple': inviteLink !== '',
                    'text-gray-500': inviteLink === '',
                  }"
                >
                  <div v-html="NOTE_ICON" class="mr-2"></div>
                  <span>Generate Link</span>
                </div>
              </BaseButton>
              <transition
                enter-active-class="transition ease-in duration-300"
                leave-active-class="transition ease-out duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div class="flex flex-col mx-2" v-if="inviteLinkCopied">
                  <span
                    class="text-sm leading-5 font-medium text-primary-purple"
                  >
                    Link Copied!
                  </span>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <!-- footer section -->
        <footer class="flex flex-wrap justify-center mt-auto items-center">
          <FooterSection :isDisabled="isDisabled" @next="goNext" />
        </footer>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import {
  HOLLOW_CHECK_CIRCLE,
  NOTE_ICON,
} from "@/assets/svg/shared/svg-constants";
import BaseInput from "@/components/shared/BaseInput.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import StepsList from "@/components/expert-program/StepsList.vue";
import FooterSection from "@/components/expert-program/FooterSection.vue";
import InviteEmails from "@/components/expert-program/invite-experts/InviteEmails.vue";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";
import { useRouter } from "vue-router";

const GENERATE_LINK_MUTATION = gql`
  mutation generateInviteLink($input: GenerateInviteLinkInput!) {
    generateInviteLink(input: $input) {
      invitation {
        inviteUrl
      }
      errors
    }
  }
`;

const SEND_INVITE_MUTATION = gql`
  mutation createInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      successMessage
      errors
    }
  }
`;

export default defineComponent({
  name: "SalesforceIntegrationView",
  components: {
    StepsList,
    FooterSection,
    BaseInput,
    BaseButton,
    InviteEmails,
  },
  setup() {
    const email = ref<string>("");
    const emailWarning = ref<boolean>(false);
    const emailsList = ref<string[]>([]);
    const emailWarningMessage = ref<string>("");
    const note = ref<string>("");
    const isDisabled = ref<boolean>(false);
    const errorMessage = ref<string>("");
    const inviteLink = ref<string>("");
    const inviteLinkCopied = ref<boolean>(false);
    const sentSuccessfully = ref<boolean>(false);
    const userStore = useUserStore();
    const router = useRouter();

    const {
      mutate: generateLink,
      onDone: generateLinkDone,
      onError: generateLinkError,
    } = useMutation(GENERATE_LINK_MUTATION);

    const {
      mutate: sendInvite,
      onDone: sendInviteDone,
      onError: sendInviteError,
      loading: sendInviteLoading,
    } = useMutation(SEND_INVITE_MUTATION);

    onMounted(() => {
      callGenerateLink();
    });

    const callGenerateLink = () => {
      generateLink({
        input: {
          inviteParams: {
            programId: userStore.createExpertProgram?.id,
          },
        },
      });

      generateLinkDone((response) => {
        if (response) {
          const data = response.data.generateInviteLink;
          if (data.errors.length > 0) {
            errorMessage.value = data.errors.join(". ");
          } else {
            inviteLink.value = data.invitation.inviteUrl;
          }
        }
      });

      generateLinkError((error: ApolloError) => {
        errorMessage.value = error.message;
      });
    };

    const callSendInvite = () => {
      const valid = validateEmailList();
      if (!valid) {
        return;
      }

      sendInvite({
        input: {
          inviteParams: {
            programId: userStore.createExpertProgram?.id,
            note: note.value,
            emails: emailsList.value,
          },
          inviteType: "experts",
        },
      });

      sendInviteDone((response) => {
        if (response) {
          clearErrorMessages();
          const data = response.data.createInvitation;
          if (data.errors.length > 0) {
            errorMessage.value = data.errors.join(". ");
          } else {
            handleSentSuccessfully();
          }
        }
      });

      sendInviteError((error: ApolloError) => {
        console.log("error is for send invite", error);
        errorMessage.value = error.message;
      });
    };

    function copyToClipboard() {
      navigator.clipboard.writeText(inviteLink.value).then(
        () => {
          inviteLinkCopied.value = true;
          setTimeout(() => {
            inviteLinkCopied.value = false;
          }, 2000);
        },
        (error) => {
          errorMessage.value = error.message;
        }
      );
    }

    const validateEmailList = () => {
      if (emailsList.value.length === 0) {
        errorMessage.value = "Please add at least one email";
        return false;
      }
      return true;
    };

    const handleSentSuccessfully = () => {
      emailsList.value = [];
      sentSuccessfully.value = true;
      setTimeout(() => {
        sentSuccessfully.value = false;
      }, 2000);
    };

    const goNext = () => {
      console.log("goNext");
      userStore.clearUpdateExpertProgram();
      router.push({ name: "ExpertPrograms" });
    };

    const validEmail = (email: string) => {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return pattern.test(email);
    };

    const clearErrorMessages = () => {
      if (errorMessage.value !== "") {
        errorMessage.value = "";
      }
    };

    const addEmail = () => {
      clearErrorMessages();
      if (!validEmail(email.value)) {
        emailWarning.value = true;
        emailWarningMessage.value = "Invalid email";
      } else if (emailsList.value.includes(email.value)) {
        emailWarning.value = true;
        emailWarningMessage.value = "Email already added";
      } else {
        emailsList.value.push(email.value);
        email.value = "";
      }
    };

    const removeEmail = (index: number) => {
      clearErrorMessages();
      emailsList.value.splice(index, 1);
    };

    const handleEmailInput = () => {
      emailWarning.value = false;
      emailWarningMessage.value = "";
      clearErrorMessages();
    };

    const inviteSentMessage = () => {
      if (sentSuccessfully.value) {
        return "Invite Sent Successfully!";
      } else if (sendInviteLoading.value) {
        return "Sending...";
      } else {
        return "";
      }
    };

    return {
      HOLLOW_CHECK_CIRCLE,
      NOTE_ICON,
      email,
      emailWarning,
      emailsList,
      addEmail,
      removeEmail,
      emailWarningMessage,
      goNext,
      isDisabled,
      errorMessage,
      note,
      callGenerateLink,
      callSendInvite,
      handleEmailInput,
      sendInviteLoading,
      inviteLinkCopied,
      sentSuccessfully,
      inviteSentMessage,
      copyToClipboard,
      inviteLink,
    };
  },
});
</script>
