import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center mt-4 mb-2 bg-white rounded-xl border border-gray-300 border-solid" }
const _hoisted_2 = { class: "flex flex-col justify-center w-full rounded-xl" }
const _hoisted_3 = { class: "flex gap-10 justify-between items-center px-6 py-5 rounded-xl w-full bg-slate-50" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col w-full px-6 pb-5 rounded-xl max-md:px-5",
  ref: "collapsible"
}
const _hoisted_6 = { class: "flex flex-col mb-4 mt-4 text-left pb-4" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "flex flex-row mt-3 gap-2" }
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "flex flex-col mt-1" }
const _hoisted_12 = { class: "flex max-w-max mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1" }
const _hoisted_13 = { class: "ml-2" }
const _hoisted_14 = { class: "flex flex-col mt-1" }
const _hoisted_15 = { class: "flex max-w-max mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1" }
const _hoisted_16 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[11] || (_cache[11] = _createElementVNode("h3", { class: "self-stretch my-auto text-sm font-semibold text-black" }, " Cards Styling ", -1)),
        _createElementVNode("button", {
          class: "flex justify-between items-center self-stretch my-auto w-5",
          "aria-label": "Toggle cards styling",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapse && _ctx.toggleCollapse(...args)))
        }, [
          _createElementVNode("div", {
            innerHTML: _ctx.isCollapsed ? _ctx.ARROW_DOWN : _ctx.ARROW_UP
          }, null, 8, _hoisted_4)
        ])
      ]),
      _createVNode(_Transition, {
        onBeforeEnter: _ctx.beforeEnter,
        onEnter: _ctx.enter,
        onLeave: _ctx.leave
      }, {
        default: _withCtx(() => [
          (!_ctx.isCollapsed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "selectedFontFamily",
                    text: "Font",
                    class: "font-inter text-sm font-medium leading-normal tracking-normal"
                  }),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFontFamily) = $event)),
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.applyFontFamily($event))),
                    class: "border rounded px-2 py-1"
                  }, [
                    _cache[12] || (_cache[12] = _createElementVNode("option", {
                      value: "",
                      disabled: ""
                    }, "Select Font", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fonts, (font) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: font,
                        value: font
                      }, _toDisplayString(font), 9, _hoisted_7))
                    }), 128))
                  ], 544), [
                    [_vModelSelect, _ctx.selectedFontFamily]
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedBoldness) = $event)),
                      onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.applyBoldness($event))),
                      class: "border rounded py-1 w-1/2"
                    }, [
                      _cache[13] || (_cache[13] = _createElementVNode("option", {
                        value: "",
                        disabled: ""
                      }, "Select Boldness", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fontWeights, (weight, index) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: index,
                          value: weight.value
                        }, _toDisplayString(weight.label), 9, _hoisted_9))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, _ctx.selectedBoldness]
                    ]),
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedFontSize) = $event)),
                      onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.applyFontSize($event))),
                      class: "border rounded py-1 w-1/2"
                    }, [
                      _cache[14] || (_cache[14] = _createElementVNode("option", {
                        value: "",
                        disabled: ""
                      }, "Select Font Size", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fontSizes, (size) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: size,
                          value: size
                        }, _toDisplayString(size), 9, _hoisted_10))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, _ctx.selectedFontSize]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "textColor",
                      text: "Button Text Color",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal mt-5"
                    }),
                    _createElementVNode("div", _hoisted_12, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.textColor) = $event)),
                        type: "color",
                        onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.applyTextColor($event))),
                        class: "w-7 h-7 shrink-0"
                      }, null, 544), [
                        [_vModelText, _ctx.textColor]
                      ]),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.textColor), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "backgroundColor",
                      text: "Button Background Color",
                      class: "font-inter text-sm font-medium leading-normal tracking-normal mt-5"
                    }),
                    _createElementVNode("div", _hoisted_15, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.backgroundColor) = $event)),
                        type: "color",
                        onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.applyBackgroundColor($event))),
                        class: "w-7 h-7 shrink-0"
                      }, null, 544), [
                        [_vModelText, _ctx.backgroundColor]
                      ]),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.backgroundColor), 1)
                    ])
                  ])
                ])
              ], 512))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onBeforeEnter", "onEnter", "onLeave"])
    ])
  ]))
}