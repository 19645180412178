<template>
  <div
    class="flex flex-col justify-center mt-4 mb-2 bg-white rounded-xl border border-gray-300 border-solid"
  >
    <div class="flex flex-col justify-center w-full rounded-xl">
      <div
        class="flex gap-10 justify-between items-center px-6 py-5 rounded-xl w-full bg-slate-50"
      >
        <h3 class="self-stretch my-auto text-sm font-semibold text-black">
          Cards Styling
        </h3>
        <button
          class="flex justify-between items-center self-stretch my-auto w-5"
          aria-label="Toggle cards styling"
          @click="toggleCollapse"
        >
          <div v-html="isCollapsed ? ARROW_DOWN : ARROW_UP"></div>
        </button>
      </div>

      <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div
          class="flex flex-col w-full px-6 pb-5 rounded-xl max-md:px-5"
          v-if="!isCollapsed"
          ref="collapsible"
        >
          <div class="flex flex-col mb-4 mt-4 text-left pb-4">
            <BaseLabel
              forAttr="selectedFontFamily"
              text="Font"
              class="font-inter text-sm font-medium leading-normal tracking-normal"
            />
            <select
              v-model="selectedFontFamily"
              @change="applyFontFamily($event)"
              class="border rounded px-2 py-1"
            >
              <option value="" disabled>Select Font</option>
              <option v-for="font in fonts" :key="font" :value="font">
                {{ font }}
              </option>
            </select>

            <div class="flex flex-row mt-3 gap-2">
              <select
                v-model="selectedBoldness"
                @change="applyBoldness($event)"
                class="border rounded py-1 w-1/2"
              >
                <option value="" disabled>Select Boldness</option>
                <option
                  v-for="(weight, index) in fontWeights"
                  :key="index"
                  :value="weight.value"
                >
                  {{ weight.label }}
                </option>
              </select>

              <select
                v-model="selectedFontSize"
                @change="applyFontSize($event)"
                class="border rounded py-1 w-1/2"
              >
                <option value="" disabled>Select Font Size</option>
                <option v-for="size in fontSizes" :key="size" :value="size">
                  {{ size }}
                </option>
              </select>
            </div>

            <div class="flex flex-col mt-1">
              <BaseLabel
                forAttr="textColor"
                text="Button Text Color"
                class="font-inter text-sm font-medium leading-normal tracking-normal mt-5"
              />

              <div
                class="flex max-w-max mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
              >
                <input
                  v-model="textColor"
                  type="color"
                  @input="applyTextColor($event)"
                  class="w-7 h-7 shrink-0"
                />
                <span class="ml-2">{{ textColor }}</span>
              </div>
            </div>

            <div class="flex flex-col mt-1">
              <!-- Background Color Selector -->
              <BaseLabel
                forAttr="backgroundColor"
                text="Button Background Color"
                class="font-inter text-sm font-medium leading-normal tracking-normal mt-5"
              />

              <div
                class="flex max-w-max mt-1 items-center border border-gray-300 bg-white overflow-hidden rounded px-2 py-1"
              >
                <input
                  v-model="backgroundColor"
                  type="color"
                  @input="applyBackgroundColor($event)"
                  class="w-7 h-7 shrink-0"
                />
                <span class="ml-2">{{ backgroundColor }}</span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, ref } from "vue";
import { useEditor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import { Mark } from "@tiptap/core";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import { ARROW_DOWN, ARROW_UP } from "@/assets/svg/shared/svg-constants";
import { fontFamilies } from "@/data/expert-program/font-families";

const Boldness = Mark.create({
  name: "boldness",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          boldness: {
            default: null,
            parseHTML: (element) => element.style.fontWeight || null,
            renderHTML: (attributes) => {
              if (!attributes.boldness) {
                return {};
              }
              return { style: `font-weight: ${attributes.boldness};` };
            },
          },
        },
      },
    ];
  },
});

const FontFamily = Mark.create({
  name: "fontFamily",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: (element) =>
              element.style.fontFamily.replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (!attributes.fontFamily) {
                return {};
              }
              return { style: `font-family: ${attributes.fontFamily}` };
            },
          },
        },
      },
    ];
  },
});

const FontSize = Mark.create({
  name: "fontSize",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) => element.style.fontSize.replace(/px/, ""),
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }
              return { style: `font-size: ${attributes.fontSize}px` };
            },
          },
        },
      },
    ];
  },
});

const TextColor = Mark.create({
  name: "textColor",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: (element) => element.style.color,
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }
              return { style: `color: ${attributes.color}` };
            },
          },
        },
      },
    ];
  },
});

const BackgroundColor = Mark.create({
  name: "backgroundColor",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          backgroundColor: {
            default: null,
            parseHTML: (element) => element.style.backgroundColor,
            renderHTML: (attributes) => {
              if (!attributes.backgroundColor) {
                return {};
              }
              return {
                style: `background-color: ${attributes.backgroundColor}`,
              };
            },
          },
        },
      },
    ];
  },
});

export default defineComponent({
  name: "CardStyling",
  components: {
    BaseLabel,
  },
  props: {
    fontFamily: {
      type: String,
      default: "",
    },
    boldness: {
      type: String,
      default: "",
    },
    buttonTextColor: {
      type: String,
      default: "",
    },
    buttonBackgroundColor: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "",
    },
  },
  emits: [
    "fontFamilyChanged",
    "boldnessChanged",
    "fontSizeChanged",
    "buttonTextColorChanged",
    "buttonBackgroundColorChanged",
  ],
  setup(props, { emit }) {
    const editor = useEditor({
      extensions: [
        StarterKit,
        TextStyle,
        FontFamily,
        FontSize,
        TextColor,
        BackgroundColor,
        Boldness,
      ],
    });

    const fonts = fontFamilies;

    const fontSizes = [
      "12",
      "14",
      "16",
      "18",
      "20",
      // "24",
      // "28",
      // "32",
      // "36",
      // "40",
    ];

    const fontWeights = [
      // { value: "100", label: "Thin" },
      // { value: "200", label: "Extra Light" },
      // { value: "300", label: "Light" },
      { value: "400", label: "Regular" },
      // { value: "500", label: "Medium" },
      // { value: "600", label: "Semi-Bold" },
      { value: "700", label: "Bold" },
      // { value: "800", label: "Extra Bold" },
    ];

    const selectedFontFamily = ref(props.fontFamily);
    const selectedFontSize = ref(props.fontSize);
    const textColor = ref(props.buttonTextColor);
    const backgroundColor = ref(props.buttonBackgroundColor);
    const selectedBoldness = ref(props.boldness);
    const isCollapsed = ref(true);

    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const applyFontFamily = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const font = target.value;
      selectedFontFamily.value = font;
      emit("fontFamilyChanged", font);
      if (font) {
        editor.value
          ?.chain()
          .focus()
          .setMark("fontFamily", { fontFamily: font })
          .run();
      }
    };

    const applyFontSize = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const size = target.value;
      selectedFontSize.value = size;
      emit("fontSizeChanged", size);
      if (size) {
        editor.value
          ?.chain()
          .focus()
          .setMark("fontSize", { fontSize: size })
          .run();
      }
    };

    const applyBoldness = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      const boldness = target.value;
      selectedBoldness.value = boldness;
      emit("boldnessChanged", boldness);
      if (boldness) {
        editor.value?.chain().focus().setMark("boldness", { boldness }).run();
      }
    };

    const applyTextColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      textColor.value = target.value;
      emit("buttonTextColorChanged", textColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("textColor", { color: textColor.value })
        .run();
    };

    const applyBackgroundColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      backgroundColor.value = target.value;
      emit("buttonBackgroundColorChanged", backgroundColor.value);
      editor.value
        ?.chain()
        .focus()
        .setMark("backgroundColor", { backgroundColor: backgroundColor.value })
        .run();
    };

    const beforeEnter = (el: Element) => {
      const target = el as HTMLElement;
      target.style.height = "0";
      target.style.opacity = "0";
    };

    const enter = (el: Element) => {
      const target = el as HTMLElement;
      const height = target.scrollHeight;
      target.style.transition = "height 0.4s ease, opacity 0.4s ease";
      target.style.height = height + "px";
      target.style.opacity = "1";
    };

    const leave = (el: Element) => {
      const target = el as HTMLElement;
      target.style.transition = "height 0.4s ease, opacity 0.4s ease";
      target.style.height = "0";
      target.style.opacity = "0";
    };

    onBeforeUnmount(() => {
      editor.value?.destroy();
    });

    return {
      editor,
      applyFontFamily,
      applyFontSize,
      applyTextColor,
      applyBackgroundColor,
      applyBoldness,
      fonts,
      fontSizes,
      fontWeights,
      textColor,
      backgroundColor,
      selectedFontSize,
      selectedFontFamily,
      selectedBoldness,
      ARROW_DOWN,
      ARROW_UP,
      isCollapsed,
      toggleCollapse,
      beforeEnter,
      enter,
      leave,
    };
  },
});
</script>
