<template>
  <div
    class="flex items-center border border-gray-300 rounded-md w-full overflow-hidden"
  >
    <span class="pl-3 pr-1 text-gray-500 whitespace-nowrap">
      {{ leadingText }}
    </span>

    <input
      type="text"
      v-model="inputValue"
      :placeholder="placeholder"
      class="flex-1 pl-1 pr-3 py-2 outline-none text-gray-500 min-w-0"
      @input="formatValue"
      @blur="emitValue"
    />

    <span class="pr-3 text-gray-500 whitespace-nowrap">
      {{ trailingText }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "InputWithText",
  props: {
    leadingText: {
      type: String,
      default: "",
    },
    trailingText: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Enter number",
    },
    modelValue: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    // Create a ref for the input value, initialized with modelValue prop
    const inputValue = ref<string | number>(
      typeof props.modelValue === "number" ? props.modelValue.toFixed(2) : ""
    );

    // Format input to restrict it to two decimal places
    const formatValue = () => {
      // Allow only numbers with up to two decimal places
      const formattedValue = inputValue.value
        .toString()
        .replace(/[^0-9.]/g, "");
      const match = formattedValue.match(/^(\d+(\.\d{0,2})?)?/);
      inputValue.value = match ? match[0] : "";
    };

    // Emit the formatted value to the parent component on blur
    const emitValue = () => {
      if (inputValue.value) {
        const value = parseFloat(inputValue.value as string).toFixed(2);
        inputValue.value = value;
        emit("update:modelValue", parseFloat(value));
      } else {
        emit("update:modelValue", undefined);
      }
    };

    // Watch for changes in modelValue prop to sync inputValue ref
    watch(
      () => props.modelValue,
      (newVal) => {
        inputValue.value = newVal?.toFixed(2) || "";
      }
    );

    return {
      inputValue,
      formatValue,
      emitValue,
    };
  },
});
</script>

<style scoped>
/* Only using Tailwind CSS */
</style>
