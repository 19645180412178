import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "overflow-x-auto w-full pr-4" }
const _hoisted_2 = { class: "flex flex-col mb-4" }
const _hoisted_3 = { class: "flex flex-row gap-2" }
const _hoisted_4 = {
  key: 0,
  class: "flex-1 border border-gray-200 rounded-lg flex flex-col"
}
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = {
  key: 1,
  class: "flex-1 border border-gray-200 rounded-lg flex flex-col"
}
const _hoisted_7 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewDirectory = _resolveComponent("ViewDirectory")!
  const _component_ViewWidget = _resolveComponent("ViewWidget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isDirectoryVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_ViewDirectory, { onHideWidgets: _ctx.hideWidgets }, null, 8, ["onHideWidgets"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isWidgetVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_ViewWidget, { onHideDirectory: _ctx.hideDirectory }, null, 8, ["onHideDirectory"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}