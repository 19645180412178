import { defineStore } from "pinia";

interface AttendeeState {
  bookMeetingName: string;
  bookMeetingEmail: string;
  bookMeetingNotes: string;
  bookMeetingTitle: string;
  bookMeetingCompany: string;
}

export const useAttendeeStore = defineStore("attendee", {
  state: (): AttendeeState => ({
    bookMeetingName: "",
    bookMeetingEmail: "",
    bookMeetingNotes: "",
    bookMeetingTitle: "",
    bookMeetingCompany: "",
  }),
  actions: {
    setBookMeetingName(name: string) {
      this.bookMeetingName = name;
    },
    setBookMeetingEmail(email: string) {
      this.bookMeetingEmail = email;
    },
    setBookMeetingNotes(notes: string) {
      this.bookMeetingNotes = notes;
    },
    setBookMeetingTitle(title: string) {
      this.bookMeetingTitle = title;
    },
    setBookMeetingCompany(company: string) {
      this.bookMeetingCompany = company;
    },
    clearStore() {
      this.bookMeetingName = "";
      this.bookMeetingEmail = "";
      this.bookMeetingNotes = "";
      this.bookMeetingTitle = "";
      this.bookMeetingCompany = "";
    },
  },
  persist: true,
});
