import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/shared/avatar.svg'


const _hoisted_1 = { class: "flex flex-col w-full max:md-w-1/2 space-y-4" }
const _hoisted_2 = { class: "flex flex-wrap justify-between gap-6 py-6 border-b border-gray-200" }
const _hoisted_3 = { class: "flex flex-col flex-1 gap-2 text-left" }
const _hoisted_4 = { class: "flex gap-2 w-full" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = { class: "text-gray-900 text-sm font-normal leading-5" }
const _hoisted_7 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_8 = { class: "flex flex-col flex-1 text-left" }
const _hoisted_9 = { class: "text-gray-900 text-sm font-normal leading-5" }
const _hoisted_10 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_11 = { class: "flex flex-wrap flex-1 text-left gap-4 items-center" }
const _hoisted_12 = { class: "relative w-12 h-12 overflow-hidden rounded-full flex items-center justify-center" }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 1,
  src: _imports_0,
  alt: "Default profile picture",
  class: "w-full h-full object-cover",
  loading: "lazy"
}
const _hoisted_15 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_16 = { class: "flex flex-col flex-1 text-left" }
const _hoisted_17 = { class: "text-gray-900 text-sm font-normal leading-5" }
const _hoisted_18 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_19 = { class: "flex flex-col flex-1 text-left" }
const _hoisted_20 = { class: "text-gray-900 text-sm font-normal leading-5" }
const _hoisted_21 = { class: "flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200" }
const _hoisted_22 = { class: "flex flex-col flex-1 text-left" }
const _hoisted_23 = { class: "text-gray-900 text-sm font-normal leading-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Name")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.firstName) + " " + _toDisplayString(_ctx.lastName), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Email Address")
      ], -1)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.email), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Your photo"),
        _createElementVNode("span", { class: "text-gray-500 text-sm font-normal leading-5" }, "Your photo will be displayed on your profile.")
      ], -1)),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          (_ctx.profilePictureUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.profilePictureUrl,
                alt: "User profile picture",
                class: "w-full h-full object-cover",
                loading: "lazy"
              }, null, 8, _hoisted_13))
            : (_openBlock(), _createElementBlock("img", _hoisted_14))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Title")
      ], -1)),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Company Name")
      ], -1)),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.companyName), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-col w-1/3 text-left" }, [
        _createElementVNode("span", { class: "text-gray-700 text-sm font-medium leading-5" }, "Company Website")
      ], -1)),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.companyWebsite), 1)
      ])
    ])
  ]))
}