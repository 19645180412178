<template>
  <div class="flex flex-col ml-6 gap-6">
    <div
      v-if="isLoadingAccountBalance"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div class="flex flex-col items-start">
      <span class="text-black text-3xl font-semibold leading-9">Billing</span>
    </div>
    <!-- available balance  -->
    <div class="flex flex-col gap-6" v-if="showCurrentAccountBalance">
      <CurrentAccountBalance
        :availableBalance="availableBalance"
        :totalBalance="totalBalance"
        :usedBalance="usedBalance"
        :autoTopup="autoTopup"
        :autoRechargeAmount="autoRechargeAmount"
        :lowBalanceAmount="lowBalanceAmount"
        :paymentMethodCardNumber="paymentMethodCardNumber"
        :paymentMethodCreatedAt="paymentMethodCreatedAt"
        :paymentMethodSource="paymentMethodSource"
        :paymentMethodInvoiceRecipientEmail="paymentMethodInvoiceRecipientEmail"
        :updateAccountBalance="updateAccountBalance"
      />
      <InvoiceHistory />
    </div>
    <UpdateAccountBalance
      v-else
      :accountBalanceId="accountBalanceId"
      :availableBalance="availableBalance"
      :autoTopup="autoTopup"
      :autoRechargeAmount="autoRechargeAmount"
      :lowBalanceAmount="lowBalanceAmount"
      :paymentMethodExpirationDate="paymentMethodExpirationDate"
      :paymentMethodCardNumber="paymentMethodCardNumber"
      :paymentMethodInvoiceRecipientEmail="paymentMethodInvoiceRecipientEmail"
      :paymentMethodSource="paymentMethodSource"
      :paymentMethodId="paymentMethodId"
      :cancel="cancelUpdateAccountBalance"
      @refetchAccountBalance="refetchAccountBalance"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
// import { useRouter } from "vue-router";
import { AccountBalance, ActivePaymentMethod } from "@/types/dashboard-types";
import CurrentAccountBalance from "./CurrentAccountBalance.vue";
import UpdateAccountBalance from "./UpdateAccountBalance.vue";
import InvoiceHistory from "./invoice/InvoiceHistory.vue";

const GET_ACCOUNT_BALANCE = gql`
  query {
    fetchAccountBalance {
      id
      availableBalance
      usedBalance
      totalBalance
      autoTopup
      autoRechargeAmount
      lowBalanceAmount
      activePaymentMethod {
        paymentMethodId
        source
        isActive
        invoiceRecipientEmail
        createdAt
        paymentInfo {
          cardNumber
          expirationDate
        }
      }
    }
  }
`;

export default defineComponent({
  name: "BillingContainer",
  components: { CurrentAccountBalance, UpdateAccountBalance, InvoiceHistory },
  setup() {
    const availableBalance = ref(0);
    const usedBalance = ref(0);
    const totalBalance = ref(0);
    const autoTopup = ref(false);
    const autoRechargeAmount = ref(0);
    const lowBalanceAmount = ref(0);
    const paymentMethodSource = ref("");
    const paymentMethodCreatedAt = ref("");
    const paymentMethodCardNumber = ref("");
    const paymentMethodExpirationDate = ref("");
    const paymentMethodInvoiceRecipientEmail = ref("");
    const showCurrentAccountBalance = ref(true);
    const paymentMethodId = ref("");
    const accountBalanceId = ref("");
    const isLoadingAccountBalance = ref(false);

    onMounted(() => {
      getAccountBalance();
    });

    const getAccountBalance = () => {
      const {
        result: queryResult,
        error: queryError,
        refetch: refetchAccountBalance,
      } = useQuery(GET_ACCOUNT_BALANCE);

      refetchAccountBalance();

      isLoadingAccountBalance.value = true;

      watch(
        () => queryResult.value,
        (newValue) => {
          if (newValue) {
            isLoadingAccountBalance.value = false;
          }
          if (newValue?.fetchAccountBalance) {
            handleAccountBalance(newValue.fetchAccountBalance);
          }
        },
        { immediate: true }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            console.error("Query error: ", newError);
            isLoadingAccountBalance.value = false;
          }
        },
        { immediate: true }
      );
    };

    const handleAccountBalance = (accountBalance: AccountBalance) => {
      accountBalanceId.value = accountBalance.id;
      updateBalances(accountBalance);
      updateAutoTopup(accountBalance);
      if (accountBalance.activePaymentMethod.isActive) {
        updatePaymentMethod(accountBalance.activePaymentMethod);
      }
    };

    const updateBalances = (accountBalance: AccountBalance) => {
      availableBalance.value = accountBalance.availableBalance;
      usedBalance.value = accountBalance.usedBalance;
      totalBalance.value = accountBalance.totalBalance;
    };

    const updateAutoTopup = (accountBalance: AccountBalance) => {
      autoTopup.value = accountBalance.autoTopup;
      autoRechargeAmount.value = accountBalance.autoRechargeAmount;
      lowBalanceAmount.value = accountBalance.lowBalanceAmount;
    };

    const updatePaymentMethod = (activePaymentMethod: ActivePaymentMethod) => {
      paymentMethodSource.value = activePaymentMethod.source;
      if (activePaymentMethod.source === "credit_card") {
        paymentMethodCardNumber.value =
          activePaymentMethod.paymentInfo.cardNumber;
        paymentMethodExpirationDate.value =
          activePaymentMethod.paymentInfo.expirationDate;
      }
      paymentMethodCreatedAt.value = formatDateToLongDateString(
        activePaymentMethod.createdAt
      );
      paymentMethodInvoiceRecipientEmail.value =
        activePaymentMethod.invoiceRecipientEmail;
      paymentMethodId.value = activePaymentMethod.paymentMethodId;
    };

    const formatDateToLongDateString = (dateString: Date): string => {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const updateAccountBalance = () => {
      // router.push({ name: "UpdateAccountBalance" });
      console.log("Update Account Balance clicked");
      showCurrentAccountBalance.value = false;
    };

    const cancelUpdateAccountBalance = () => {
      showCurrentAccountBalance.value = true;
    };

    const refetchAccountBalance = () => {
      getAccountBalance();
      showCurrentAccountBalance.value = true;
    };

    return {
      availableBalance,
      usedBalance,
      totalBalance,
      autoTopup,
      autoRechargeAmount,
      lowBalanceAmount,
      paymentMethodSource,
      paymentMethodCreatedAt,
      paymentMethodCardNumber,
      paymentMethodInvoiceRecipientEmail,
      paymentMethodExpirationDate,
      updateAccountBalance,
      showCurrentAccountBalance,
      cancelUpdateAccountBalance,
      paymentMethodId,
      accountBalanceId,
      refetchAccountBalance,
      isLoadingAccountBalance,
    };
  },
});
</script>
